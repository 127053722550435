import gqlRequestManager from './gqlRequestManager'

import { workScheduleDummyEndPoint } from './endpoints'
import { 
    getWorkScheduleDummies,
    createWorkScheduleDummy,
    updateWorkScheduleDummy,
    deleteWorkSchedleDummy
 } from '../utils/queries/workScheduleDummy'

export default {
    getWorkScheduleDummies: function() {
        return gqlRequestManager(
            workScheduleDummyEndPoint, 
            {
                query: getWorkScheduleDummies.query,
            },
            getWorkScheduleDummies.dataName
        );
    },
    createWorkScheduleDummy: function(workScheduleDummy) {
        return gqlRequestManager(
            workScheduleDummyEndPoint, 
            {
                query: createWorkScheduleDummy.query,
                variables: {
                    workScheduleDummy: workScheduleDummy
                }
            },
            createWorkScheduleDummy.dataName
        );
    },
    updateWorkScheduleDummy: function(workScheduleDummyName, updatedWorkScheduleDummy) {
        return gqlRequestManager(
            workScheduleDummyEndPoint, 
            {
                query: updateWorkScheduleDummy.query,
                variables: {
                    workScheduleDummyName: workScheduleDummyName,
                    updatedWorkScheduleDummy: updatedWorkScheduleDummy
                }
            },
            updateWorkScheduleDummy.dataName
        );
    },
    deleteWorkSchedleDummy: function(workScheduleDummyName) {
        return gqlRequestManager(
            workScheduleDummyEndPoint, 
            {
                query: deleteWorkSchedleDummy.query,
                variables: {
                    workScheduleDummyName: workScheduleDummyName
                }
            },
            deleteWorkSchedleDummy.dataName
        );
    },
}