<template>
    <v-container fluid class="pa-1">
        <v-data-table
            v-if="loaded"
            :headers="headers"
            :items="displayVariationProposalsData"
            :search="search"
            disable-pagination
            hide-default-footer
            :single-expand="false"
            :expanded.sync="expanded"
            item-key="id"
            show-expand
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Cerca"
                        single-line
                        hide-details
                    ></v-text-field>
                
                    <!-- <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider> -->
                    <!-- <v-btn
                            color="light-blue lighten-4"
                            class="mb-2"
                            @click="showConsolidationDialog = true"
                        >
                            <v-icon
                                left
                            >
                                mdi-file-lock
                            </v-icon>
                            Consolida Mensilità
                        </v-btn>
                    -->
                </v-toolbar>
            </template>
            <template v-slot:item.preview="{ item }">
                <v-container class="d-flex flex-row justify-center">
                    <v-sheet
                        rounded="pill"
                        color="grey lighten-1"
                        class="mx-1 d-flex justify-center align-center"
                        height="30"
                        width="30"
                        v-if="item.preview.registeredMinutes > 0"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    small 
                                    color="black" 
                                    class="ma-1"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-stamper
                                </v-icon>
                            </template>
                            <span>Orario registrato: {{formatDurationString(item.preview.registeredMinutes)}}</span>
                        </v-tooltip>
                    </v-sheet>
                    <v-sheet
                        rounded="pill"
                        color="teal accent-1"
                        class="mx-1 d-flex justify-center align-center"
                        height="30"
                        width="30"
                        v-if="item.preview.leaveMinutes > 0"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    small 
                                    color="black" 
                                    class="ma-1"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-exit-run
                                </v-icon>
                            </template>
                            <div v-if="item.preview.leaveMinutes > 0">Permesso: {{formatDurationString(item.preview.leaveMinutes)}}</div>
                        </v-tooltip>
                    </v-sheet>
                    <v-sheet
                        rounded="pill"
                        color="cyan accent-3"
                        class="mx-1 d-flex justify-center align-center"
                        height="30"
                        width="30"
                        v-if="item.preview.holidayMinutes > 0"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    small 
                                    color="black" 
                                    class="ma-1"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-umbrella-beach-outline
                                </v-icon>
                            </template>
                            <div v-if="item.preview.holidayMinutes > 0">Ferie: {{formatDurationString(item.preview.holidayMinutes)}}</div>
                        </v-tooltip>
                    </v-sheet>
                    <v-sheet
                        rounded="pill"
                        color="purple lighten-3"
                        class="mx-1 d-flex justify-center align-center"
                        height="30"
                        width="30"
                        v-if="item.preview.overtimeMinutes > 0"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    small 
                                    color="black" 
                                    class="ma-1"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-alarm-plus
                                </v-icon>
                            </template>
                            <span>Straordinari: {{formatDurationString(item.preview.overtimeMinutes)}}</span>
                        </v-tooltip>
                    </v-sheet>
                    <v-sheet
                        rounded="pill"
                        color="amber accent-4"
                        class="mx-1 d-flex justify-center align-center"
                        height="30"
                        width="30"
                        v-if="item.preview.businessTrip"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    small 
                                    color="black" 
                                    class="ma-1"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-bag-suitcase
                                </v-icon>
                            </template>
                            <span>Trasferta</span>
                        </v-tooltip>
                    </v-sheet>
                    <v-sheet
                        rounded="pill"
                        color="pink lighten-3"
                        class="mx-1 d-flex justify-center align-center"
                        height="30"
                        width="30"
                        v-if="item.preview.sickDay"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    small 
                                    color="black" 
                                    class="ma-1"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-emoticon-sick
                                </v-icon>
                            </template>
                            <span>Malattia</span>
                        </v-tooltip>
                    </v-sheet>
                    
                </v-container>
            </template>
            <!-- <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            
                        >
                            mdi-eye {{item}}
                        </v-icon>
                    </template>
                    <span>Dettagli Modifica</span>
                </v-tooltip>
                
            </template> -->
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-container>
                        <v-row class="mt-4">
                             <v-col class="text-center text-subtitle-2 font-weight-light">
                                Attuale
                            </v-col>
                            <v-col>
                            </v-col>
                             <v-col class="text-center text-subtitle-2 font-weight-light">
                                Proposta
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row>
                            <v-col>
                                <v-container class="d-flex flex-column align-center">
                                    <span v-for="(wdr, index) in item.userWorkDay.workDayRecords" :key="index">
                                        <v-sheet color="grey lighten-1" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2" v-if="wdr.recordType=='CLOCK_RECORD'">
                                            <v-icon left color="black">
                                                mdi-stamper
                                            </v-icon>
                                            <v-spacer />
                                            <div class="font-weight-bold">
                                                {{formatTimeString(wdr.clockRecord.punchInTime)}}&nbsp;&nbsp;&nbsp;{{formatTimeString(wdr.clockRecord.punchOutTime)}}
                                            </div>
                                            <v-spacer />
                                        </v-sheet>
                                        <v-sheet color="teal accent-1" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2" v-if="wdr.recordType=='LEAVE_RECORD'">
                                            <v-icon left color="black">
                                                mdi-exit-run
                                            </v-icon>
                                            <v-spacer />
                                            <div class="font-weight-bold">
                                                {{formatDurationString(wdr.leaveRecord.minuteDuration)}}
                                            </div>
                                            <v-spacer />
                                        </v-sheet>
                                        <v-sheet color="cyan accent-3" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2" v-if="wdr.recordType=='HOLIDAY_RECORD'">
                                            <v-icon left color="black">
                                                mdi-umbrella-beach-outline
                                            </v-icon>
                                            <v-spacer />
                                            <div class="font-weight-bold">
                                                {{formatDurationString(wdr.holidayRecord.minuteDuration)}}
                                            </div>
                                            <v-spacer />
                                        </v-sheet>
                                        <v-sheet color="pink lighten-3" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2" v-if="wdr.recordType=='SICK_RECORD'">
                                            <v-icon left color="black">
                                                mdi-emoticon-sick
                                            </v-icon>
                                            <v-spacer />
                                            <div class="font-weight-bold">
                                                Malattia
                                            </div>
                                            <v-spacer />
                                        </v-sheet>
                                        <v-sheet color="deep-orange lighten-3" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2" v-if="wdr.recordType=='AVAILABILITY_RECORD'">
                                            <v-icon left color="black">
                                                mdi-face-agent
                                            </v-icon>
                                            <v-spacer />
                                            <div class="font-weight-bold">
                                                Reperibilitá
                                            </div>
                                            <v-spacer />
                                        </v-sheet>
                                    </span>
                                    <span
                                        v-if="item.userWorkDay.overtimeMinutes > 0"
                                    >
                                        <v-sheet color="purple lighten-3" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2">
                                            <v-icon left color="black">
                                                mdi-alarm-plus
                                            </v-icon>
                                            <v-spacer />
                                            <div class="font-weight-bold">
                                                {{formatDurationString(item.userWorkDay.overtimeMinutes)}}
                                            </div>
                                            <v-spacer />
                                        </v-sheet>
                                    </span>
                                    <span
                                        v-if="item.userWorkDay.businessTrip"
                                    >
                                        <v-sheet color="amber accent-4" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2">
                                            <v-icon left color="black">
                                                mdi-bag-suitcase
                                            </v-icon>
                                            <v-spacer />
                                            <div class="font-weight-bold">
                                                Trasferta
                                            </div>
                                            <v-spacer />
                                        </v-sheet>
                                    </span>
                                </v-container>
                            </v-col>
                            <v-col class="d-flex flex-column justify-center align-center">
                                <div class="d-flex flex-row justify-center px-2">    
                                    <v-icon small class="mr-2">
                                        mdi-clock
                                    </v-icon>
                                    <div class="d-flex flex-column mx-2 justify-center">
                                        <span class="font-weight-bold" v-if="item.scheduledDayTimes.morningTimesPresent">{{formatTimeString( item.scheduledDayTimes.morningTimes.clockInTime)}}&nbsp;-&nbsp;{{formatTimeString( item.scheduledDayTimes.morningTimes.clockOutTime)}}</span>
                                        <span class="font-weight-bold" v-if="item.scheduledDayTimes.afternoonTimesPresent">{{formatTimeString( item.scheduledDayTimes.afternoonTimes.clockInTime)}}&nbsp;-&nbsp;{{formatTimeString( item.scheduledDayTimes.afternoonTimes.clockOutTime)}}</span>
                                    </div>
                                </div>
                                <v-divider class="my-4"/>
                                <v-sheet rounded="lg" max-width="280" outlined class="pa-2 font-italic text-wrap">
                                    <v-icon left small>
                                        mdi-text
                                    </v-icon>
                                    {{item.notes}}
                                </v-sheet>
                                <v-divider class="my-4"/>
                                <v-icon large>
                                    mdi-arrow-right-circle
                                </v-icon>
                                                       
                            </v-col>
                            <v-col>
                                <v-container class="d-flex flex-column align-center">
                                    <span v-for="(wdr, index) in item.workDayRecords" :key="index">
                                        <v-sheet color="grey lighten-1" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2" v-if="wdr.recordType=='CLOCK_RECORD'">
                                            <v-icon left color="black">
                                                mdi-stamper
                                            </v-icon>
                                            <v-spacer />
                                            <div class="font-weight-bold">
                                                {{formatTimeString(wdr.clockRecord.punchInTime)}}&nbsp;&nbsp;&nbsp;{{formatTimeString(wdr.clockRecord.punchOutTime)}}
                                            </div>
                                            <v-spacer />
                                        </v-sheet>
                                        <v-sheet color="teal accent-1" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2" v-if="wdr.recordType=='LEAVE_RECORD'">
                                            <v-icon left color="black">
                                                mdi-exit-run
                                            </v-icon>
                                            <v-spacer />
                                            <div class="font-weight-bold">
                                                {{formatDurationString(wdr.leaveRecord.minuteDuration)}}
                                            </div>
                                            <v-spacer />
                                        </v-sheet>
                                        <v-sheet color="cyan accent-3" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2" v-if="wdr.recordType=='HOLIDAY_RECORD'">
                                            <v-icon left color="black">
                                                mdi-umbrella-beach-outline
                                            </v-icon>
                                            <v-spacer />
                                            <div class="font-weight-bold">
                                                {{formatDurationString(wdr.holidayRecord.minuteDuration)}}
                                            </div>
                                            <v-spacer />
                                        </v-sheet>
                                        <v-sheet color="pink lighten-3" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2" v-if="wdr.recordType=='SICK_RECORD'">
                                            <v-icon left color="black">
                                                mdi-emoticon-sick
                                            </v-icon>
                                            <v-spacer />
                                            <div class="font-weight-bold">
                                                Malattia
                                            </div>
                                            <v-spacer />
                                        </v-sheet>
                                        <v-sheet color="deep-orange lighten-3" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2" v-if="wdr.recordType=='AVAILABILITY_RECORD'">
                                            <v-icon left color="black">
                                                mdi-face-agent
                                            </v-icon>
                                            <v-spacer />
                                            <div class="font-weight-bold">
                                                Reperibilitá
                                            </div>
                                            <v-spacer />
                                        </v-sheet>
                                    </span>
                                    <span
                                        v-if="item.preview.overtimeMinutes > 0"
                                    >
                                        <v-sheet color="purple lighten-3" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2">
                                            <v-icon left color="black">
                                                mdi-alarm-plus
                                            </v-icon>
                                            <v-spacer />
                                            <div class="font-weight-bold" style="white-space: normal !important;">
                                                {{formatDurationString(item.preview.overtimeMinutes)}}
                                            </div>
                                            <v-spacer />
                                        </v-sheet>
                                    </span>
                                    <span
                                        v-if="item.preview.businessTrip"
                                    >
                                        <v-sheet color="amber accent-4" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2">
                                            <v-icon left color="black">
                                                mdi-bag-suitcase
                                            </v-icon>
                                            <v-spacer />
                                            <div class="font-weight-bold">
                                                Trasferta
                                            </div>
                                            <v-spacer />
                                        </v-sheet>
                                    </span>
                                </v-container>
                            </v-col>
                        </v-row>
                        <v-row>
                            
                        </v-row>
                    </v-container>
                    <v-spacer class="my-1"/>
                    <v-container class="d-flex justify-center mb-2">
                        <v-btn 
                            class="mr-8" 
                            color="red lighten-1" 
                            dark
                            @click="rejectVariationProposal(item)"
                        >
                            <v-icon left>
                                mdi-thumb-down
                            </v-icon>
                            Scarta
                        </v-btn>
                         <v-btn 
                            color="green accent-2"
                            @click="applyVariationProposal(item)"
                        >
                            <v-icon left>
                                mdi-thumb-up
                            </v-icon>
                            Applica
                        </v-btn>
                    </v-container>
                </td>
            </template>
        </v-data-table>
        <ConfirmDialog
            :dialog="confirmDialog"
            :dialogText="confirmDialogText"
            :loading="confirmDialogLoading"
            v-on:c-dialog-confirm="confirmDialogAction"
            v-on:c-dialog-cancel="confirmDialog = false"
        />
    </v-container>
</template>

<script>
export const ConfirmDialog = () => import('../global/ConfirmDialog.vue')

import userWorkDayVariationProposalApi from '../../api/userWorkDayVariationProposal'
import workTimesService from '../../services/mixins/workTimesMixin'

import { DateTime } from 'luxon'

export default {
    data: function() {
        return {
            search: '',
            headers: [
                { text: 'Utente', align: 'start', value: 'username' },
                { text: 'Azienda', align: 'start', value: 'companyName' },
                { text: 'Data', align: 'start', value: 'formattedWorkDate' },
                { text: 'Creazione Proposta', align: 'start', value: 'formattedCreationTime' },
                { text: 'Anteprima', align: 'center', value: 'preview', sortable: false},
                { text: '', value: 'actions', sortable: false, align: 'end' },
            ],
            variationProposalsData: [],
            expanded: [],
            confirmDialog: false,
            confirmDialogText: "",
            confirmDialogAction: () => {},
            confirmDialogLoading: false,
            loaded: false
        };
    },
    mixins: [
        workTimesService
    ],
    components: {
        ConfirmDialog
    },
    computed: {
        displayVariationProposalsData: function() {
            let displayRecords = [];
            [...this.variationProposalsData].forEach(proposal => {
                let wrappedWorkDate = DateTime.fromISO(proposal.workDate);
                let element = {};
                element.notes = proposal.notes;
                element.username = proposal.user.username,
                element.companyName = proposal.user.company.companyName,
                element.workDate = proposal.workDate;
                element.formattedWorkDate = wrappedWorkDate.toLocaleString(DateTime.DATE_FULL);
                element.formattedCreationTime = DateTime.fromISO(proposal.creationTime).toLocaleString(DateTime.DATETIME_SHORT);
                // trovo l'orario attivo
                element.activeSchedule = proposal.user.userWorkSchedules.find((schedule) => {
                    let scheduleStart = DateTime.fromISO(schedule.validityStartDate);
                    if(scheduleStart <= wrappedWorkDate) {
                        let scheduleStop = schedule.validityStopDate ? DateTime.fromISO(schedule.validityStopDate) : null;
                        if(scheduleStop == null || scheduleStop >= wrappedWorkDate) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                });
                let weekDay = wrappedWorkDate.weekday;
                let weekDayKey = ['mondayWorkTimes', 'tuesdayWorkTimes', 'wednesdayWorkTimes', 'thursdayWorkTimes', 'fridayWorkTimes', 'saturdayWorkTimes', 'sundayWorkTimes'][weekDay - 1];
                element.scheduledDayTimes = element.activeSchedule.scheduledWeekWorkTimes[weekDayKey];
                element.scheduledDayTimes.morningTimesPresent = !!element.scheduledDayTimes.morningTimes.clockInTime && !!element.scheduledDayTimes.morningTimes.clockOutTime;
                element.scheduledDayTimes.afternoonTimesPresent = !!element.scheduledDayTimes.afternoonTimes.clockInTime && !!element.scheduledDayTimes.afternoonTimes.clockOutTime;
                //
                
                let proposalSummary = this.userWorkDaySummary(proposal.workDayRecords, element.scheduledDayTimes, proposal.workDate);
                element.preview = {
                    businessTrip: proposal.businessTrip,
                    sickDay: proposalSummary.sickDay,
                    overtimeMinutes: proposal.overtimeMinutes,
                    leaveMinutes: proposalSummary.leaveMinutes,
                    clockMinutes: proposalSummary.clockMinutes,
                    registeredMinutes: proposalSummary.registeredMinutes,
                    holidayMinutes: proposalSummary.holidayMinutes,
                };
                element.id = element.username + '_' + wrappedWorkDate.toISODate();
                element.workDayRecords = proposal.workDayRecords;
                element.userWorkDay = proposal.userWorkDay;

                displayRecords.push(element);
            })
            return displayRecords;
        },
       
    },
    watch: {
    },
    methods: {
        fetchVariationProposals: async function() {
            const response = await userWorkDayVariationProposalApi.userWorkDayVariationProposals()
           
            if(response.success) {
                this.variationProposalsData = response.data;
            }
        },
        expandPanelByUsernameDate: function(username, date) {
           
            let itemToExpand = this.displayVariationProposalsData.find(vp => vp.username === username && vp.workDate.substring(0, 10) === date);
            this.expanded.push(itemToExpand);
           
        },
        formatDurationString: function(minutesDuration) {
            return (minutesDuration >= 60 ? Math.floor(minutesDuration/60) + 'h ' : '') + (minutesDuration % 60 != 0 ? minutesDuration % 60 + 'm': '').trim();
        },
        formatTimeString: function(timeISO) {
            if(timeISO) {
                return DateTime.fromISO(timeISO).toLocaleString(DateTime.TIME_24_SIMPLE);
            } else {
                return "--:--";
            }
        },
        rejectVariationProposal: async function(vp) {
            let self = this;
            self.confirmDialogText = "Scartare le modifiche proposte?";
            self.confirmDialogAction = () => {
                self.confirmDialogLoading = true;
                self.confirmDialogText = "Scarto modifiche in corso";
                userWorkDayVariationProposalApi.rejectUserWorkDayVariationProposal(vp.workDate, vp.username).then(async (response) => {
                    if(response.success) {
                        await self.fetchVariationProposals();
                        self.confirmDialog = false;
                    } else {
                        self.confirmDialog = false;
                    }
                    self.confirmDialogLoading = false;
                });
            };
            self.confirmDialog = true;
        },
        applyVariationProposal: async function(vp) {
            let self = this;
            self.confirmDialogText = "Applicare le modifiche proposte?";
            self.confirmDialogAction = () => {
                self.confirmDialogLoading = true;
                self.confirmDialogText = "Applicazione modifiche in corso";
                userWorkDayVariationProposalApi.applyUserWorkDayVariationProposal(vp.workDate, vp.username).then(async (response) => {
                    if(response.success) {
                        await self.fetchVariationProposals();
                        self.confirmDialog = false;
                    } else {
                        self.confirmDialog = false;
                    }
                    self.confirmDialogLoading = false;
                });
            };
            self.confirmDialog = true;
        },
    },
    beforeMount: async function() {
        await this.fetchVariationProposals();
        let username = this.$route.params.username;
        let date = this.$route.params.date;
        if(date && username) {
            this.expandPanelByUsernameDate(username, date);
        }
        this.loaded = true;
        
    },
}
</script>