export const getUserWorkDayVariationProposals = {
    query: `
        query
        {
            userWorkDayVariationProposals
            {
                user {
                    firstName
                    lastName
                    email
                    username
                    company {
                        companyName
                    }
                    userWorkSchedules {
                        validityStartDate
                        validityStopDate
                        scheduledWeekWorkTimes{
                            mondayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            tuesdayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            wednesdayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            thursdayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            fridayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            saturdayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            sundayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                        }
                    }
                }
                notes
                workDate
                businessTrip
                overtimeMinutes
                creationTime
                workDayRecords {
                        dateIndex
                        note
                        recordType
                        clockRecord {
                            punchInTime
                            punchOutTime
                            punchInTimeOffset
                            punchOutTimeOffset
                        }
                        leaveRecord {
                            minuteDuration
                            apply104
                        }
                        holidayRecord {
                            minuteDuration
                            fullDay
                        }
                        sickRecord {
                            medicalCertificationDelivered
                        }
                        availabilityRecord {
                            temp
                        }
                }
                userWorkDay {
                    workDate
                    businessTrip
                    overtimeMinutes
                    workDayRecords {
                        dateIndex
                        note
                        recordType
                        clockRecord {
                            punchInTime
                            punchOutTime
                            punchInTimeOffset
                            punchOutTimeOffset
                        }
                        leaveRecord {
                            minuteDuration
                            apply104
                        }
                        holidayRecord {
                            minuteDuration
                            fullDay
                        }
                        sickRecord {
                            medicalCertificationDelivered
                        }
                        availabilityRecord {
                            temp
                        }
                    }
                }
            }
        }
    `,
    dataName: 'userWorkDayVariationProposals'

}


export const createUserWorkDayVariationProposal = {
    query: `
        mutation($workDate:Date!, $businessTrip:Boolean!, $variationProposalNotes:String, $overtimeMinutes:Int!, $userWorkDayRecords:[PostWorkDayRecordInput!])
        {
            createUserWorkDayVariationProposal(workDate: $workDate, businessTrip: $businessTrip, overtimeMinutes: $overtimeMinutes, variationProposalNotes: $variationProposalNotes, userWorkDayRecords: $userWorkDayRecords)
        }
    `,
    dataName: 'createUserWorkDayVariationProposal'

}

export const applyUserWorkDayVariationProposal = {
    query: `
        mutation($workDate:Date!, $username:String!)
        {
            applyUserWorkDayVariationProposal(workDate: $workDate, username: $username)
        }
    `,
    dataName: 'applyUserWorkDayVariationProposal'

}

export const rejectUserWorkDayVariationProposal = {
    query: `
        mutation($workDate:Date!,  $username:String!)
        {
            rejectUserWorkDayVariationProposal(workDate: $workDate, username: $username)
        }
    `,
    dataName: 'rejectUserWorkDayVariationProposal'

}

export const deleteUserWorkDayVariationProposal = {
    query: `
        mutation($workDate:Date!)
        {
            deleteUserWorkDayVariationProposal(workDate: $workDate)
        }
    `,
    dataName: 'deleteUserWorkDayVariationProposal'

}

export const getUserWorkDayVariationProposalsInRange = {
    query: `
        query($startDate:Date!, $stopDate:Date!)
        {
            userWorkDayVariationProposalsInRange(startDate: $startDate, stopDate: $stopDate)
            {
                user {
                    firstName
                    lastName
                    email
                    username
                    company {
                        companyName
                    }
                    userWorkSchedules {
                        validityStartDate
                        validityStopDate
                        scheduledWeekWorkTimes{
                            mondayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            tuesdayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            wednesdayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            thursdayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            fridayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            saturdayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            sundayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                        }
                    }
                }
                notes
                workDate
                businessTrip
                overtimeMinutes
                creationTime
                workDayRecords {
                        dateIndex
                        note
                        recordType
                        clockRecord {
                            punchInTime
                            punchOutTime
                            punchInTimeOffset
                            punchOutTimeOffset
                        }
                        leaveRecord {
                            minuteDuration
                            apply104
                        }
                        holidayRecord {
                            minuteDuration
                            fullDay
                        }
                        sickRecord {
                            medicalCertificationDelivered
                        }
                        availabilityRecord {
                            temp
                        }
                }
                userWorkDay {
                    workDate
                    businessTrip
                    overtimeMinutes
                    workDayRecords {
                        dateIndex
                        note
                        recordType
                        clockRecord {
                            punchInTime
                            punchOutTime
                            punchInTimeOffset
                            punchOutTimeOffset
                        }
                        leaveRecord {
                            minuteDuration
                            apply104
                        }
                        holidayRecord {
                            minuteDuration
                            fullDay
                        }
                        sickRecord {
                            medicalCertificationDelivered
                        }
                        availabilityRecord {
                            temp
                        }
                    }
                }
            }
        }
    `,
    dataName: 'userWorkDayVariationProposals'

}
