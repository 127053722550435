<template>
  <v-app id="app">
    <AppToolbar
      :clockObject="clockObject"
    />
    <v-main>
      <DayRecordsPage 
        v-on:refresh-user-work-day-data="userWorkDayDataLoad(dayRecordsDate)"
        :userWorkDay="currentUserWorkDay"
        :clockObject="clockObject"
        :dayRecordsDate="dayRecordsDate"
        v-on:show-uwd-calendar="calendarDialog = true"
        :loadingMonthData="loadingMonthData"
        :loadingDayData="loadingDayData"
        :monthWorkTimesCoverageStatus="showingMonthStatus"
      />
      <MonthRecordsDialog 
        :show="calendarDialog"
        v-on:close-calendar-dialog="calendarDialog = false"
        :currentDate="dayRecordsDate"
        v-on:change-records-date="changeDayRecordsDate"
        v-on:refresh-calendar-user-work-days="monthUserWorkDaysDataLoad"
        :monthWorkDaysStatus.sync="showingMonthStatus"
      />
    </v-main>
    <SnackBar/>
  </v-app>
</template>
<script>
export const AppToolbar = () => import('../../components/core/AppToolBar.vue')
import DayRecordsPage from '../../components/dashboard/DayRecordsDisplay.vue'
import MonthRecordsDialog from '../../components/dashboard/MonthRecordsDisplay.vue'
export const SnackBar = () => import('../../components/core/AppSnackBar.vue')

// import loginApi from '../../api/login'
// import userApi from '../../api/user'
import userWorkDayApi from '../../api/userWorkDay'

import { mapActions, mapState } from 'vuex'
import { DateTime } from 'luxon'
//import * as currentUserActions from '../../store/actions/currentUser'
import * as dataStorageActions from '../../store/actions/dataStorage'

export default {
    components: {
        DayRecordsPage,
        MonthRecordsDialog,
        AppToolbar,
        SnackBar,
    },
    data: function() {
      return {
        menu: false,
        dayRecordsDate: new Date(),
        //clockObject: DateTime.local(),
        calendarDialog: false,
        showingMonthStatus: 'ok',
        loadingDayData: false,
        loadingMonthData: false,
      }
    },
    props: {
      clockObject: {
        type: Object,
        required: true
      },
    },
    methods: {
      changeDayRecordsDate(jsDate) {
        this.dayRecordsDate = jsDate;
      },
      userWorkDayDataLoad(jsDate) {
        this.loadingMonthData = true;
        this.loadingDayData = true;
        let month = jsDate.getMonth();
        let year = jsDate.getFullYear();

        let dateStart = DateTime.utc(year, month + 1, 1).toISODate();
        let dateStop = DateTime.utc(year, month + 1, 1).endOf('month').toISODate();


        userWorkDayApi.getUserWorkDayByCurrentUserDateRange(dateStart, dateStop).then(response => {
          if(response.success) {
            this.saveMonthUserWorkDays(response.data);
          }

          this.loadingMonthData = false;

          let parsedDate = DateTime.fromJSDate(jsDate).toISODate();
          userWorkDayApi.userWorkDayByCurrentUserDate(parsedDate).then(response => {
            if(response.success)
              this.saveUserWorkDay(response.data);

            this.loadingDayData = false;
          })

        })
        
      },
      monthUserWorkDaysDataLoad(month, year) {
        this.loadingMonthData = true;
        let dateStart = DateTime.utc(year, month + 1, 1).toISODate();
        let dateStop = DateTime.utc(year, month + 1, 1).endOf('month').toISODate();
        userWorkDayApi.getUserWorkDayByCurrentUserDateRange(dateStart, dateStop).then(response => {
          if(response.success) {
            this.saveMonthUserWorkDays(response.data);
          }
          this.loadingMonthData = false;
        })
      },
      ...mapActions({
        //logIn: currentUserActions.USER_DOLOGIN,
        //logOut: currentUserActions.USER_DOLOGOUT,
        saveUserWorkDay: dataStorageActions.DATASTORAGE_SETUWD,
        saveMonthUserWorkDays: dataStorageActions.DATASTORAGE_SETMONTHUWDS,
      })
    },
    watch: {
      dayRecordsDate: function(val) {
        this.userWorkDayDataLoad(val);
      },
    },
    computed: {
      ...mapState({
        username: state => state.currentUser.username,
        company: state => state.currentUser.company,
        userWorkDay: state => state.dataStorage.userWorkDay,
        //calendarUserWorkDays: state => state.dataStorage.calendarUserWorkDays,
      }),
      userWorkDayDateKey: function() {
        return DateTime.fromJSDate(this.dayRecordsDate).toFormat('dd');
      },
      userWorkDayMonthKey: function() {
        return DateTime.fromJSDate(this.dayRecordsDate).toFormat('yyyy-LL');
      },
      currentUserWorkDay: function() {
        return this.userWorkDay;
      },
      currentWorkDayRecords: function() {
        if (this.currentUserWorkDay) {
          return this.currentUserWorkDay.workDayRecords || [];
        } else {
          return [];
        }
      },
    },
    mounted: function() {
      // userApi.currentUser().then(response => {
      //   if(response.success)
      //     this.logIn(response.data)
      // });
      this.dayRecordsDate = new Date();
      //setInterval(() => this.clockObject = DateTime.local(), 1000);
    }
}
</script>
<style>
  html { 
    overflow-y: auto;
  }
</style>