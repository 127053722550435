<template>
    <router-view />
</template>
<script>

export default {
    data: function() {
        return {
        };
    },
    methods: {
    },
    mounted: function() {
    }
}
</script>