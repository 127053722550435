import gqlRequestManager from './gqlRequestManager'

import { companyEndPoint } from './endpoints'
import { 
    getCompanies,
    createCompany,
    updateCompany,
    deleteCompany
 } from '../utils/queries/company'

export default {
    getCompanies: function() {
        return gqlRequestManager(
            companyEndPoint, 
            {
                query: getCompanies.query,
            },
            getCompanies.dataName
        );
    },

    createCompany: function(company) {
        return gqlRequestManager(
            companyEndPoint, 
            {
                query: createCompany.query,
                variables: {
                    company: company
                }
            },
            createCompany.dataName
        );
    },

    updateCompany: function(companyName, updatedCompany) {
        return gqlRequestManager(
            companyEndPoint, 
            {
                query: updateCompany.query,
                variables: {
                    companyName: companyName,
                    updatedCompany: updatedCompany
                }
            },
            updateCompany.dataName
        );
    },

    deleteCompany: function(companyName) {
        return gqlRequestManager(
            companyEndPoint, 
            {
                query: deleteCompany.query,
                variables: {
                    companyName: companyName
                }
            },
            deleteCompany.dataName
        );
    },
}

