export const createLocalYearHolidays = {
    query:  `
        mutation($localYearHolidays:PostLocalYearHolidaysInput!)
        {
            createLocalYearHolidays(localYearHolidays: $localYearHolidays) 
            {
                year
                holidayDates {
                    name
                    date
                }
            }
        }
    `,
    dataName: 'createLocalYearHolidays' 
}

export const getLocalYearsHolidays = {
    query:  `
        query
        {
            localYearsHolidays
            {
                year
                holidayDates {
                    name
                    date
                }
            }
        }
    `,
    dataName: 'localYearsHolidays' 
}

export const deleteLocalYearHolidays = {
    query:  `
        mutation($year:Short!)
        {
            deleteLocalYearHolidays(year: $year)
        }
    `,
    dataName: 'deleteLocalYearHolidays' 
}

