import gqlRequestManager from './gqlRequestManager'

import { reportEndPoint } from './endpoints'
import { 
    generateWorkingHoursReport,
    exportWorkingHoursReport,
    userMonthExport
 } from '../utils/queries/reports'

export default {
    generateWorkingHoursReport: function(companyName, month, year) {
        return gqlRequestManager(
            reportEndPoint, 
            {
                query: generateWorkingHoursReport.query,
                variables: {
                    companyName: companyName,
                    month: month,
                    year: year
                }
            },
            generateWorkingHoursReport.dataName
        );
    },
    exportWorkingHoursReport: function(companyName, month, year) {
        return gqlRequestManager(
            reportEndPoint, 
            {
                query: exportWorkingHoursReport.query,
                variables: {
                    companyName: companyName,
                    month: month,
                    year: year
                }
            },
            exportWorkingHoursReport.dataName
        );
    },
    userMonthExport: function(companyName, month, year) {
        return gqlRequestManager(
            reportEndPoint, 
            {
                query: userMonthExport.query,
                variables: {
                    companyName: companyName,
                    month: month,
                    year: year
                }
            },
            userMonthExport.dataName
        );
    }
}
