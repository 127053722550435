import gqlRequestManager from './gqlRequestManager'

import { localYearHolidaysEndPoint } from './endpoints'
import { 
    getLocalYearsHolidays,
    createLocalYearHolidays,
    deleteLocalYearHolidays
 } from '../utils/queries/localYearHolidays'

export default {
    getLocalYearsHolidays: function() {
        return gqlRequestManager(
            localYearHolidaysEndPoint, 
            {
                query: getLocalYearsHolidays.query,
            },
            getLocalYearsHolidays.dataName
        );
    },

    createLocalYearHolidays: function(localYearHolidays) {
        return gqlRequestManager(
            localYearHolidaysEndPoint, 
            {
                query: createLocalYearHolidays.query,
                variables: {
                    localYearHolidays: localYearHolidays
                }
            },
            createLocalYearHolidays.dataName
        );
    },

    deleteLocalYearHolidays: function(year) {
        return gqlRequestManager(
            localYearHolidaysEndPoint, 
            {
                query: deleteLocalYearHolidays.query,
                variables: {
                    year: year
                }
            },
            deleteLocalYearHolidays.dataName
        );
    },

    // updateCompany: function(companyName, updatedCompany) {
    //     return gqlRequestManager(
    //         localYearHolidaysEndPoint, 
    //         {
    //             query: updateCompany.query,
    //             variables: {
    //                 companyName: companyName,
    //                 updatedCompany: updatedCompany
    //             }
    //         },
    //         updateCompany.dataName
    //     );
    // },

    // deleteCompany: function(companyName) {
    //     return gqlRequestManager(
    //         localYearHolidaysEndPoint, 
    //         {
    //             query: deleteCompany.query,
    //             variables: {
    //                 companyName: companyName
    //             }
    //         },
    //         deleteCompany.dataName
    //     );
    // },
}

