import Vue from 'vue'
import Vuex from 'vuex'

import currentUser from './modules/currentUser'
import appState from './modules/appState'
import snackbar from './modules/snackbar'
import dashboard from './modules/dashboard'
import dataStorage from './modules/dataStorage'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        currentUser,
        appState,
        snackbar,
        dashboard,
        dataStorage
    },
    state: {},
    mutations: {},
    actions: {},
    plugins: []
})