import * as dashboardActions from '../actions/dashboard'

const state = {
    displayDate: new Date(),
}

const getters = {}

const actions = {
    [dashboardActions.DASHBOARD_SETDISPLAYDATE]: async ({
        rootState,
        dispatch,
        commit
    }, date) => {
        commit(dashboardActions.DASHBOARD_SETDISPLAYDATE, date)
        return
    },

}

const mutations = {
    [dashboardActions.DASHBOARD_SETDISPLAYDATE]: (state, date) => {
        state.displayDate = date;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}