export const getCompanies = {
    query:  `
        query
        {
            companies
            {
                companyName
                usersCount
            }
        }
    `,
    dataName: 'companies'
}

export const deleteCompany = {
    query: `
        mutation($companyName:String!)
        {
            deleteCompany(
                companyName: $companyName
            )
        }
    `,
    dataName: 'deleteCompany'
}

export const createCompany = {
    query: `
        mutation($company:PostCompanyInput!)
        {
        createCompany(
                company: $company
            ) {
                companyName
            }
        }
    `,
    dataName: 'createCompany'
}

export const updateCompany = {
    query: `
        mutation($companyName:String!, $updatedCompany:FetchCompanyInput!)
            {
            updateCompany(
                companyName: $companyName,
                updatedCompany: $updatedCompany
            ) {
                companyName
            }
        }
    `,
    dataName: 'updateCompany'
}