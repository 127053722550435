export const getWorkScheduleDummies = {
    query: `
        query
        {
            workScheduleDummies{
                workScheduleDummyName
                defaultWeekWorkTimes{
                    mondayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    tuesdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    wednesdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    thursdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    fridayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    saturdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    sundayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                }
            }
        }
    `, 
    dataName: 'workScheduleDummies'

}

export const createWorkScheduleDummy = {
    query: `
        mutation($workScheduleDummy:PostWorkScheduleDummyInput!){
            createWorkScheduleDummy(
                workScheduleDummy: $workScheduleDummy,
            )
            {
                workScheduleDummyName
                defaultWeekWorkTimes{
                    mondayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    tuesdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    wednesdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    thursdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    fridayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    saturdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    sundayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                }
            }
        }
    `, 
    dataName: 'createWorkScheduleDummy'

}

export const updateWorkScheduleDummy = {
    query: `
        mutation($workScheduleDummyName:String!, $updatedWorkScheduleDummy:FetchWorkScheduleDummyInput!){
            updateWorkScheduleDummy(
                workScheduleDummyName: $workScheduleDummyName,
                updatedWorkScheduleDummy: $updatedWorkScheduleDummy
            )
            {
                workScheduleDummyName
                defaultWeekWorkTimes{
                    mondayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    tuesdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    wednesdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    thursdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    fridayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    saturdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    sundayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                }
            }
        }
    `, 
    dataName: 'updateWorkScheduleDummy'

}

export const deleteWorkSchedleDummy = {
    query: `
        mutation($workScheduleDummyName:String!)
            {
            deleteWorkScheduleDummy(
                workScheduleDummyName: $workScheduleDummyName
            )
        }
    `,
    dataName: 'deleteWorkScheduleDummy'

}