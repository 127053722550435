//export const DATASTORAGE_SETUWDS = 'dataStorage/SET_USERWORKDAYS'
export const DATASTORAGE_SETUWD = 'dataStorage/SET_USERWORKDAY'
export const DATASTORAGE_SETMONTHUWDS = 'dataStorage/SET_MONTHUWDS'
export const DATASTORAGE_SETUSERS = 'dataStorage/SET_USERS'
export const DATASTORAGE_SETCOMPANIES = 'dataStorage/SET_COMPANIES'
export const DATASTORAGE_SETDEPARTMENTS = 'dataStorage/SET_DEPARTMENTS'
export const DATASTORAGE_SETUSERWORKSCHEDULES = 'dataStorage/SET_USERWORKSCHEDULES'
export const DATASTORAGE_SETWORKSCHEDULEDUMMIES = 'dataStorage/SET_WORKSCHEDULEDUMMIES'
export const DATASTORAGE_SETLOCALYEARSHOLIDAYS = 'dataStorage/SET_LOCALYEARSHOLIDAYS'
export const DATASTORAGE_ISHOLIDAYCHECK = 'dataStorage/ISHOLIDAY_CHECK'
export const DATASTORAGE_GETUSERROLES = 'dataStorage/GET_USERROLES'