import * as dataStorageActions from '../actions/dataStorage'
import Vue from 'vue'
import user from '../../api/user'

import { DateTime } from 'luxon'

const state = {
    userWorkDay: {},
    calendarUserWorkDays: [],
    users: [],
    companies: [],
    departments: [],
    userWorkSchedules: {},
    workScheduleDummies: [],
    localYearsHolidays: [],
    userRoles: {
        SYSTEM: {
            value: 3,
            label: 'Sistema',
            icon: 'mdi-crown',
            color: 'amber'
        },
        ADMIN: {
            value: 2,
            label: 'Amministratore',
            icon: 'mdi-cog',
            color: 'green'
        },
        ANALYST: {
            value: 1,
            label: 'Analista',
            icon: 'mdi-database',
            color: 'purple'
        },
        BASE: {
            value: 0,
            label: 'Base',
            icon: 'mdi-account',
            color: 'grey'
        },
    }
}

const getters = {
    [dataStorageActions.DATASTORAGE_GETUSERROLES]: state => state.userRoles,
    [dataStorageActions.DATASTORAGE_ISHOLIDAYCHECK]: (state) => (date) => {
        let wrappedDate = DateTime.fromJSDate(date);
        let yearHolidays = state.localYearsHolidays.find(lyh => {
            return lyh.year == wrappedDate.year;
        });
        
        let result = !!yearHolidays && !!yearHolidays.holidayDates.map(hd => hd.date).find(hd => {
            return hd.substring(0, 10) == wrappedDate.toISO().substring(0, 10)
        });

        return result;
    },
}

const actions = {
    // [dataStorageActions.DATASTORAGE_SETUWDS]: async ({
    //     rootState,
    //     dispatch,
    //     commit
    //     }, uwds) => {
    //     commit(dataStorageActions.DATASTORAGE_SETUWDS, uwds)
    //     return
    // },
    [dataStorageActions.DATASTORAGE_SETUWD]: async ({
        rootState,
        dispatch,
        commit
        }, uwd) => {
        commit(dataStorageActions.DATASTORAGE_SETUWD, uwd)
        return
    },
    [dataStorageActions.DATASTORAGE_SETMONTHUWDS]: async ({
        rootState,
        dispatch,
        commit
        }, monthUwd) => {
        commit(dataStorageActions.DATASTORAGE_SETMONTHUWDS, monthUwd)
        return
    },
    [dataStorageActions.DATASTORAGE_SETUSERS]: async ({
        rootState,
        dispatch,
        commit
        }, users) => {
        commit(dataStorageActions.DATASTORAGE_SETUSERS, users)
        return
    },
    [dataStorageActions.DATASTORAGE_SETCOMPANIES]: async ({
        rootState,
        dispatch,
        commit
        }, companies) => {
        commit(dataStorageActions.DATASTORAGE_SETCOMPANIES, companies)
        return
    },
    [dataStorageActions.DATASTORAGE_SETDEPARTMENTS]: async ({
        rootState,
        dispatch,
        commit
        }, departments) => {
        commit(dataStorageActions.DATASTORAGE_SETDEPARTMENTS, departments)
        return
    },
    [dataStorageActions.DATASTORAGE_SETWORKSCHEDULEDUMMIES]: async ({
        rootState,
        dispatch,
        commit
        }, wsd) => {
        commit(dataStorageActions.DATASTORAGE_SETWORKSCHEDULEDUMMIES, wsd)
        return
    },
    [dataStorageActions.DATASTORAGE_SETLOCALYEARSHOLIDAYS]: async ({
        rootState,
        dispatch,
        commit
        }, yearsHolidays) => {
        commit(dataStorageActions.DATASTORAGE_SETLOCALYEARSHOLIDAYS, yearsHolidays)
        return
    },
    [dataStorageActions.DATASTORAGE_SETUSERWORKSCHEDULES]: async ({
        rootState,
        dispatch,
        commit
        }, userWorkSchedules) => {
        commit(dataStorageActions.DATASTORAGE_SETUSERWORKSCHEDULES, userWorkSchedules)
        return
    },

}

const mutations = {
    // [dataStorageActions.DATASTORAGE_SETUWDS]: (state, uwds) => {
    //     state.userWorkDays = uwds;
    // },
    [dataStorageActions.DATASTORAGE_SETUWD]: (state, uwd) => {
        // if(state.userWorkDays[uwdObject.monthKey] == null)
        //     Vue.set(state.userWorkDays, uwdObject.monthKey, {})
        // Vue.set(state.userWorkDays[uwdObject.monthKey], uwdObject.dayKey, uwdObject.val)
        //state.userWorkDays[uwdObject.key] = uwdObject.val;
        state.userWorkDay = uwd;
    },
    [dataStorageActions.DATASTORAGE_SETMONTHUWDS]: (state, monthUwd) => {
        state.calendarUserWorkDays = monthUwd;
    },
    [dataStorageActions.DATASTORAGE_SETUSERS]: (state, users) => {
        state.users = users;
    },
    [dataStorageActions.DATASTORAGE_SETCOMPANIES]: (state, companies) => {
        state.companies = companies;
    },
    [dataStorageActions.DATASTORAGE_SETDEPARTMENTS]: (state, departments) => {
        state.departments = departments;
    },
    [dataStorageActions.DATASTORAGE_SETWORKSCHEDULEDUMMIES]: (state, wsd) => {
        state.workScheduleDummies = wsd;
    },
    [dataStorageActions.DATASTORAGE_SETLOCALYEARSHOLIDAYS]: (state, yearsHolidays) => {
        state.localYearsHolidays = yearsHolidays;
    },
    [dataStorageActions.DATASTORAGE_SETUSERWORKSCHEDULES]: (state, userWorkSchedules) => {
        state.userWorkSchedules = userWorkSchedules;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}