<template>
    <v-app>
        <v-container fill-height class="d-flex flex-column justify-center align-center">
            <div class="text-h1 font-weight-black light-blue--text text--lighten-3">
                404
            </div>
            <div class="text-subtitle-1 font-weight-light mt-4">
                Pagina non trovata
            </div>
            <v-btn
                @click="goBack"
                class="mt-12"
                color="light-green lighten-4"
            >
                <v-icon left>
                    mdi-home
                </v-icon>
                Torna alla schermata iniziale
            </v-btn>
        </v-container>
    </v-app>
</template>
<script>

export default {
    data: function() {
        return {
          
        };
    },
    methods: {
        goBack: function() {
            this.$router.push({ name: 'Home' });
        }
    },
    mounted: function() {
    }
}
</script>