// actions, mutations
export const SNACKBAR_SHOW = 'snackbar/SHOW'
export const SNACKBAR_HIDE = 'snackbar/HIDE'

// getters
export const SNACKBAR_GET_STATUS = 'snackbar/GET_STATUS'
export const SNACKBAR_GET_MESSAGE = 'snackbar/GET_MESSAGE'
// export const SNACKBAR_GET_ACTION_BUTTON = 'snackbar/GET_ACTION_BUTTON'
export const SNACKBAR_GET_MODE = 'snackbar/GET_MODE'
export const SNACKBAR_GET_MESSAGE_TYPE = 'snackbar/GET_MESSAGE_TYPE'
export const SNACKBAR_GET_X = 'snackbar/GET_X'
export const SNACKBAR_GET_Y = 'snackbar/GET_Y'
export const SNACKBAR_GET_TIMEOUT = 'snackbar/GET_TIMEOUT'
