<template>
    <v-container fluid class="pa-0">
        <v-row class="d-flex justify-center pa-4" style="max-height: calc(70px) !important">
            <v-icon @click="addDaysToDate(-1)" :disabled="loading">
                mdi-chevron-left
            </v-icon>

            <v-menu ref="datePicker" v-model="datePicker" :close-on-content-click="false" transition="scale-transition"
                offset-y max-width="290px" min-width="auto" bottom :disabled="loading">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined text class="mx-2" v-bind="attrs" v-on="on">
                        {{ formattedDateString }}
                    </v-btn>

                </template>
                <v-date-picker v-model="isoDate" first-day-of-week="1" locale="it" no-title
                    @input="datePicker = false"></v-date-picker>
            </v-menu>

            <v-icon @click="addDaysToDate(1)" :disabled="loading">
                mdi-chevron-right
            </v-icon>
        </v-row>
        <v-layout child-flex class="pa-0 overflow-auto" style="max-height: calc(100vh - 108px) !important">
            <v-data-table :headers="headers" :items="userData" :search="search" disable-pagination hide-default-footer
                sort-by="username" :loading="loading">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Cerca" single-line
                            hide-details></v-text-field>


                    </v-toolbar>
                </template>

                <template v-slot:item.displayName="{ item }">
                    <div class="d-flex justify-space-between align-center">
                        {{ item.displayName }}
                        <v-btn elevation="2" x-small fab @click="openMonthlyStatusDialog(item)">
                            <v-icon>
                                mdi-calendar-account
                            </v-icon>
                        </v-btn>
                    </div>
                </template>

                <template v-slot:item.scheduledTimes="{ item }">
                    <v-chip class="py-1" style="height: auto !important;" outlined v-if="((item.scheduledTimes && item.scheduledTimes.morningTimes.clockInTime && item.scheduledTimes.morningTimes.clockOutTime) || (item.scheduledTimes && item.scheduledTimes.afternoonTimes.clockInTime && item.scheduledTimes.afternoonTimes.clockOutTime)) &&
                        !isHolidayCheck(jsDate)
                        ">
                        <span v-if="item.hasVariationProposal">
                            <v-avatar left class="orange lighten-1">
                                <v-icon small color="white">
                                    mdi-alert-decagram
                                </v-icon>
                            </v-avatar>
                        </span>
                        <span v-else>
                            <v-avatar left class="green lighten-1" v-if="item.progressObject.complete">
                                <v-icon small color="white">
                                    mdi-check
                                </v-icon>
                            </v-avatar>
                            <v-avatar left class="red lighten-1" v-if="!item.progressObject.complete && isPastShowing">
                                <v-icon small color="white">
                                    mdi-close
                                </v-icon>
                            </v-avatar>
                        </span>
                        <div>
                            <div
                                v-if="item.scheduledTimes && item.scheduledTimes.morningTimes.clockInTime && item.scheduledTimes.morningTimes.clockOutTime">
                                {{ formatTimeString(item.scheduledTimes.morningTimes.clockInTime) }} - {{
                                    formatTimeString(item.scheduledTimes.morningTimes.clockOutTime) }}
                            </div>
                            <div
                                v-if="item.scheduledTimes && item.scheduledTimes.afternoonTimes.clockInTime && item.scheduledTimes.afternoonTimes.clockOutTime">
                                {{ formatTimeString(item.scheduledTimes.afternoonTimes.clockInTime) }} - {{
                                    formatTimeString(item.scheduledTimes.afternoonTimes.clockOutTime) }}
                            </div>
                        </div>
                    </v-chip>


                    <!-- <div v-if="item.scheduledTimes.morningTimes.clockInTime && item.scheduledTimes.morningTimes.clockOutTime">
                    {{ formatTimeString(item.scheduledTimes.morningTimes.clockInTime) }} - {{ formatTimeString(item.scheduledTimes.morningTimes.clockOutTime) }}
                </div>
                <div v-if="item.scheduledTimes.afternoonTimes.clockInTime && item.scheduledTimes.afternoonTimes.clockOutTime">
                    {{ formatTimeString(item.scheduledTimes.afternoonTimes.clockInTime) }} - {{ formatTimeString(item.scheduledTimes.afternoonTimes.clockOutTime) }}
                </div> -->
                </template>

                <template v-slot:item.records="{ item }">
                    <v-container fluid class="d-flex flex-row">

                        <v-col class="d-flex flex-row" style="overflow-x: auto; white-space: nowrap;">
                            <span v-for="(cr, index) in item.clockRecordObjects" :key="index + '_clock'"
                                class="d-flex flex-row">

                                <v-sheet outlined max-height="42"
                                    :color="cr.lateEnter ? 'orange lighten-4' : 'grey lighten-2'"
                                    class="pa-2 px-3 rounded-l-pill">

                                    <span class="font-weight-medium">
                                        {{ formatTimeString(cr.punchInTime) }}
                                    </span>


                                </v-sheet>

                                <v-sheet outlined max-height="42"
                                    :color="cr.earlyLeave ? 'orange lighten-4' : 'grey lighten-2'"
                                    class="pa-2 px-3 mr-2 rounded-r-pill">

                                    <span class="font-weight-medium">
                                        {{ formatTimeString(cr.punchOutTime) }}
                                    </span>


                                </v-sheet>
                            </span>
                        </v-col>


                        <v-col class="d-flex justify-end" style="overflow-x: auto; white-space: nowrap;">
                            <!-- <v-sheet color="orange lighten-1" rounded="circle" class="d-flex justify-center align-center" height="42" width="42" v-if="item.workDayData.businessTrip">
                            <v-icon color="white">
                                mdi-alert-decagram
                            </v-icon>
                        </v-sheet> -->

                            <v-sheet color="cyan accent-3" rounded="circle" class="d-flex justify-center align-center"
                                height="42" width="42" v-if="item.holidayDay">
                                <v-icon color="black">
                                    mdi-alpha-f
                                </v-icon>
                            </v-sheet>
                            <v-sheet rounded="circle" class="d-flex justify-center align-center" height="42" width="42"
                                v-else>
                                <v-icon color="grey lighten-1">
                                    mdi-alpha-f
                                </v-icon>
                            </v-sheet>

                            <span class="mx-1" />

                            <v-sheet color="teal accent-3" rounded="pill"
                                class="d-flex flex-row justify-space-between align-center px-2" height="42"
                                v-if="item.leaveMinutes > 0">
                                <v-icon left color="black">
                                    mdi-alpha-p
                                </v-icon>
                                <v-spacer />
                                <span class="font-weight-medium mr-2">
                                    {{ formatDurationString(item.leaveMinutes) }}
                                </span>
                            </v-sheet>
                            <v-sheet rounded="circle" class="d-flex justify-center align-center" height="42" width="42"
                                v-else>
                                <v-icon color="grey lighten-1">
                                    mdi-alpha-p
                                </v-icon>
                            </v-sheet>

                            <span class="mx-1" />

                            <v-sheet color="purple lighten-3" rounded="pill"
                                class="d-flex flex-row justify-space-between align-center px-2" height="42"
                                v-if="item.workDayData.overtimeMinutes > 0">
                                <v-icon left color="black">
                                    mdi-alpha-s
                                </v-icon>
                                <v-spacer />
                                <span class="font-weight-medium mr-2">
                                    {{ formatDurationString(item.workDayData.overtimeMinutes) }}
                                </span>
                            </v-sheet>
                            <v-sheet rounded="circle" class="d-flex justify-center align-center" height="42" width="42"
                                v-else>
                                <v-icon color="grey lighten-1">
                                    mdi-alpha-s
                                </v-icon>
                            </v-sheet>

                            <span class="mx-1" />

                            <v-sheet color="amber accent-4" rounded="circle" class="d-flex justify-center align-center"
                                height="42" width="42" v-if="item.workDayData.businessTrip">
                                <v-icon color="black">
                                    mdi-alpha-t
                                </v-icon>
                            </v-sheet>
                            <v-sheet rounded="circle" class="d-flex justify-center align-center" height="42" width="42"
                                v-else>
                                <v-icon color="grey lighten-1">
                                    mdi-alpha-t
                                </v-icon>
                            </v-sheet>

                            <span class="mx-1" />

                            <v-sheet color="deep-orange lighten-3" rounded="circle"
                                class="d-flex justify-center align-center" height="42" width="42"
                                v-if="item.availabilityDay">
                                <v-icon color="black">
                                    mdi-alpha-r
                                </v-icon>
                            </v-sheet>
                            <v-sheet rounded="circle" class="d-flex justify-center align-center" height="42" width="42"
                                v-else>
                                <v-icon color="grey lighten-1">
                                    mdi-alpha-r
                                </v-icon>
                            </v-sheet>

                            <span class="mx-1" />

                            <v-sheet color="pink lighten-3" rounded="circle" class="d-flex justify-center align-center"
                                height="42" width="42" v-if="item.sickDay">
                                <v-icon color="black">
                                    mdi-alpha-m
                                </v-icon>
                            </v-sheet>
                            <v-sheet rounded="circle" class="d-flex justify-center align-center" height="42" width="42"
                                v-else>
                                <v-icon color="grey lighten-1">
                                    mdi-alpha-m
                                </v-icon>
                            </v-sheet>

                        </v-col>

                    </v-container>

                </template>

            </v-data-table>
        </v-layout>
        <MonthlyUserStatus
            :show="monthlyUserStatus.show"
            :username="monthlyUserStatus.username"
            :selectedMonth="wrappedDate"
            v-on:close-monthly-user-status-dialog="monthlyUserStatus.show = false"
            />
    </v-container>
</template>
<script>
//export const DateInput = () => import('../global/DateInput.vue')
const MonthlyUserStatus = () => import('../../components/admin/dialogs/MonthlyUserStatus.vue')

import userApi from '../../api/user'
import userWorkScheduleApi from '../../api/userWorkSchedule'
import userWorkDayApi from '../../api/userWorkDay'

import workTimesService from '../../services/mixins/workTimesMixin'

import * as dataStorageActions from '../../store/actions/dataStorage'

import { mapActions, mapState, mapGetters } from 'vuex'
import { DateTime } from 'luxon'

export default {
    data: function () {
        return {
            //loading: false,
            search: '',
            isoDate: null,
            userData: [],
            datePicker: false,
            headers: [
                { text: 'Utente', value: 'displayName', width: "13%" },
                { text: 'Azienda', value: 'companyName', width: "10%" },
                { text: 'Orario', value: 'scheduledTimes', width: "10%", sortable: false },
                { text: 'Timbrature', value: 'records', sortable: false, align: 'center' },
                //{ text: 'Status', value: 'workDayData', width: "10%", sortable: false },
            ],
            loadingObject: [],
            monthlyUserStatus: {
                show: false,
                username: null,
                // selectedMonth: null,
            },

        }
    },
    mixins: [workTimesService],
    components: {
        //DateInput
       MonthlyUserStatus,
    },
    methods: {
        ...mapActions({
            saveUsers: dataStorageActions.DATASTORAGE_SETUSERS,
        }),
        openMonthlyStatusDialog: function(user) {
            this.monthlyUserStatus.username = user.username;
            // this.monthlyUserStatus.selectedMonth = new Date(this.isoDate);
            this.monthlyUserStatus.show = true;
        },
        fetchData: function () {
            this.userData = [];
            userApi.getUsers().then(response => {
                if (response.success) {
                    this.saveUsers(response.data);
                    let users = this.users.filter(user => user.includeInReports && !user.disabled);
                    users.forEach(user => { this.loadingObject.push(true); });
                    users.forEach(user => {

                        let scheduledTimes = this.findActualUserWorkScheduledTimes(user.userWorkSchedules);
                        userWorkDayApi.userWorkDayByUserDate(this.isoDate, user.username).then(response => {
                            let workDayData = { workDayRecords: [], overtimeMinutes: 0, businessTrip: false };

                            if (response.success) {
                                workDayData = response.data || workDayData;
                            } else {
                                console.log();
                            }
                            let isSickDay = !!workDayData.workDayRecords.find(record => record.recordType == 'SICK_RECORD');
                            let workDaySummary = this.userWorkDaySummary(workDayData.workDayRecords, scheduledTimes, this.isoDate);
                            let percentage = Math.min(Math.floor((((workDayData.businessTrip ? workDaySummary.clockMinutes : workDaySummary.registeredMinutes) + workDaySummary.absenceMinutes) / workDaySummary.scheduledMinutes) * 100), 100);
                            let progressObject = {
                                progressPercentage: percentage,
                                registeredTime: workDaySummary.registeredMinutes + workDaySummary.absenceMinutes,
                                scheduledTime: workDaySummary.scheduledMinutes,
                                complete: percentage == 100 || isSickDay || workDaySummary.isValid,
                            };

                            let leaveMinutes = 0;
                            workDayData.workDayRecords.filter(record => record.recordType == 'LEAVE_RECORD').forEach(lr => {
                                leaveMinutes += lr.leaveRecord.minuteDuration;
                            });

                            this.userData.push(
                                {
                                    username: user.username,
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    displayName: `${user.lastName} ${user.firstName}`,
                                    companyName: user.company.companyName,
                                    clockRecordObjects: this.createClockObjects(workDayData.workDayRecords.filter(record => record.recordType == 'CLOCK_RECORD').map(record => record.clockRecord), scheduledTimes),
                                    clockRecords: workDayData.workDayRecords.filter(record => record.recordType == 'CLOCK_RECORD'),
                                    otherRecords: workDayData.workDayRecords.filter(record => record.recordType != 'CLOCK_RECORD'),
                                    workDayData: workDayData,
                                    scheduledTimes: scheduledTimes,
                                    workDaySummary: workDaySummary,
                                    progressObject: progressObject,
                                    leaveMinutes: leaveMinutes,
                                    sickDay: workDayData.workDayRecords.find(record => record.recordType == 'SICK_RECORD') != null,
                                    availabilityDay: workDayData.workDayRecords.find(record => record.recordType == 'AVAILABILITY_RECORD') != null,
                                    holidayDay: workDayData.workDayRecords.find(record => record.recordType == 'HOLIDAY_RECORD') != null,
                                    hasVariationProposal: workDayData.variationProposal != null
                                }
                            );
                            this.loadingObject.pop(true);
                        });
                    })

                } else {
                    console.log();
                }

            });

        },
        createClockObjects: function (cRecords, scheduledTimes) {

            let morningClockInTime = null;
            let morningClockOutTime = null;
            if (scheduledTimes && scheduledTimes.morningTimes.clockInTime && scheduledTimes.morningTimes.clockOutTime) {
                morningClockInTime = DateTime.fromISO(scheduledTimes.morningTimes.clockInTime).set(this.objectDate);
                morningClockOutTime = DateTime.fromISO(scheduledTimes.morningTimes.clockOutTime).set(this.objectDate);
            }

            let afternoonClockInTime = null;
            let afternoonClockOutTime = null;
            if (scheduledTimes && scheduledTimes.afternoonTimes.clockInTime && scheduledTimes.afternoonTimes.clockOutTime) {
                afternoonClockInTime = DateTime.fromISO(scheduledTimes.afternoonTimes.clockInTime).set(this.objectDate);
                afternoonClockOutTime = DateTime.fromISO(scheduledTimes.afternoonTimes.clockOutTime).set(this.objectDate);
            }

            let objectes = cRecords.map(record => {
                let lateEnter = false;
                let earlyLeave = false;

                let punchInTime = DateTime.fromISO(record.punchInTime);
                let punchOutTime = record.punchOutTime ? DateTime.fromISO(record.punchOutTime) : null;

                if (morningClockInTime && morningClockOutTime) {
                    if (
                        (!afternoonClockInTime || !afternoonClockOutTime || punchInTime < morningClockOutTime) && punchInTime > morningClockInTime.plus({ minutes: 1 })
                    ) {
                        lateEnter = true;
                    }

                    if (punchOutTime > morningClockInTime && punchOutTime < morningClockOutTime) {
                        earlyLeave = true;
                    }
                }

                if (afternoonClockInTime && afternoonClockOutTime) {
                    if (
                        punchInTime < afternoonClockOutTime && punchInTime > afternoonClockInTime.plus({ minutes: 1 })
                    ) {
                        lateEnter = true;
                    }

                    if (punchOutTime > afternoonClockInTime && punchOutTime < afternoonClockOutTime) {
                        earlyLeave = true;
                    }
                }

                return {
                    punchInTime: punchInTime,
                    punchOutTime: punchOutTime,
                    earlyLeave: earlyLeave,
                    lateEnter: lateEnter
                }

            });
            return objectes;
        },
        findActualUserWorkScheduledTimes: function (schedules) {
            let selectedSchedule = schedules.find(schedule => {
                let startDate = DateTime.fromISO(schedule.validityStartDate);
                let stopDate = schedule.validityStopDate ? DateTime.fromISO(schedule.validityStopDate) : null;

                return startDate.startOf('day') <= this.wrappedDate && (stopDate == null || stopDate.endOf('day') >= this.wrappedDate);
            })
            return selectedSchedule ? selectedSchedule.scheduledWeekWorkTimes[this.weekDayTimesKey] : null;
        },
        formatDurationString: function (minutesDuration) {
            return (minutesDuration >= 60 ? Math.floor(minutesDuration / 60) + 'h ' : '') + (minutesDuration % 60 != 0 ? minutesDuration % 60 + 'm' : '').trim();
        },
        formatTimeString: function (timeISO) {
            if (timeISO) {
                return DateTime.fromISO(timeISO).toLocaleString(DateTime.TIME_24_SIMPLE);
            } else {
                return "--:--";
            }
        },
        addDaysToDate: function (daysOffset) {
            this.wrappedDate = this.wrappedDate.plus({ days: daysOffset });
        }
    },
    computed: {
        ...mapState({
            users: state => state.dataStorage.users,
        }),
        ...mapGetters({
            isHolidayCheck: dataStorageActions.DATASTORAGE_ISHOLIDAYCHECK,
        }),
        loading: function () {
            return this.loadingObject.length > 0;
        },
        wrappedDate: {
            get: function () {
                if (this.isoDate) {
                    return DateTime.fromISO(this.isoDate);
                } else {
                    return DateTime.now();
                }
            },
            set: function (val) {
                this.isoDate = val.toISODate();
            }

        },
        objectDate: function () {
            return {
                day: this.wrappedDate.day,
                month: this.wrappedDate.month,
                year: this.wrappedDate.year
            };
        },
        jsDate: function () {
            return this.isoDate ? new Date(this.isoDate) : null;
        },
        formattedDateString: function () {
            return this.wrappedDate.setLocale("it").toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
        },
        weekDayTimesKey: function () {
            return ["mondayWorkTimes", "tuesdayWorkTimes", "wednesdayWorkTimes", "thursdayWorkTimes", "fridayWorkTimes", "saturdayWorkTimes", "sundayWorkTimes"][this.wrappedDate.weekday - 1];
        },
        todayShowing: function () {
            return this.isoDate === DateTime.now().toISODate();
        },
        isPastShowing: function () {
            return this.wrappedDate.startOf("day") < DateTime.now().startOf("day");
        }
    },
    watch: {
        isoDate: function () {
            this.fetchData();
        },
    },
    mounted: function () {
        this.isoDate = DateTime.now().toISODate();
        //this.userData = [];
        //this.fetchData();
    },
}
</script> 