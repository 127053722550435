import axiosInstance from '../services/axiosInstance'

export default function(endpoint, objectArgument, dataResponseName) {
    return axiosInstance.post(
        endpoint,
        objectArgument
    ).then(response => {
        let gQLResponse = response.data;
        if (gQLResponse.errors) {
            //gestione errore
            console.log("error: ", gQLResponse.errors.map(error => error.message).join('; '));
            return {success: false};
        } else {
            return {success: true, data: gQLResponse.data[dataResponseName]};
        }
    })
}