import gqlRequestManager from './gqlRequestManager'

import { userWorkDayVariationProposalEndpoint } from './endpoints'
import { 
    getUserWorkDayVariationProposals,
    createUserWorkDayVariationProposal,
    applyUserWorkDayVariationProposal,
    rejectUserWorkDayVariationProposal,
    deleteUserWorkDayVariationProposal
    } from '../utils/queries/userWorkDayVariationProposal'

export default {
    userWorkDayVariationProposals: function() {
        return gqlRequestManager(
            userWorkDayVariationProposalEndpoint, 
            {
                query: getUserWorkDayVariationProposals.query,
            },
            getUserWorkDayVariationProposals.dataName
        );
    },
    createUserWorkDayVariationProposal: function(workDate, businessTrip, overtimeMinutes, variationProposalNotes, userWorkDayRecords) {
        return gqlRequestManager(
            userWorkDayVariationProposalEndpoint, 
            {
                query: createUserWorkDayVariationProposal.query,
                variables: {
                    workDate: workDate,
                    businessTrip: businessTrip,
                    variationProposalNotes: variationProposalNotes,
                    overtimeMinutes: overtimeMinutes,
                    userWorkDayRecords: userWorkDayRecords
                }
            },
            createUserWorkDayVariationProposal.dataName
        );
    },
    applyUserWorkDayVariationProposal: function(workDate, username) {
        return gqlRequestManager(
            userWorkDayVariationProposalEndpoint, 
            {
                query: applyUserWorkDayVariationProposal.query,
                variables: {
                    workDate: workDate,
                    username: username
                }
            },
            applyUserWorkDayVariationProposal.dataName
        );
    },
    rejectUserWorkDayVariationProposal: function(workDate, username) {
        return gqlRequestManager(
            userWorkDayVariationProposalEndpoint, 
            {
                query: rejectUserWorkDayVariationProposal.query,
                variables: {
                    workDate: workDate,
                    username: username
                }
            },
            rejectUserWorkDayVariationProposal.dataName
        );
    },
    deleteUserWorkDayVariationProposal: function(workDate) {
        return gqlRequestManager(
            userWorkDayVariationProposalEndpoint, 
            {
                query: deleteUserWorkDayVariationProposal.query,
                variables: {
                    workDate: workDate
                }
            },
            deleteUserWorkDayVariationProposal.dataName
        );
    },
}
