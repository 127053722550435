export const getUserWorkSchedules = {
    query: `
        query
        {
            userWorkSchedules
            {
                user {
                    username
                }
                baseWorkScheduleDummy{
                    workScheduleDummyName
                }
                validityStartDate
                validityStopDate
                scheduledWeekWorkTimes{
                    mondayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    tuesdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    wednesdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    thursdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    fridayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    saturdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    sundayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                }
            }
        }
    `,
    dataName: 'userWorkSchedules'
}

export const getUserWorkSchedulesByUser = {
    query: `
        query($username:String!)
        {
            userWorkSchedulesByUser(username: $username)
            {
                user {
                    username
                }
                baseWorkScheduleDummy{
                    workScheduleDummyName
                }
                validityStartDate
                validityStopDate
                scheduledWeekWorkTimes{
                    mondayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    tuesdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    wednesdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    thursdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    fridayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    saturdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    sundayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                }
            }
        }
    `,
    dataName: 'userWorkSchedulesByUser'
}

export const createUserWorkSchedule = {
    query: `
        mutation($userWorkSchedule:PostUserWorkScheduleInput!, $username:String!, $baseWorkScheduleDummyName:String){
            createUserWorkSchedule(userWorkSchedule: $userWorkSchedule, username: $username, baseWorkScheduleDummyName: $baseWorkScheduleDummyName){
                baseWorkScheduleDummy{
                    workScheduleDummyName
                }
                validityStartDate
                validityStopDate
                scheduledWeekWorkTimes{
                    mondayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    tuesdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    wednesdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    thursdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    fridayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    saturdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    sundayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                }
            }
        }
    `,
    dataName: 'createUserWorkSchedule'
}

export const updateUserWorkSchedule = {
    query: `
        mutation($username:String!, $userWorkScheduleValidityStartDate:DateTime!, $updatedUserWorkSchedule:PostUserWorkScheduleInput, $updatedBaseWorkScheduleDummyName:String)
        {
            updateUserWorkSchedule(
                username: $username,
                userWorkScheduleValidityStartDate: $userWorkScheduleValidityStartDate,
                updatedUserWorkSchedule: $updatedUserWorkSchedule,
                updatedBaseWorkScheduleDummyName: $updatedBaseWorkScheduleDummyName
            )
            {
                baseWorkScheduleDummy{
                    workScheduleDummyName
                }
                validityStartDate
                validityStopDate
                scheduledWeekWorkTimes{
                    mondayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    tuesdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    wednesdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    thursdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    fridayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    saturdayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                    sundayWorkTimes{
                        morningTimes{
                            clockInTime
                            clockOutTime
                        }
                        afternoonTimes{
                            clockInTime
                            clockOutTime
                        }
                    }
                }
            }
        }
    `,
    dataName: 'updateUserWorkSchedule'
}

export const deleteUserWorkSchedule = {
    query: `
        mutation($username:String!, $userWorkScheduleValidityStartDate:DateTime!)
        {
            deleteUserWorkSchedule(
                username: $username,
                userWorkScheduleValidityStartDate: $userWorkScheduleValidityStartDate
            )
        }
    `,
    dataName: 'deleteUserWorkSchedule'
}