<template>
  <v-app>
    <AppToolbar :clockObject="clockObject"/>
    <v-main>
       <router-view />
    </v-main>
    <SnackBar/>
  </v-app>
</template>
<script>
export const AppToolbar = () => import('../../components/core/AppToolBar.vue')
export const SnackBar = () => import('../../components/core/AppSnackBar.vue')

export default {
    components: {
        AppToolbar,
        SnackBar,
    },
    props: {
      clockObject: {
        type: Object,
        required: true
      },
    },
    methods: {
    },
    computed: {
    },
}
</script>
<style>
  html { overflow-y: auto }
</style>