import gqlRequestManager from './gqlRequestManager'

import { consolidationReportEndPoint } from './endpoints'
import { 
        createConsolidationRecord,
        getExportableConsolidationRecords,
        undoConsolidationRecord,
        getConsolidationRecords,
        fixConsolidationError
    } from '../utils/queries/consolidationRecord'

export default {
    createConsolidationRecord: function(companyNames, year, month) {
        return gqlRequestManager(
            consolidationReportEndPoint, 
            {
                query: createConsolidationRecord.query,
                variables: {
                    companyNames: companyNames,
                    year: year,
                    month: month
                }
            },
            createConsolidationRecord.dataName
        );
    },
    getExportableConsolidationRecords: function() {
        return gqlRequestManager(
            consolidationReportEndPoint, 
            {
                query: getExportableConsolidationRecords.query
            },
            getExportableConsolidationRecords.dataName
        );
    },
    undoConsolidationRecord: function(companyName, year, month) {
        return gqlRequestManager(
            consolidationReportEndPoint, 
            {
                query: undoConsolidationRecord.query,
                variables: {
                    companyName: companyName,
                    year: year,
                    month: month
                }
            },
            undoConsolidationRecord.dataName
        );
    },
    getConsolidationRecords: function() {
        return gqlRequestManager(
            consolidationReportEndPoint, 
            {
                query: getConsolidationRecords.query,
            },
            getConsolidationRecords.dataName
        );
    },
    fixConsolidationError: function( workDate, username, overtimeMinutes, businessTrip, userWorkDayRecords) {
        return gqlRequestManager(
            consolidationReportEndPoint,
            {
                query: fixConsolidationError.query,
                variables: {
                    workDate: workDate,
                    username: username,
                    overtimeMinutes: overtimeMinutes,
                    businessTrip: businessTrip,
                    userWorkDayRecords: userWorkDayRecords
                }
            },
            fixConsolidationError.dataName
        );
    }
}