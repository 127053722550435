import gqlRequestManager from './gqlRequestManager'

import { departmentEndPoint } from './endpoints'
import { 
    getDepartments,
    getDepartmentsWithUsersData,
    createDepartment,
    updateDepartment,
    deleteDepartment
 } from '../utils/queries/department'

export default {
    getDepartments: function() {
        return gqlRequestManager(
            departmentEndPoint, 
            {
                query: getDepartments.query,
            },
            getDepartments.dataName
        );
    },

    getDepartmentsWithUsersData: function() {
        return gqlRequestManager(
            departmentEndPoint, 
            {
                query: getDepartmentsWithUsersData.query,
            },
            getDepartmentsWithUsersData.dataName
        );
    },

    createDepartment: function(departmentName) {
        return gqlRequestManager(
            departmentEndPoint, 
            {
                query: createDepartment.query,
                variables: {
                    departmentName: departmentName
                }
            },
            createDepartment.dataName
        );
    },

    updateDepartment: function(departmentName, updatedDepartmentName) {
        return gqlRequestManager(
            departmentEndPoint, 
            {
                query: updateDepartment.query,
                variables: {
                    departmentName: departmentName,
                    updatedDepartmentName: updatedDepartmentName
                }
            },
            updateDepartment.dataName
        );
    },

    deleteDepartment: function(departmentName) {
        return gqlRequestManager(
            departmentEndPoint, 
            {
                query: deleteDepartment.query,
                variables: {
                    departmentName: departmentName
                }
            },
            deleteDepartment.dataName
        );
    },
}

