import * as appStateActions from '../actions/appState'

const state = {
    loading: true,
}

const getters = {}

const actions = {
    [appStateActions.APP_SETLOADING]: async ({
        rootState,
        dispatch,
        commit
    }, loading) => {
        commit(appStateActions.APP_SETLOADSTATUS, loading)
        return
    },

}

const mutations = {
    [appStateActions.APP_SETLOADSTATUS]: (state, loading) => {
        state.loading = loading;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}