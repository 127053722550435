

export const checkAuthEndpoint = '/login/status_check'
export const loginEndpoint = '/login'
export const userEndpoint = '/user'
export const userWorkDayEndpoint = '/user_work_day'
export const userWorkDayVariationProposalEndpoint = '/user_work_day_variation_proposal'
export const workDayRecordEndPoint = '/work_day_record'
export const companyEndPoint = '/company'
export const departmentEndPoint = '/department'
export const workScheduleDummyEndPoint = '/work_schedule_dummy'
export const localYearHolidaysEndPoint = '/local_year_holidays'
export const userWorkScheduleEndPoint = '/user_work_schedule'
export const consolidationReportEndPoint = '/consolidation_record'
export const reportEndPoint = '/report'