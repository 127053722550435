import gqlRequestManager from './gqlRequestManager'

import { userEndpoint } from './endpoints'
import { 
    getCurrentUser, 
    getUsers, 
    createUser,
    updateUser,
    deleteUser, 
    disableUser,
    enableUser
 } from '../utils/queries/user'

export default {
    currentUser: function() {
        return gqlRequestManager(
            userEndpoint, 
            {
                query: getCurrentUser.query,
            },
            getCurrentUser.dataName
        );
    },
    getUsers: function() {
        return gqlRequestManager(
            userEndpoint, 
            {
                query: getUsers.query,
            },
            getUsers.dataName
        );
    },
    createUser: function(user, userCompanyName) {
        return gqlRequestManager(
            userEndpoint,
            {
                query: createUser.query,
                variables: {
                    user: user,
                    userCompanyName: userCompanyName
                }
            },
            createUser.dataName
        )
    },
    updateUser: function(username, updatedUser, updatedCompanyName) {
        return gqlRequestManager(
            userEndpoint,
            {
                query: updateUser.query,
                variables: {
                    username: username,
                    updatedUser: updatedUser,
                    updatedCompanyName: updatedCompanyName
                }
            },
            updateUser.dataName
        )
    },
    deleteUser: function(username) {
        return gqlRequestManager(
            userEndpoint,
            {
                query: deleteUser.query,
                variables: {
                    username: username
                }
            },
            deleteUser.dataName
        );
    },
    disableUser: function(username) {
        return gqlRequestManager(
            userEndpoint,
            {
                query: disableUser.query,
                variables: {
                    username: username
                }
            },
            disableUser.dataName
        );
    },
    enableUser: function(username) {
        return gqlRequestManager(
            userEndpoint,
            {
                query: enableUser.query,
                variables: {
                    username: username
                }
            },
            enableUser.dataName
        );
    }
}

