export const createConsolidationRecord = {
    query:  `
        mutation($companyNames:[String!]!, $year:Short!, $month:Short!)
        {
            createConsolidationRecord(
                companyNames: $companyNames,
                year: $year,
                month: $month
            )
        }
    `,
    dataName: 'createConsolidationRecord'
}

export const undoConsolidationRecord = {
    query:  `
        mutation($companyName:String!, $year:Short!, $month:Short!)
        {
            undoConsolidationRecord(
                companyName: $companyName,
                year: $year,
                month: $month
            )
        }
    `,
    dataName: 'undoConsolidationRecord'
}

export const getConsolidationRecords = {
    query:  `
        query
        {
            consolidationRecords
            {
                company {
                    companyName
                }
                month
                year
                creationTime
                usersErrors{
                    user {
                        username
                        email
                        firstName
                        lastName
                    }
                    consolidationErrors {
                        workDate
                        errorCode
                        blocking
                        userWorkDay {
                            workDate
                            businessTrip
                            overtimeMinutes
                            workDayRecords {
                                dateIndex
                                note
                                recordType
                                clockRecord {
                                    punchInTime
                                    punchOutTime
                                    punchInTimeOffset
                                    punchOutTimeOffset
                                }
                                leaveRecord {
                                    minuteDuration
                                }
                                holidayRecord {
                                    minuteDuration
                                }
                                sickRecord {
                                    medicalCertificationDelivered
                                }
                                availabilityRecord {
                                    temp
                                }
                            }
                            variationProposal {
                                creationTime
                            }
                        }
                        dateWorkTimes {
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                    }
                }
            }
        }
    `,
    dataName: 'consolidationRecords'
}

export const getExportableConsolidationRecords = {
    query:  `
        query
        {
            exportableConsolidationRecords
            {
                company {
                    companyName
                }
                month
                year
                creationTime
                workingHoursReportPresent
            }
        }
    `,
    dataName: 'exportableConsolidationRecords'
}

export const fixConsolidationError = {
    query: `
        mutation($workDate:Date!, $username:String!, $overtimeMinutes:Int!, $businessTrip:Boolean!, $userWorkDayRecords:[PostWorkDayRecordInput!])
        {
            fixConsolidationError(
                workDate: $workDate,
                username: $username,
                overtimeMinutes: $overtimeMinutes,
                businessTrip: $businessTrip,
                userWorkDayRecords: $userWorkDayRecords
            )
        }
    `,
    dataName: 'fixConsolidationError'
}
