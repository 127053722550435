import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticStyle:{"overflow-y":"auto"},attrs:{"fluid":""}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.holidaysData,"search":_vm.search,"disable-pagination":"","hide-default-footer":"","single-expand":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Cerca","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VBtn,{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogEditHoliday = true}}},[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Aggiungi Festività ")],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])}),_c('YearHolidaysEditDialog',{attrs:{"show":_vm.dialogEditHoliday,"editedYearHolidays":_vm.editedItem},on:{"dialog-close":_vm.closeEditDialog,"selected-year-change":_vm.loadEditingHolidayDates,"save-local-year-holidays":_vm.saveLocalYearHolidays}}),_c('ConfirmDialog',{attrs:{"dialog":_vm.confirmDialog,"dialogText":_vm.confirmDialogText},on:{"c-dialog-confirm":_vm.confirmDeleteItem,"c-dialog-cancel":_vm.cancelDeleteItem}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }