import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import { RootView } from '@/views';
import HomeView from '../views/dashboard/Home.vue';
import AdminView from '../views/admin/Admin.vue'
import AnagraphicsView from '../components/admin/AnagraphicsRoot.vue'
import ConsolidationView from '../components/admin/ConsolidationRecords.vue'
import VariationProposalsView from '../components/admin/VariationProposals.vue'
import HolidaysScheduleView from '../components/admin/HolidaysSchedule'
import UsersDailyStatusView from '../components/admin/UsersDailyStatus.vue'
import ReportsView from '../views/reports/Reports.vue'
import LoginHome from '../views/login/Login.vue';
import ErrorPage from '../views/error/Error.vue'
import NotFoundPage from '../views/404/NotFound.vue'

import UnauthorizedPage from '../components/error/Unauthorized.vue'
import InternalErrorPage from '../components/error/InternalError.vue'
import UsersTableView from '../components/admin/anagraphics/tables/UsersTable.vue'
import CompaniesTableView from '../components/admin/anagraphics/tables/CompaniesTable.vue'
import WorkScheduleDummiesTableView from '../components/admin/anagraphics/tables/WorkScheduleDummiesTable.vue'
import HolidaysTableView from '../components/admin/anagraphics/tables/HolidaysTable.vue'
import DepartmentsTableView from '../components/admin/anagraphics/tables/DepartmentsTable.vue'

export default new Router({
    routes: [{
        path: '/',
        component: RootView,
        children: [
            {
                path: '',
                name: 'Home',
                component: HomeView,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'login',
                name: 'Login',
                component: LoginHome,
                meta: {
                    guest: true
                }
            },
            {
                path: 'error',
                name: 'Error',
                component: ErrorPage,
                meta: {
                    guest: true
                },
                children: [
                    {
                        path: 'unauthorized',
                        name: 'Unauthorized',
                        component: UnauthorizedPage,
                        meta: {
                            requiresAuth: true
                        },
                    },
                    {
                        path: 'internal_error',
                        name: 'InternalError',
                        component: InternalErrorPage,
                    },
                ]
            },
            {
                path: 'admin',
                name: 'Admin',
                component: AdminView,
                meta: {
                    requiresAuth: true,
                    requiredRole: 'ANALYST'
                },
                children: [
                    {
                        path: 'anagraphics',
                        name: 'Anagraphic',
                        component: AnagraphicsView,
                        meta: {
                            requiresAuth: true,
                            requiredRole: 'ADMIN'
                        },
                        children: [
                            {
                                path: 'users',
                                name: 'UsersAnagraphic',
                                component: UsersTableView,
                            },
                            {
                                path: 'companies',
                                name: 'CompaniesAnagraphic',
                                component: CompaniesTableView,
                            },
                            {
                                path: 'departments',
                                name: 'DepartmentsAnagraphic',
                                component: DepartmentsTableView,
                            },
                            {
                                path: 'work_schedule_dummies',
                                name: 'WorkScheduleDummiesAnagraphic',
                                component: WorkScheduleDummiesTableView,
                            },
                            {
                                path: 'holidays',
                                name: 'HolidaysAnagraphic',
                                component: HolidaysTableView,
                            }
                        ]
                    },
                    {
                        path: 'data_consolidation',
                        name: 'DataConsolidation',
                        component: ConsolidationView,
                    },
                    {
                        path: 'variation_proposals',
                        name: 'VariationProposals',
                        component: VariationProposalsView,
                    },
                    {
                        path: 'absences_management',
                        name: 'AbsencesManagement',
                        component: HolidaysScheduleView,
                    },
                    {
                        path: 'users_daily_status',
                        name: 'UsersDailyStatus',
                        component: UsersDailyStatusView,
                    }
                    
                ]
            },
            {
                path: 'reports',
                name: 'Reports',
                component: ReportsView,
                meta: {
                    requiresAuth: true,
                    requiredRole: 'ANALYST'
                }
            }
        ]
    },
    { path: "*", component: NotFoundPage }
    ]
})