import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VContainer,{staticClass:"justify-center align-center d-flex flex-column",attrs:{"fill-height":""}},[_c(VImg,{staticClass:"shrink mb-6",attrs:{"alt":"Vuetify Logo","contain":"","src":require("@/assets/logo_black.svg"),"width":"120"}}),_c('span',{staticClass:"text-h3 font-weight-bold"},[_vm._v(" UGO ")]),_c('span',{staticClass:"font-italic mb-12"},[_vm._v(" Utilità Gestione Orari ")]),_c(VCard,{staticClass:"pa-8 pt-0",attrs:{"elevation":"12","width":"350","loading":_vm.loading,"disabled":_vm.loading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login.apply(null, arguments)}}},[_c(VCardTitle,{staticClass:"pt-8"},[_vm._v(" LOGIN ")]),_c(VCardText,[_c(VForm,[_c(VTextField,{attrs:{"name":"username","label":"Nome Utente","type":"email","error":_vm.invalidCredentialsMessageShow},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"name":"password","label":"Password","type":"password","error":_vm.invalidCredentialsMessageShow},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c(VCardActions,{staticClass:"d-flex justify-center"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.login}},[_vm._v(" ENTRA ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }