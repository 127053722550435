export const getDepartments = {
    query:  `
        query
        {
            departments
            {
                departmentName
                usersCount
                users {
                    username
                    company {
                        companyName
                    }
                }
            }
        }
    `,
    dataName: 'departments'
}

export const getDepartmentsWithUsersData = {
    query:  `
        query
        {
            departments
            {
                departmentName
                usersCount
                users {
                    username
                    firstName
                    lastName
                    company {
                        companyName
                    }
                    userWorkSchedules {
                        baseWorkScheduleDummy {
                            workScheduleDummyName
                        }
                        validityStartDate
                        validityStopDate
                        scheduledWeekWorkTimes{
                            mondayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            tuesdayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            wednesdayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            thursdayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            fridayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            saturdayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                            sundayWorkTimes{
                                morningTimes{
                                    clockInTime
                                    clockOutTime
                                }
                                afternoonTimes{
                                    clockInTime
                                    clockOutTime
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    dataName: 'departments'
}

export const deleteDepartment = {
    query: `
        mutation($departmentName:String!)
        {
            deleteDepartment(
                departmentName: $departmentName
            )
        }
    `,
    dataName: 'deleteCompany'
}

export const createDepartment = {
    query: `
        mutation($departmentName:String!)
        {
        createDepartment(
                departmentName: $departmentName
            ) {
                departmentName
            }
        }
    `,
    dataName: 'createDepartment'
}

export const updateDepartment = {
    query: `
        mutation($departmentName:String!, $updatedDepartmentName:String!)
            {
            updateDepartment(
                departmentName: $departmentName,
                updatedDepartmentName: $updatedDepartmentName
            ) {
                departmentName
            }
        }
    `,
    dataName: 'updateDepartment'
}