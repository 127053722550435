<template>
    <v-container fluid>
        <v-data-table
            :search="search"
            :headers="workScheduleDummiesHeaders"
            :items="displayWorkScheduleDummies"
            :single-expand="false"
            :expanded.sync="expanded"
            item-key="workScheduleDummyName"
            show-expand
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cerca"
                single-line
                hide-details
                ></v-text-field>
            
                <v-divider
                class="mx-4"
                inset
                vertical
                ></v-divider>

                    <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="dialogEditWSD = true"
                    >
                    <v-icon
                        left
                        dark
                    >
                        mdi-plus
                    </v-icon>
                    Crea Modello Orario
                    </v-btn>
                <!-- <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="text-h6">Are you sure you want to delete {{editedItem.workScheduleDummyName}}?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog> -->
            </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
                <v-container>
                <v-simple-table>
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th>
                        </th>
                        <th class="text-center">
                            Mattino
                        </th>
                        <th class="text-center">
                            Pomeriggio
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(weekDay, index) in orderedWeekDayKeys" :key="index">
                        <td class="text-left"> {{weekDay.text}}</td>
                        <td class="text-center">
                            <v-container>
                                <v-row justify="center" align="center">
                                <v-col
                                    cols="12"
                                    md="5"
                                >
                                <v-icon v-if="item.defaultWeekWorkTimes[weekDay.key].morningTimes.clockInTime">mdi-import</v-icon>
                                {{formatDisplayTime(item.defaultWeekWorkTimes[weekDay.key].morningTimes.clockInTime)}}
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="5"
                                >
                                <v-icon v-if="item.defaultWeekWorkTimes[weekDay.key].morningTimes.clockInTime">mdi-export</v-icon>
                                {{formatDisplayTime(item.defaultWeekWorkTimes[weekDay.key].morningTimes.clockOutTime)}}
                                </v-col>
                                </v-row>
                            </v-container>
                        </td>
                        <td class="text-center">
                            <v-container>
                                <v-row justify="center" align="center">
                                <v-col
                                    cols="12"
                                    md="5"
                                >
                                <v-icon v-if="item.defaultWeekWorkTimes[weekDay.key].afternoonTimes.clockInTime">mdi-import</v-icon>
                                {{formatDisplayTime(item.defaultWeekWorkTimes[weekDay.key].afternoonTimes.clockInTime)}}
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="5"
                                >
                                <v-icon v-if="item.defaultWeekWorkTimes[weekDay.key].afternoonTimes.clockOutTime">mdi-export</v-icon>
                                {{formatDisplayTime(item.defaultWeekWorkTimes[weekDay.key].afternoonTimes.clockOutTime)}}
                                </v-col>
                                </v-row>
                            </v-container>
                        </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-container>
            </td>
            </template>
            <template v-slot:item.weekTime="{ item }">
                {{formattedDuration(item.weekMinutes)}}
            </template>
            <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteItem(item)"
            >
                mdi-delete
            </v-icon>
            </template>
        </v-data-table>
        <ConfirmDialog
            :dialog="dialogDelete" 
            :dialogText="'Eliminare definitivamente il Modello Orario ' + editedItem.workScheduleDummyName + '?'"
            v-on:c-dialog-confirm="deleteItemConfirm"
            v-on:c-dialog-cancel="closeDelete"
        />
        <WorkScheduleDummyEdit
            :show="dialogEditWSD"
            :editedWorkScheduleDummy="editedItem"
            :edit="editingWSD"
            v-on:dialog-close="closeDialogEditWSD"
            v-on:create-work-schedule-dummy="createWorkScheduleDummy"
            v-on:update-work-schedule-dummy="updateWorkScheduleDummy"
        />
    </v-container>
</template>

<script>
export const ConfirmDialog = () => import('../../../global/ConfirmDialog.vue')
export const WorkScheduleDummyEdit = () => import('../dialogs/WorkScheduleDummyEdit.vue')

import workScheduleDummyApi from '../../../../api/workScheduleDummy'

import { DateTime } from 'luxon'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      search: '',
      expanded: [],
      dialogEditWSD: false,
      dialogDelete: false,
      editedIndex: -1,
      orderedWeekDayKeys: [
        {text: 'Lunedì', key: 'mondayWorkTimes', morning: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}, afternoon: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}},
        {text: 'Martedì', key: 'tuesdayWorkTimes', morning: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}, afternoon: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}},
        {text: 'Mercoledì', key: 'wednesdayWorkTimes', morning: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}, afternoon: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}},
        {text: 'Giovedì', key: 'thursdayWorkTimes', morning: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}, afternoon: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}},
        {text: 'Venerdì', key: 'fridayWorkTimes', morning: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}, afternoon: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}},
        {text: 'Sabato', key: 'saturdayWorkTimes', morning: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}, afternoon: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}},
        {text: 'Domenica', key: 'sundayWorkTimes', morning: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}, afternoon: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}},
      ],
      editedItem: {
          workScheduleDummyName: '',
          defaultWeekWorkTimes: {
              mondayWorkTimes: {
                  morningTimes: { clockInTime: null, clockOutTime: null, },
                  afternoonTimes: { clockInTime: null, clockOutTime: null, },
              },
              tuesdayWorkTimes: {
                  morningTimes: { clockInTime: null, clockOutTime: null, },
                  afternoonTimes: { clockInTime: null, clockOutTime: null, },
              },
              wednesdayWorkTimes: {
                  morningTimes: { clockInTime: null, clockOutTime: null, },
                  afternoonTimes: { clockInTime: null, clockOutTime: null, },
              },
              thursdayWorkTimes: {
                  morningTimes: { clockInTime: null, clockOutTime: null, },
                  afternoonTimes: { clockInTime: null, clockOutTime: null, },
              },
              fridayWorkTimes: {
                  morningTimes: { clockInTime: null, clockOutTime: null, },
                  afternoonTimes: { clockInTime: null, clockOutTime: null, },
              },
              saturdayWorkTimes: {
                  morningTimes: { clockInTime: null, clockOutTime: null, },
                  afternoonTimes: { clockInTime: null, clockOutTime: null, },
              },
              sundayWorkTimes: {
                  morningTimes: { clockInTime: null, clockOutTime: null, },
                  afternoonTimes: { clockInTime: null, clockOutTime: null, },
              }
          }
      },
      defaultItem: {
          workScheduleDummyName: '',
          defaultWeekWorkTimes: {
              mondayWorkTimes: {
                  morningTimes: { clockInTime: null, clockOutTime: null, },
                  afternoonTimes: { clockInTime: null, clockOutTime: null, },
              },
              tuesdayWorkTimes: {
                  morningTimes: { clockInTime: null, clockOutTime: null, },
                  afternoonTimes: { clockInTime: null, clockOutTime: null, },
              },
              wednesdayWorkTimes: {
                  morningTimes: { clockInTime: null, clockOutTime: null, },
                  afternoonTimes: { clockInTime: null, clockOutTime: null, },
              },
              thursdayWorkTimes: {
                  morningTimes: { clockInTime: null, clockOutTime: null, },
                  afternoonTimes: { clockInTime: null, clockOutTime: null, },
              },
              fridayWorkTimes: {
                  morningTimes: { clockInTime: null, clockOutTime: null, },
                  afternoonTimes: { clockInTime: null, clockOutTime: null, },
              },
              saturdayWorkTimes: {
                  morningTimes: { clockInTime: null, clockOutTime: null, },
                  afternoonTimes: { clockInTime: null, clockOutTime: null, },
              },
              sundayWorkTimes: {
                  morningTimes: { clockInTime: null, clockOutTime: null, },
                  afternoonTimes: { clockInTime: null, clockOutTime: null, },
              }
          }
      },
      workScheduleDummiesHeaders: [
          { text: '', value: 'data-table-expand' },
          { text: 'Nome Modello Orario', align: 'start', value: 'workScheduleDummyName' },
          { text: 'Ore settimanali', value: 'weekTime', align: 'start'},
          { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
    }
  },
  components: {
    ConfirmDialog,
    WorkScheduleDummyEdit
  },
  methods: {
    editItem (item) {
      this.editedIndex = this.displayWorkScheduleDummies.indexOf(item)
      this.editedItem = JSON.parse(JSON.stringify(item));
      delete this.editedItem.weekHours;
      this.dialogEditWSD = true
    },

    deleteItem (item) {
      this.editedIndex = this.displayWorkScheduleDummies.indexOf(item)
      this.editedItem = Object.assign({}, item)
      delete this.editedItem.weekHours;
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      let editedWorkScheduleDummy = { ...this.editedItem };
      workScheduleDummyApi.deleteWorkSchedleDummy(editedWorkScheduleDummy.workScheduleDummyName).then(response => {
            if(response.success) {
                this.emitFetchWorkScheduleDummies();
                this.closeDelete();
            }
        });
    },
    closeDialogEditWSD () {
        this.dialogEditWSD = false;
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
        })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      })
    },

    createWorkScheduleDummy(workScheduleDummy) {
        workScheduleDummyApi.createWorkScheduleDummy(workScheduleDummy).then(response => {
            if(response.success) {
                this.emitFetchWorkScheduleDummies();
                this.closeDialogEditWSD();
            }
        })
    },
    updateWorkScheduleDummy(workScheduleDummyName, updatedWorkScheduleDummy) {
        workScheduleDummyApi.updateWorkScheduleDummy(workScheduleDummyName, updatedWorkScheduleDummy).then(response => {
            if(response.success) {
                this.emitFetchWorkScheduleDummies();
                this.closeDialogEditWSD();
            }
        })
    },

    formatDisplayTime (isoTime) {
      let parsedTime = DateTime.fromISO(isoTime)
      if (parsedTime.invalid == null) 
        return parsedTime.toLocaleString(DateTime.TIME_24_SIMPLE);
      return '';
    },
    emitFetchWorkScheduleDummies() {
        this.$emit('refresh-work-schedule-dummies-data');
    },
    formattedDuration: function(minutes) {
        let otm = minutes;
        return (otm >= 60 ? Math.floor(otm/60) + ' ore ' : '') + (otm % 60 != 0 ? otm % 60 + ' minuti': '').trim();
    },
  },
  computed: {
    ...mapState({
        workScheduleDummies: state => state.dataStorage.workScheduleDummies,
    }),
    editingWSD: function() {
        //return JSON.stringify(this.editedItem) != JSON.stringify(this.defaultItem) 
        return this.editedIndex >= 0;
    },
    displayWorkScheduleDummies () {
      let dWorkScheduleDummies = JSON.parse(JSON.stringify(this.workScheduleDummies));
      dWorkScheduleDummies.forEach(workScheduleDummy => {
        this.orderedWeekDayKeys.forEach(weekDay => {
          let weekDayTimes = workScheduleDummy.defaultWeekWorkTimes[weekDay.key]
          let morningMinutes = 0
          let afternoonMinutes = 0
          if (weekDayTimes.morningTimes.clockInTime && weekDayTimes.morningTimes.clockOutTime) {
            let clockOut = DateTime.fromISO(weekDayTimes.morningTimes.clockOutTime)
            let clockIn = DateTime.fromISO(weekDayTimes.morningTimes.clockInTime)
            morningMinutes = clockOut.diff(clockIn, ['minutes']).minutes;
          }
          if (weekDayTimes.afternoonTimes.clockInTime && weekDayTimes.afternoonTimes.clockOutTime) {
            let clockOut = DateTime.fromISO(weekDayTimes.afternoonTimes.clockOutTime)
            let clockIn = DateTime.fromISO(weekDayTimes.afternoonTimes.clockInTime)
            afternoonMinutes = clockOut.diff(clockIn, ['minutes']).minutes;
          }
          workScheduleDummy.weekMinutes = (workScheduleDummy.weekMinutes || 0) + morningMinutes + afternoonMinutes
        }) 
      })
      return dWorkScheduleDummies;
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Crea Modello Orario' : 'Modifica Modello Orario';
    },
  },
}
</script>
