<template>
    <v-app>
        <v-container fill-height class="justify-center align-center d-flex flex-column">
            <v-img
                alt="Vuetify Logo"
                class="shrink mb-6"
                contain
                src="@/assets/logo_black.svg"
                width="120"
            />
            <span class="text-h3 font-weight-bold">
                UGO
            </span>
            <span class="font-italic mb-12">
                Utilità Gestione Orari
            </span>
            <v-card 
                elevation="12" 
                class="pa-8 pt-0" 
                width="350"
                :loading="loading"
                :disabled="loading"
                v-on:keyup.enter="login"
            >
                <v-card-title
                    class="pt-8"
                >
                    LOGIN
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-text-field
                            v-model="username"
                            name="username"
                            label="Nome Utente"
                            type="email"
                            :error="invalidCredentialsMessageShow"
                        >
                        </v-text-field>
                        <v-text-field
                            v-model="password"
                            name="password"
                            label="Password"
                            type="password"
                            :error="invalidCredentialsMessageShow"
                        >
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions
                    class="d-flex justify-center"
                >
                    <v-btn 
                        color="primary" 
                        @click="login"
                    > 
                        ENTRA
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
    </v-app>
</template>
<script>
import loginApi from '../../api/login'
import * as currentUserActions from '../../store/actions/currentUser'

import { mapActions } from 'vuex'

export default {
    data: function() {
        return {
            loading: false,
            username: null,
            password: null,
            invalidCredentialsMessageShow: false,
        };
    },
    watch: {
        username: function(val) {
            this.invalidCredentialsMessageShow = false;
        },
        password: function(val) {
            this.invalidCredentialsMessageShow = false;
        }
    },
    methods: {
        login: function() {
            this.loading = true;
            loginApi.login(this.username, this.password)
                .then(response => {
                    if(response.success) {
                        this.logIn(response.data);
                        this.$router.push({name: 'Home'});
                    } else {
                        this.invalidCredentialsMessageShow = true;
                    }
                    this.loading = false;
                })
        },
        ...mapActions({
            logIn: currentUserActions.USER_DOLOGIN,
        }),
    },
    mounted: function() {
        loginApi.checkAuth()
            .then(response => {
                if (response)
                    this.$router.push({name: 'Home'})
            })
    }
}
</script>