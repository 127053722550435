
export const getUserWorkDayByCurrentUserDate = {
    query: `
        query($date:Date!)
        {
            userWorkDayByCurrentUserDate(date: $date)
            {
                workDate
                consolidated
                businessTrip
                workDayRecords {
                        dateIndex
                        note
                        freezed
                        recordType
                        clockRecord {
                            punchInTime
                            punchOutTime
                            punchInTimeOffset
                            punchOutTimeOffset
                        }
                        leaveRecord {
                            minuteDuration
                            apply104
                        }
                        holidayRecord {
                            minuteDuration
                            fullDay
                        }
                        sickRecord {
                            medicalCertificationDelivered
                        }
                        availabilityRecord {
                            temp
                        }
                }
            }
        }
    `,
    dataName: 'userWorkDayByCurrentUserDate'

}

export const registerPunchInOut = {
    query: `
        mutation($recordTimeOffset:Short!)
        {
            punchInOut(recordTimeOffset: $recordTimeOffset) 
            {
                dateIndex
                note
                recordType
                freezed
                clockRecord {
                    punchInTime
                    punchOutTime
                }
                leaveRecord {
                    minuteDuration
                    apply104
                }
                holidayRecord {
                    minuteDuration
                    fullDay
                }
                sickRecord {
                    medicalCertificationDelivered
                }
                availabilityRecord {
                    temp
                }
            }
        }
    `,
    dataName: 'punchInOut'

} 

export const undoLastPunchInOut = {
    query: `
        mutation
        {
            undoLastPunchInOut
            {
                dateIndex
                note
                recordType
                freezed
                clockRecord {
                    punchInTime
                    punchOutTime
                    punchInTimeOffset
                    punchOutTimeOffset
                }
                leaveRecord {
                    minuteDuration
                    apply104
                }
                holidayRecord {
                    minuteDuration
                    fullDay
                }
                sickRecord {
                    medicalCertificationDelivered
                }
                availabilityRecord {
                    temp
                }
            }
        }
    `,
    dataName: 'undoLastPunchInOut'

}

export const registerSickRecord = {
    query: `
        mutation($date:Date!)
        {
            createSickRecord(date: $date)
            {
                dateIndex
                note
                recordType
                freezed
                sickRecord {
                    medicalCertificationDelivered
                }
            }
        }
    `,
    dataName: 'createSickRecord'

}

export const insertSickRecord = {
    query: `
        mutation($date:Date!, $username:String!)
        {
            insertSickRecord(date: $date, username: $username)
        }
    `,
    dataName: 'insertSickRecord'
}

export const registerAvailabilityRecord = {
    query: `
        mutation($date:Date!)
        {
            createAvailabilityRecord(date: $date)
            {
                dateIndex
                note
                recordType
                freezed
                sickRecord {
                    medicalCertificationDelivered
                }
                availabilityRecord {
                    temp
                }
            }
        }
    `,
    dataName: "createAvailabilityRecord"
}

export const registerAbsenceRecord = {
    query: `
        mutation($date:Date!, $minuteDuration:Int!, $absenceKind:String!, $apply104:Boolean)
        {
            createAbsenceRecord(date: $date, minuteDuration: $minuteDuration, absenceKind: $absenceKind, apply104: $apply104)
            {
                dateIndex
                note
                recordType
                freezed
                leaveRecord {
                    minuteDuration
                    apply104
                }
                holidayRecord {
                    minuteDuration
                    fullDay
                }
            }
        }
    `,
    dataName: 'createAbsenceRecord'

}

export const editAbsenceRecord = {
    query: `
        mutation($date:Date!, $dateIndex:Short!, $minuteDuration:Int!, $absenceKind:String!, $apply104:Boolean)
        {
            updateAbsenceRecord(date: $date, dateIndex: $dateIndex, minuteDuration: $minuteDuration, absenceKind: $absenceKind, apply104: $apply104)
            {
                dateIndex
                note
                recordType
                freezed
                leaveRecord {
                    minuteDuration
                    apply104
                }
                holidayRecord {
                    minuteDuration
                    fullDay
                }
            }
        }
    `,
    dataName: 'updateAbsenceRecord'

}

export const removeUserWorkDayRecord = {
    query: `
        mutation($username:String!, $date:Date!, $index:Short!)
        {
            deleteUserWorkDayRecord(username: $username, date: $date, index: $index)
        }
    `,
    dataName: 'deleteUserWorkDayRecord'
}

export const removeWorkDayRecord = {
    query: `
        mutation($date:Date!, $index:Short!)
        {
            deleteWorkDayRecord(date: $date, index: $index)
        }
    `,
    dataName: 'deleteWorkDayRecord'
}

export const editWorkDayRecordNote = {
    query: `
        mutation($date:Date!, $index:Short!, $note:String)
        {
            updateWorkDayRecordNote(date: $date, index: $index, note: $note)
            {
                dateIndex
                note
                recordType
                freezed
                clockRecord {
                    punchInTime
                    punchOutTime
                    punchInTimeOffset
                    punchOutTimeOffset
                }
                leaveRecord {
                    minuteDuration
                    apply104
                }
                holidayRecord {
                    minuteDuration
                    fullDay
                }
                sickRecord {
                    medicalCertificationDelivered
                }
                availabilityRecord {
                    temp
                }
            }
        }
    `,
    dataName: 'updateWorkDayRecordNote'
}

export const insertAbsenceRecord = {
    query: `
        mutation($date:Date!, $minuteDuration:Int!, $username:String!, $absenceKind:String!, $apply104:Boolean)
        {
            insertAbsenceRecord(date: $date, minuteDuration: $minuteDuration, username: $username, absenceKind: $absenceKind, apply104: $apply104)
        }
    `,
    dataName: 'insertAbsenceRecord'
}