<template>
    <v-container fluid style="overflow-y: auto;">
        <v-data-table
            :headers="headers"
            :items="companies"
            :search="search"
            disable-pagination
            hide-default-footer
        >
        <template v-slot:top>
        <v-toolbar
            flat
        >
        
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cerca"
            single-line
            hide-details
        ></v-text-field>
        
            <v-divider
            class="mx-4"
            inset
            vertical
            ></v-divider>
            <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="dialogEditCompany = true"
                >
                <v-icon
                    left
                    dark
                >
                    mdi-plus
                </v-icon>
                Crea Azienda
                </v-btn>
            
        </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
        >
            mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
        >
            mdi-delete
        </v-icon>
        </template>
        </v-data-table>
        <ConfirmDialog
            :dialog="dialogDelete" 
            :dialogText="'Eliminare definitivamente l\'azienda ' + this.editedItem.companyName + '?'"
            v-on:c-dialog-confirm="deleteItemConfirm"
            v-on:c-dialog-cancel="closeDelete"
        />
        <CompanyEdit
            :show="dialogEditCompany"
            :editedCompany="editedItem"
            :edit="editingCompany"
            v-on:dialog-close="closeEdit"
            v-on:create-company="createCompany"
            v-on:update-company="updateCompany"
        />
    </v-container>
</template>

<script>
export const ConfirmDialog = () => import('../../../global/ConfirmDialog.vue')
export const CompanyEdit = () => import('../../anagraphics/dialogs/CompanyEdit.vue')

import companyApi from '../../../../api/company'

import { mapState } from 'vuex'

export default {
  data: function() {
      return {
        search: '',
        dialog: false,
        dialogEditCompany: false,
        dialogDelete: false,
        headers: [
            { text: 'Nome Azienda', align: 'start', value: 'companyName' },
            { text: 'Conteggio Utenti', align: 'start', value: 'usersCount' },
            { text: '', value: 'actions', sortable: false, align: 'end' },
        ],
        editedIndex: -1,
        editedItem: {
            companyName: '',
        },
        defaultItem: {
            companyName: '',
        },
      };
  },
  components: {
      ConfirmDialog,
      CompanyEdit
  },
  computed: {
    ...mapState({
        companies: state => state.dataStorage.companies,
    }), 
    editingCompany () {
        return this.editedIndex >= 0;
    }
  },
methods: {
    editItem (item) {
      this.editedIndex = this.companies.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogEditCompany = true
    },

    deleteItem (item) {
      this.editedIndex = this.companies.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      let self = this;
      let editedCompany = { ...self.editedItem };
      companyApi.deleteCompany(editedCompany.companyName).then(response => {
          if(response.success) {
              this.emitFetchCompaniesEvent();
          }
        });
      self.closeDelete()
    },


    closeEdit() {
        this.dialogEditCompany = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
        })
    },

    closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
        })
    },
    emitFetchCompaniesEvent() {
        this.$emit('refresh-companies-data');
    },
    createCompany(company) {
        companyApi.createCompany(company).then(response => {
            if(response.success) {
                this.emitFetchCompaniesEvent();
            }
            this.closeEdit();
        })
    },
    updateCompany(companyName, updatedCompany) {
        companyApi.updateCompany(companyName, updatedCompany).then(response => {
            if(response.success) {
                this.emitFetchCompaniesEvent();
            }
            this.closeEdit();
        })
    },
  },
}
</script>
