import gqlRequestManager from './gqlRequestManager'

import axiosInstance from '../services/axiosInstance'

import { checkAuthEndpoint, loginEndpoint } from './endpoints'
import { authCheck, loginUser, logoutUser } from '../utils/queries/login'

export default {
    checkAuth: function() {
        return axiosInstance.post(
            checkAuthEndpoint,
            {
                query: authCheck.query
            }
        ).then(response => {
            let gQLResponse = response.status;
            return (gQLResponse >= 200 && gQLResponse < 300)
        })
    },
    login: function(username, password) {
        return gqlRequestManager(
            loginEndpoint, 
            {
                query: loginUser.query,
                variables: {
                    username: username, 
                    password: password
                }
            },
            loginUser.dataName
        );
    },
    logout: function() {
        return gqlRequestManager(
            loginEndpoint, 
            {
                query: logoutUser.query
            },
            logoutUser.dataName
        );
    }
}

