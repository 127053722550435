// export const getWorkingHoursReport = {
//     query: `
//         query($companyName:String!, $month:Short!, $year:Short!)
//         {
//             workingHoursReport(companyName: $companyName, month: $month, year: $year)
//         }
//     `,
//     dataName: 'workingHoursReport'
// }

export const generateWorkingHoursReport = {
    query: `
        mutation($companyName:String!, $month:Short!, $year:Short!)
        {
            generateWorkingHoursReport(companyName: $companyName, month: $month, year: $year)
        }
    `,
    dataName: 'generateWorkingHoursReport'
}

export const exportWorkingHoursReport = {
    query: `
        query($companyName:String!, $month:Short!, $year:Short!)
        {
            exportWorkingHoursReport(companyName: $companyName, month: $month, year: $year)
        }
    `,
    dataName: 'exportWorkingHoursReport'
}

export const userMonthExport = {
    query: `
        query($companyName:String!, $month:Short!, $year:Short!)
        {
            userMonthExport(companyName: $companyName, month: $month, year: $year) {
                firstName
                lastName
                workingHoursDaysData
                {
                    workDate
                    requiredTimeValue
                    punchedTimeValue
                    leaveTimeValue
                    holidayTimeValue
                    overtimeTimeValue
                    sickDayValue
                    availabilityDayValue
                    businessTripDayValue
                }
            }
        }
    `,
    dataName: 'userMonthExport'
}