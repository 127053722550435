<template>
    <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="orange darken-3">
                <v-container fluid>
                    <v-row class="d-flex flex-row align-center">
                        <v-col class="d-flex justify-start">
                            <v-btn outlined class="mr-4" @click="setToday">
                                Oggi
                            </v-btn>
                            <v-btn outlined class="mr-4" @click="showWorkingHorsPreview">
                                Anteprima Esportazione Orari
                            </v-btn>
                        </v-col>
                        <v-col class="d-flex flex-row align-center justify-center">
                            <v-btn fab text small @click="focusMonth = focusMonth.minus({ months: 1 })">
                                <v-icon small>
                                    mdi-chevron-left
                                </v-icon>
                            </v-btn>
                            <v-toolbar-title>
                                {{ calendarTitle }}
                            </v-toolbar-title>
                            <v-btn fab text small @click="focusMonth = focusMonth.plus({ months: 1 })">
                                <v-icon small>
                                    mdi-chevron-right
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="d-flex justify-end pr-0">
                            <v-btn right icon dark @click="closeMethod">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
                <!-- <v-toolbar-title>Calendario</v-toolbar-title> -->

                <!-- <v-spacer />
               
                <v-spacer></v-spacer>
                 -->
            </v-toolbar>
            <v-card-text>
                <v-card class="mx-4 mt-8" elevation="0">
                    <v-container fluid>
                        <v-row no-gutters>
                            <v-col v-for="index in 7" :key="index">
                                <v-card width="100%" min-height="50" outlined rounded="0"
                                    class="d-flex justify-center align-center text-h6" color="orange lighten-3">
                                    {{ ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'][index -
                                        1] }}
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-for="i in monthKeys.length / 7" :key="i" no-gutters>
                            <v-col v-for="j in 7" :key="j">
                                <v-hover v-slot="{ hover }">
                                    <v-card width="100%" min-height="120" rounded="0" elevation="0"
                                        v-ripple="{ center: true }" class="d-flex flex-column justify-center align-center"
                                        @click="viewDay(monthKeys[(i - 1) * 7 + j - 1])"
                                        :color="monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].currentMonth ? (hover ? 'grey lighten-3' : 'white') : (hover ? 'grey lighten-1' : 'grey lighten-2')">
                                        <div style="min-height: 20px;" />
                                        <v-tooltip bottom
                                            :disabled="(!(monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].past && monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].currentMonth)) || monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].uncoveredTime == null">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="d-flex justify-center align-end text-h4 py-2 font-weight-medium"
                                                    :class="monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].color" v-on="on"
                                                    v-bind="attrs">
                                                    {{ monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].displayString }}
                                                </div>
                                            </template>
                                            <div v-if="monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].hasVariationProposal">
                                                Proposta di Modifica in attesa di approvazione
                                            </div>
                                            <span
                                                v-if="monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].uncoveredTime && monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].uncoveredTime > 0">
                                                La copertura dell'orario giornaliero non è completa (scoperto:
                                                {{ formattedDuration(monthDataDict[monthKeys[(i - 1) * 7 + j -
                                                    1]].uncoveredTime) }})
                                            </span>
                                            <span v-else>
                                                L'orario giornaliero è coperto correttamente
                                            </span>
                                        </v-tooltip>
                                        <div class="d-flex justify-center align-center flex-row" style="min-height: 20px;">
                                            <v-sheet
                                                v-for="(event, i) in monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].events"
                                                :key="i" rounded="pill" :color="event.color"
                                                class="mx-1 d-flex justify-center align-center" height="20" width="20">
                                                <v-tooltip bottom v-if="event.kind == 'trip'">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon x-small color="black" class="ma-1" v-bind="attrs" v-on="on">
                                                            mdi-bag-suitcase
                                                        </v-icon>
                                                    </template>
                                                    <span>Trasferta</span>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="event.kind == 'overtime'">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon x-small color="black" class="ma-1" v-bind="attrs" v-on="on">
                                                            mdi-alarm-plus
                                                        </v-icon>
                                                    </template>
                                                    <span>Straordinari: {{ formattedDuration(event.minutes) }}</span>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="event.kind == 'sick'">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon x-small color="black" class="ma-1" v-bind="attrs" v-on="on">
                                                            mdi-emoticon-sick
                                                        </v-icon>
                                                    </template>
                                                    <span>Malattia</span>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="event.kind == 'availability'">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon x-small color="black" class="ma-1" v-bind="attrs" v-on="on">
                                                            mdi-face-agent
                                                        </v-icon>
                                                    </template>
                                                    <span>Reperibilità</span>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="event.kind == 'absence'">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon x-small color="black" class="ma-1" v-bind="attrs" v-on="on">
                                                            {{ event.holiday_minutes > 0 ? 'mdi-umbrella-beach-outline' :
                                                                'mdi-exit-run' }}
                                                        </v-icon>
                                                    </template>
                                                    <div v-if="event.holiday_minutes > 0">Ferie:
                                                        {{ formattedDuration(event.holiday_minutes) }}</div>
                                                    <div v-if="event.leave_minutes > 0">Permesso:
                                                        {{ formattedDuration(event.leave_minutes) }}</div>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="event.kind == 'clock'">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon x-small color="black" class="ma-1" v-bind="attrs" v-on="on">
                                                            mdi-stamper
                                                        </v-icon>
                                                    </template>
                                                    <span>Orario registrato: {{ formattedDuration(event.minutes) }}</span>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="event.kind == 'clock_error'">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon x-small color="white" class="ma-1" v-bind="attrs" v-on="on">
                                                            mdi-stamper
                                                        </v-icon>
                                                    </template>
                                                    <span>Orario di chiusura mancante</span>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="event.kind == 'notes'" max-width="600">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon x-small color="white" class="ma-1" v-bind="attrs" v-on="on">
                                                            mdi-text
                                                        </v-icon>
                                                    </template>
                                                    <span class="text-wrap">Note: {{ event.notes }}</span>
                                                </v-tooltip>
                                            </v-sheet>
                                        </div>



                                        <!-- <v-tooltip bottom :disabled="!(monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].past && monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].currentMonth)">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        rounded
                                                        elevation="0"
                                                        width="110"
                                                        :dark="monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].isToday"
                                                        :color="monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].color"
                                                        :disabled="!monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].currentMonth"
                                                        class="d-flex justify-space-around"
                                                        v-on="on"
                                                        v-bind="attrs"
                                                        @click="viewDay(monthKeys[(i - 1) * 7 + j - 1])"
                                                    >
                                                        <v-icon
                                                            v-if="monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].past && monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].currentMonth"
                                                            
                                                            left
                                                            :color="monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].uncoveredTime > 0 ? 'red lighten-2' : 'green lighten-2'"
                                                        >
                                                            {{monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].uncoveredTime > 0 ? 'mdi-alert-circle' : 'mdi-check-circle'}}
                                                        </v-icon>
                                                        <span>{{monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].displayString}}</span>
                                                        <span 
                                                            v-if="monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].past && monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].currentMonth"
                                                            class="px-2"
                                                        />
                                                    </v-btn>
                                                </template>
                                                <span 
                                                    v-if="monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].uncoveredTime > 0"
                                                >
                                                    La copertura dell'orario giornaliero non è completa (scoperto: {{formattedDuration(monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].uncoveredTime)}})
                                                </span>
                                                <span 
                                                    v-else
                                                >
                                                    L'orario giornaliero è coperto correttamente
                                                </span>
                                            </v-tooltip> -->


                                        <!-- <v-row no-gutters class="d-flex justify-center align-start pa-4">
                                            <v-sheet
                                                v-for="(event, i) in monthDataDict[monthKeys[(i - 1) * 7 + j - 1]].events"
                                                :key="i"
                                                rounded="pill"
                                                :color="event.color"
                                                class="mx-1"
                                                >
                                                <v-tooltip bottom v-if="event.kind == 'trip'">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon 
                                                            small 
                                                            color="black" 
                                                            class="ma-1"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            mdi-bag-suitcase
                                                        </v-icon>
                                                    </template>
                                                    <span>Trasferta</span>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="event.kind == 'overtime'">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon 
                                                            small 
                                                            color="black" 
                                                            class="ma-1"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            mdi-alarm-plus
                                                        </v-icon>
                                                    </template>
                                                    <span>Straordinari: {{formattedDuration(event.minutes)}}</span>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="event.kind == 'sick'" >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon 
                                                            small 
                                                            color="black" 
                                                            
                                                            class="ma-1"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            mdi-emoticon-sick
                                                        </v-icon>
                                                    </template>
                                                    <span>Malattia</span>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="event.kind == 'absence'" >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon 
                                                            small 
                                                            color="black" 
                                                            class="ma-1"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            mdi-umbrella-beach-outline
                                                        </v-icon>
                                                    </template>
                                                    <div v-if="event.holiday_minutes > 0">Ferie: {{formattedDuration(event.holiday_minutes)}}</div>
                                                    <div v-if="event.leave_minutes > 0">Permesso: {{formattedDuration(event.leave_minutes)}}</div>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="event.kind == 'clock'" >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon 
                                                            small 
                                                            color="black" 
                                                            class="ma-1"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            mdi-stamper
                                                        </v-icon>
                                                    </template>
                                                    <span>Orario registrato: {{formattedDuration(event.minutes)}}</span>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="event.kind == 'clock_error'" >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon 
                                                            small 
                                                            color="white" 
                                                            class="ma-1"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            mdi-stamper
                                                        </v-icon>
                                                    </template>
                                                    <span>Orario di chiusura mancante</span>
                                                </v-tooltip>
                                            </v-sheet>
                                        </v-row> -->

                                    </v-card>
                                </v-hover>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-card-text>
            <!-- <v-card-actions class="d-flex align-end justify-end">
                <v-btn fab/>
            </v-card-actions> -->
            <v-spacer />
            <!-- <v-speed-dial
      bottom
      right
      direction="top"
      :open-on-hover="false"
      transition="slide-y-reverse-transition"
      absolute
      fixed
      v-model="addButtonDial"
    >
      <template v-slot:activator >
        <v-btn
          color="orange darken-3"
          fab
          x-large
        >
          <v-icon v-if="addButtonDial">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
      >
        <v-icon>mdi-umbrella-beach-outline</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
      >
        <v-icon>mdi-emoticon-sick</v-icon>
      </v-btn>

    </v-speed-dial> -->
        </v-card>
        <MonthWorkingHoursPreviewDialog :show="showMonthWorkingHoursPreview" :userWorkingHoursData="userWorkingHoursData"
            :monthPreviewLoading="monthPreviewLoading" :selectedMonth="focusMonth"
            v-on:close-month-working-hours-preview-dialog="showMonthWorkingHoursPreview = monthPreviewLoading = false" />
    </v-dialog>
</template>
<script>
export const MonthWorkingHoursPreviewDialog = () => import('./dialogs/MonthWorkingHoursPreview.vue')
import workTimesService from '../../services/mixins/workTimesMixin'

import reportApi from '../../api/report'

import { DateTime } from 'luxon'
import { mapGetters, mapState } from 'vuex'

import * as dataStoragesActions from '../../store/actions/dataStorage'

export default {
    data: function () {
        return {
            currentMonth: null,
            addButtonDial: false,
            showMonthWorkingHoursPreview: false,
            userWorkingHoursData: {},
            monthPreviewLoading: false,
        }
    },
    mixins: [workTimesService],
    props: {
        show: {
            type: Boolean,
            required: true
        },
        currentDate: {
            type: Date,
            required: true
        },
        // calendarUserWorkDays: {
        //     type: Array,
        //     required: true
        // },
        monthWorkDaysStatus: {
            type: String,
            required: true
        }
    },
    components: {
        MonthWorkingHoursPreviewDialog
    },
    methods: {
        closeMethod: function () {
            this.focusMonth = DateTime.fromJSDate(this.currentDate).startOf('month');
            this.$emit('close-calendar-dialog');
        },
        formattedDuration: function (minutes) {
            let otm = minutes;
            return (otm >= 60 ? Math.floor(otm / 60) + ' ore ' : '') + (otm % 60 != 0 ? otm % 60 + ' minuti' : '').trim();
        },
        setToday: function () {
            this.focusMonth = null;
            this.$emit('change-records-date', new Date());
            this.$emit('close-calendar-dialog');
        },
        viewDay(isoDate) {
            this.focusMonth = DateTime.fromISO(isoDate).startOf('month');
            this.$emit('change-records-date', DateTime.fromISO(isoDate).toJSDate());
            this.$emit('close-calendar-dialog');
        },
        emitMonthStatus: function () {
            this.$emit('update:monthWorkDaysStatus', this.monthStatus);
        },
        showWorkingHorsPreview: function () {
            this.monthPreviewLoading = true;
            this.showMonthWorkingHoursPreview = true;
            reportApi.userMonthExport(this.userCompany.companyName, this.focusMonth.month, this.focusMonth.year).then(response => {
                if (response.success) {
                    this.userWorkingHoursData = response.data;
                } else {
                    console.log("error calculating report")
                }
                this.monthPreviewLoading = false;
            });

        }
    },
    watch: {
        calendarUserWorkDays: {
            handler(val, oldVal) {
                if (val != oldVal)
                    this.emitMonthStatus();
            },
            deep: true
        }
    },
    mounted: function () {
        this.emitMonthStatus();
    },
    computed: {
        ...mapGetters({
            isHolidayCheck: dataStoragesActions.DATASTORAGE_ISHOLIDAYCHECK,
        }),
        ...mapState({
            calendarUserWorkDays: state => state.dataStorage.calendarUserWorkDays,
            userCompany: state => state.currentUser.company,
        }),
        todayDateObject: function () {
            let today = DateTime.now();
            return {
                day: today.day,
                month: today.month,
                year: today.year
            }
        },
        isCurrentMonth: function () {
            return this.todayDateObject.month == this.focusMonth.month && this.todayDateObject.year == this.focusMonth.year;
        },
        monthStatus: function () {
            let status = "ok";
            if (this.isCurrentMonth) {
                let start = this.focusMonth.startOf('month');
                let end = DateTime.fromObject(this.todayDateObject).plus({ days: -1 });
                let counter = 0;
                while (start <= end && counter <= 31) {
                    if (this.dayUncoveredTime(start.toJSDate()) || 0 > 0) {
                        status = "uncovered_times";
                        break;
                    }
                    start = start.plus({ days: 1 });
                    counter += 1;
                }
            }
            if (status != this.monthWorkDaysStatus) {
                this.$emit('update:monthWorkDaysStatus', status);
            }
            return status;
        },
        focusMonth: {
            get: function () {
                return this.currentMonth || this.wrappedCurrentDate.startOf('month');
            },
            set: function (val) {
                if (val && this.focusMonth != val.startOf('month'))
                    this.$emit('refresh-calendar-user-work-days', val.month - 1, val.year)
                this.currentMonth = val ? val.startOf('month') : null;
            }
        },
        monthKeys: function () {
            let list = [];
            let counter = this.wrappedFocusMonthStartDate;

            for (let i = 0; i < this.wrappedFocusMonthStartDate.weekday - 1; i++) {
                list.push(counter.minus({ days: this.wrappedFocusMonthStartDate.weekday - i - 1 }).toISODate());
            }
            while (counter <= this.wrappedFocusMonthStopDate) {
                list.push(counter.toISODate())
                counter = counter.plus({ days: 1 });
            }

            for (let i = this.wrappedFocusMonthStopDate.weekday; i < 7; i++) {
                list.push(this.wrappedFocusMonthStopDate.plus({ days: i - this.wrappedFocusMonthStopDate.weekday + 1 }).toISODate())
            }
            return list;
        },
        monthDataDict: function () {
            let dict = {};
            let today = DateTime.local().startOf('day');

            this.monthKeys.forEach((dateKey, index) => {
                let date = DateTime.fromISO(dateKey);
                let isToday = dateKey == today.toISODate();
                let past = date < today;
                let currentMonth = date.month == this.focusMonth.month && date.year == this.focusMonth.year;
                let firstOfMonth = date.month != date.minus({ days: 1 }).month;
                let uncoveredTime = this.calendarUncoveredTimes[dateKey];
                let color = 'grey--text text--darken-1';

                let hasVariationProposal = (this.calendarUserWorkDaysAsEvents[dateKey] || {}).hasVariationProposal;

                if (past && currentMonth) {
                    if (hasVariationProposal)
                        color = 'orange--text text--lighten-1';
                    else if (uncoveredTime && uncoveredTime > 0)
                        color = 'red--text text--lighten-3';
                    else if (uncoveredTime != null)
                        color = 'light-green--text text--lighten-1';
                }
                if (!currentMonth)
                    color = 'grey--text text--lighten-5'
                if (isToday)
                    color = 'grey--text text--darken-3'

                dict[dateKey] = {
                    currentMonth: currentMonth,
                    firstOfMonth: firstOfMonth,
                    past: past,
                    isToday: isToday,
                    displayString: date.day, //firstOfMonth || index == 0 ? `${date.day} ${date.monthShort}` : `${date.day}`,
                    color: color,
                    events: (this.calendarUserWorkDaysAsEvents[dateKey] || {}).dayEvents || [],
                    uncoveredTime: uncoveredTime,
                    hasVariationProposal: hasVariationProposal
                }
            })



            return dict;
        },
        wrappedCurrentDate: function () {
            return DateTime.fromJSDate(this.currentDate);
            //return DateTime.fromObject({day: 1, month: 12, year: 2021})
        },
        wrappedFocusMonthStartDate: function () {
            return this.focusMonth;
        },
        wrappedFocusMonthStopDate: function () {
            return this.focusMonth.endOf('month');
        },
        calendarTitle: function () {
            return this.focusMonth.setLocale('it-IT').toFormat('LLLL y');
        },
        calendarUserWorkDaysAsEvents: function () {
            let calendarUWDEvents = {};
            this.calendarUserWorkDays.forEach(uwd => {
                let dayEvents = [];
                if (this.calendarWorkTimes[uwd.workDate]) {
                    if (this.calendarWorkTimes[uwd.workDate] < 0) {
                        dayEvents.push({
                            color: "deep-orange lighten-1",
                            kind: "clock_error",
                        })
                    } else {
                        dayEvents.push({
                            color: "grey lighten-1",
                            minutes: this.calendarWorkTimes[uwd.workDate],
                            kind: "clock",
                        })
                    }
                }
                if (this.calendarAvailabilityDays[uwd.workDate])
                    dayEvents.push({
                        color: "deep-orange lighten-3",
                        kind: "availability",
                    })
                if (this.calendarSickDays[uwd.workDate])
                    dayEvents.push({
                        color: "pink lighten-3",
                        kind: "sick",
                    })
                if (this.calendarHolidayTimes[uwd.workDate] || this.calendarLeaveTimes[uwd.workDate])
                    dayEvents.push({
                        color: (this.calendarHolidayTimes[uwd.workDate] || 0) > 0 ? "cyan accent-3" : "teal accent-3",
                        holiday_minutes: this.calendarHolidayTimes[uwd.workDate] || 0,
                        leave_minutes: this.calendarLeaveTimes[uwd.workDate] || 0,
                        kind: "absence",
                    })
                if (uwd.businessTrip)
                    dayEvents.push({
                        color: "amber accent-4",
                        kind: "trip",
                    })
                if (uwd.overtimeMinutes > 0)
                    dayEvents.push({
                        color: "purple lighten-3",
                        minutes: uwd.overtimeMinutes,
                        kind: "overtime",
                    })
                if (uwd.notes)
                    dayEvents.push({
                        color: "grey",
                        notes: uwd.notes,
                        kind: "notes",
                    })


                calendarUWDEvents[DateTime.fromISO(uwd.workDate).toISODate()] = { dayEvents: dayEvents, hasVariationProposal: !!((uwd.variationProposal || {}).creationTime) };
                // uwd.workDayRecords
            })
            return calendarUWDEvents;
        },
        calendarWorkTimes: function () {
            let calendarWorkTimes = {};
            this.calendarUserWorkDays.forEach(uwd => {
                let records = uwd.workDayRecords;
                records.forEach(record => {
                    if (record.recordType == 'CLOCK_RECORD') {
                        if (calendarWorkTimes[uwd.workDate] != -1) {
                            if (record.clockRecord.punchInTime && record.clockRecord.punchOutTime) {
                                let record_punch_out = Math.floor(DateTime.fromISO(record.clockRecord.punchOutTime).toSeconds() / 60);
                                let record_punch_in = Math.floor(DateTime.fromISO(record.clockRecord.punchInTime).toSeconds() / 60);
                                let record_minutes = record_punch_out - record_punch_in; //record_punch_out.diff(record_punch_in, ["minutes"]).minutes;
                                if (!calendarWorkTimes[uwd.workDate]) {
                                    calendarWorkTimes[uwd.workDate] = record_minutes;
                                } else {
                                    calendarWorkTimes[uwd.workDate] += record_minutes;
                                }
                            } else {
                                calendarWorkTimes[uwd.workDate] = -1; //error check
                            }
                        }
                    }
                })
            })
            return calendarWorkTimes;
        },
        calendarLeaveTimes: function () {
            let calendarLeaveTimes = {};
            this.calendarUserWorkDays.forEach(uwd => {
                let records = uwd.workDayRecords;
                records.forEach(record => {
                    if (record.recordType == 'LEAVE_RECORD') {
                        if (!calendarLeaveTimes[uwd.workDate]) {
                            calendarLeaveTimes[uwd.workDate] = record.leaveRecord.minuteDuration;
                        } else {
                            calendarLeaveTimes[uwd.workDate] += record.leaveRecord.minuteDuration;
                        }
                    }
                })
            })
            return calendarLeaveTimes;
        },
        calendarHolidayTimes: function () {
            let calendarHolidayTimes = {};
            this.calendarUserWorkDays.forEach(uwd => {
                let records = uwd.workDayRecords;
                records.forEach(record => {
                    if (record.recordType == 'HOLIDAY_RECORD') {
                        if (!calendarHolidayTimes[uwd.workDate]) {
                            calendarHolidayTimes[uwd.workDate] = record.holidayRecord.minuteDuration;
                        } else {
                            calendarHolidayTimes[uwd.workDate] += record.holidayRecord.minuteDuration;
                        }
                    }
                })
            })
            return calendarHolidayTimes;
        },
        calendarSickDays: function () {
            let calendarSickDays = {};
            this.calendarUserWorkDays.forEach(uwd => {
                let records = uwd.workDayRecords;
                records.forEach(record => {
                    if (record.recordType == 'SICK_RECORD') {
                        calendarSickDays[uwd.workDate] = true;
                    }
                })
            })
            return calendarSickDays;
        },
        calendarAvailabilityDays: function () {
            let calendarAvailabilityDays = {};
            this.calendarUserWorkDays.forEach(uwd => {
                let records = uwd.workDayRecords;
                records.forEach(record => {
                    if (record.recordType == 'AVAILABILITY_RECORD') {
                        calendarAvailabilityDays[uwd.workDate] = true;
                    }
                })
            })
            return calendarAvailabilityDays;
        },
        calendarUncoveredTimes: function () {
            let monthDict = {};
            let start = this.focusMonth;
            let end = this.focusMonth.endOf('month');
            let counter = 0;
            while (start <= end && counter <= 31) {
                monthDict[start.toISODate()] = this.dayUncoveredTime(start.toJSDate());
                start = start.plus({ days: 1 });
                counter += 1;
            }
            return monthDict;
        },
    }
}
</script>