<template>
    <v-container fluid>
        <v-row>
            <v-col class="d-flex justify-start align-center pa-4">
                <v-btn
                   outlined
                   color="green"
                   :disabled="loadingData"
                   @click="exportTables()"
                >
                    <v-icon left>
                        mdi-file-table
                    </v-icon>
                    Esporta
                </v-btn>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-4">
                <v-icon
                    @click="addMonthsToDate(-1)"
                    :disabled="loadingData"
                >
                    mdi-chevron-left
                </v-icon>

                <v-menu
                    ref="monthPicker"
                    v-model="monthPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    bottom
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            outlined
                            text
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="loadingData"
                        >
                            {{selectedMonth.setLocale("it").toFormat("LLLL y")}}
                        </v-btn>

                    </template>
                    <v-date-picker
                        type="month"
                        v-model="isoSelectedMonth"
                        locale="it"
                        no-title
                        @input="monthPicker = false"
                    ></v-date-picker>
                </v-menu>
                
                <v-icon
                    @click="addMonthsToDate(1)"
                    :disabled="loadingData"
                >
                    mdi-chevron-right
                </v-icon>
            </v-col>
            <v-col class="d-flex justify-end align-center">
                <span 
                    v-if="editing"
                    :class="{'pink--text text--darken-1': sickRecordsEditing, 'blue--text text--darken-1': holidayEditing}"
                    class="mr-4 font-weight-bold"
                >
                    {{sickRecordsEditing ? 'MODIFICA MALATTIE ATTIVA' : 'MODIFICA FERIE ATTIVA'}}
                </span>
                <v-switch
                    v-model="holidayEditing"
                    :color="holidayEditing ? 'blue darken-1' : 'grey lighten-1'"
                    inset
                    prepend-icon="mdi-umbrella-beach-outline"
                    :disabled="loadingData"
                ></v-switch>
                <v-switch
                    v-model="sickRecordsEditing"
                    :color="sickRecordsEditing ? 'pink darken-1' : 'grey lighten-1'"
                    inset
                    prepend-icon="mdi-emoticon-sick-outline"
                    :disabled="loadingData"
                ></v-switch>
            </v-col>
        </v-row>
        
        <v-container fluid v-if="!loadingData">
            <div v-for="department in departmentsData" :key="department.departmentName" style="" class="scroller">
                <v-row class="my-6 d-flex align-center" align="start" no-gutters>
                    <v-col cols="1" style="width: 60px; writing-mode: vertical-rl; text-orientation: mixed;" class="d-flex align-center justify-center font-weight-black text-h6">
                        {{department.departmentName}}
                    </v-col>
                    <v-col cols="11">
                        <v-row no-gutters>
                            <v-col cols="2" class="justify-end" style="min-width: 190px;">
                            </v-col>
                            <v-col cols="10" class="d-flex flex-row flex-nowrap">
                                <div 
                                    v-for="n in selectedMonth.endOf('month').get('day')" 
                                    :key="n" style="width: 40px; height: 60px; min-width: 30px;" 
                                    class="d-flex flex-column justify-center align-center bordered"
                                    :style="{'background-color': isHolidayCheck(getDateOfMonth(n).toJSDate()) ? '#FFD54F' : '#FFFFFF'}"
                                >
                                    <div>
                                        {{getDateOfMonth(n).setLocale("it").toFormat("ccccc")}}
                                    </div>
                                    <div>
                                        {{n}}
                                    </div>
                                    
                                </div>
                            </v-col>
                        </v-row>
                        <v-row v-for="user in department.users" :key="user.username" no-gutters>
                            <v-col cols="2" class="d-flex justify-end align-center pr-4 bordered" style="min-width: 190px;">
                                {{user.firstName + ' ' + user.lastName}}
                            </v-col>
                            <v-col cols="10" class="d-flex flex-row flex-nowrap">
                                <div 
                                    v-for="n in selectedMonth.endOf('month').get('day')" 
                                    :key="n" 
                                    :style="{'width': '40px', 'height': '40px', 'min-width': '30px', 'background-color': isHolidayCheck(getDateOfMonth(n).toJSDate()) ? '#FFD54F' : user.dayStatuses[n] ? (user.dayStatuses[n].type == 'holiday' ? (sickRecordsEditing ? '#FFFFFF' : '#64B5F6') : user.dayStatuses[n].type == 'sick' ? (holidayEditing ? '#FFFFFF' : '#F06292') : '#FFFFFF') : '#FFFFFF'}" 
                                    class="d-flex justify-center align-center bordered" 
                                >
                                    <v-icon
                                        v-if="!editing && user.dayStatuses[n] && user.dayStatuses[n].type == 'holiday'"
                                    >
                                        mdi-umbrella-beach-outline
                                    </v-icon>
                                    <v-icon
                                        v-if="!editing && user.dayStatuses[n] && user.dayStatuses[n].type == 'sick'"
                                    >
                                        mdi-emoticon-sick-outline
                                    </v-icon>
                                    <v-hover v-slot="{ hover }" v-if="holidayEditing && user.dayStatuses[n] && user.dayStatuses[n].type != 'disabled' && user.dayStatuses[n].type != 'sick'">
                                        <v-icon
                                            :color="user.dayStatuses[n].freezed ? 'white' : user.dayStatuses[n].type == 'free' ? 'blue darken-4' : 'white'"
                                            @click="user.dayStatuses[n].freezed ? () => {} : user.dayStatuses[n].type == 'free' ? insertHoliday(user, n) : removeHoliday(user.username, user.dayStatuses[n].holidayRecordIndex, n)"
                                        >
                                            {{user.dayStatuses[n].freezed ? 'mdi-snowflake' : user.dayStatuses[n].type != 'free' ? (hover ? 'mdi-cancel' : 'mdi-umbrella-beach-outline') : 'mdi-plus'}}
                                        </v-icon>
                                    </v-hover>
                                    <v-hover v-slot="{ hover }" v-if="sickRecordsEditing && user.dayStatuses[n] && user.dayStatuses[n].type != 'disabled' && user.dayStatuses[n].type != 'holiday'">
                                        <v-icon
                                            :color="user.dayStatuses[n].freezed ? 'white' : user.dayStatuses[n].type == 'free' ? 'pink darken-4' : 'white'"
                                            @click="user.dayStatuses[n].freezed ? () => {} : user.dayStatuses[n].type == 'free' ? insertSickRecord(user.username, n) : removeSickRecord(user.username, user.dayStatuses[n].sickRecordIndex, n)"
                                        >
                                            {{user.dayStatuses[n].freezed ? 'mdi-snowflake' : user.dayStatuses[n].type != 'free' ? (hover ? 'mdi-cancel' : 'mdi-emoticon-sick-outline') : 'mdi-plus'}}
                                        </v-icon>
                                    </v-hover>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider />
            </div>
        </v-container>
        <v-container v-else class="d-flex align-center justify-center fill-height">
            
            <!-- <v-progress-linear :value="loadingProgress"></v-progress-linear> -->
             <v-progress-linear indeterminate></v-progress-linear>
            
        </v-container>
        <ConfirmDialog
            :dialog="confirmDialog" 
            :dialogText="confirmDialogText"
            v-on:c-dialog-confirm="confirmDialogAction"
            v-on:c-dialog-cancel="confirmDialog = false"
        />

    </v-container>
        
</template>
<script>
export const ConfirmDialog = () => import('../global/ConfirmDialog.vue')

import { mapState, mapGetters, mapActions } from 'vuex'
import userApi from '../../api/user'
import departmentApi from '../../api/department'
import userWorkDayApi from '../../api/userWorkDay'
import workDayRecordApi from '../../api/workDayRecord'
import * as dataStorageActions from '../../store/actions/dataStorage'
import * as snackbarActions from '../../store/actions/snackbar'
import { DateTime } from 'luxon'
import user from '../../api/user'

const ExcelJS = require('exceljs');
import { saveAs } from 'file-saver'

export default {
    data: function() {
        return {
            monthPicker: false,
            selectedMonth: DateTime.now().startOf("month"),
            departmentsData: [],
            loadingUwdData: [],
            totalUsers: 0,
            holidayEditing: false,
            sickRecordsEditing: false,
            userWorkDaysData: {},
            confirmDialog: false,
            confirmDialogText: '',
            confirmDialogAction: () => {},
        }
    },
    mixins: [
        
    ],
    components: {
        ConfirmDialog
    },
    methods: {
        ...mapActions({
            saveUsers: dataStorageActions.DATASTORAGE_SETUSERS,
            showSnackbar: snackbarActions.SNACKBAR_SHOW
        }),
        exportTables: async function() {
            const workbook = new ExcelJS.Workbook();
            this.departmentsData.forEach(dd => {
                const sheet = workbook.addWorksheet(dd.departmentName);

                dd.users.forEach((userData, userIndex) => {
                    sheet.getRow(2 + userIndex).getCell(1).value = userData.lastName + ' ' + userData.firstName;
                });

                let cDate = this.selectedMonth.setLocale('it').startOf('month');
                let endDate = this.selectedMonth.endOf('month');

                sheet.getColumn(1).width = 20;
                

                while(cDate <= endDate) {
                    sheet.getRow(1).getCell(1 + cDate.get("day")).value = new Date(cDate.toISODate());
                    if(cDate.weekday == 7) {
                        sheet.getRow(1).getCell(1 + cDate.get("day")).font = {color: {argb: "d44124"}};
                    }

                    dd.users.forEach((userData, userIndex) => {
                        console.log(userData)
                        let dayStatus = userData.dayStatuses[cDate.get("day")];
                        let dayContent = dayStatus.type;
                        let cell = sheet.getRow(2 + userIndex).getCell(1 + cDate.get("day"));

                        if(dayContent == 'holiday') {
                            cell.value = "FERIE";
                            cell.fill = {
                                type: 'pattern',
                                pattern:'solid',
                                fgColor:{argb: '4287f5'}
                            };
                        }
                        if(dayContent == 'sick') {
                            cell.value = "MALATTIA";
                            cell.fill = {
                                type: 'pattern',
                                pattern:'solid',
                                fgColor:{argb: 'F06292'}
                            };
                        }
                        
                    });
                    sheet.getColumn(1 + cDate.get("day")).width = 13;
                    if(this.isHolidayCheck(cDate.toJSDate())) {
                        sheet.getColumn(1 + cDate.get("day")).eachCell(cell => cell.fill = {
                            type: 'pattern',
                            pattern:'solid',
                            fgColor:{argb:'ffd54f'}
                        });
                    }
                    cDate = cDate.plus({days: 1});
                }

                // sheet.columns.forEach(function (column, i) {
                //     var maxLength = 0;
                //     column["eachCell"]({ includeEmpty: true }, function (cell) {
                //         var columnLength = cell.value ? cell.value.toString().length : 10;
                //         if (columnLength > maxLength ) {
                //             maxLength = columnLength;
                //         }
                //     });
                //     column.width = maxLength < 10 ? 10 : maxLength;
                // });
                
            });
        
            const buf = await workbook.xlsx.writeBuffer()

            saveAs(new Blob([buf]), "Ferie_" + this.selectedMonth.setLocale("it").toFormat("LLLL_y") + ".xlsx")

            //workbook.xlsx.write("Ferie_" + this.selectedMonth.setLocale("it").toFormat("LLLL_y") + ".xlsx");
        },
        getDateOfMonth: function(day) {
            return DateTime.fromObject({day: day, month: this.selectedMonth.get("month"), year: this.selectedMonth.get("year")});
        },
        insertHoliday: function(user, day) {
            let username = user.username;
            let date = this.getDateOfMonth(day);
            let schedules = user.userWorkSchedules;
            let selectedSchedule = schedules.find(schedule => {
                let startDate = DateTime.fromISO(schedule.validityStartDate);
                let stopDate = schedule.validityStopDate ? DateTime.fromISO(schedule.validityStopDate) : null;

                return startDate.startOf('day') <= date && (stopDate == null || stopDate.endOf('day') >= date);
            })
            if(selectedSchedule) {
                let dayKey = ["mondayWorkTimes", "tuesdayWorkTimes", "wednesdayWorkTimes", "thursdayWorkTimes", "fridayWorkTimes", "saturdayWorkTimes", "sundayWorkTimes"][date.weekday - 1];
                let dayTimes = selectedSchedule.scheduledWeekWorkTimes[dayKey];
                let timeMinutes = 0;
                if(dayTimes.morningTimes && dayTimes.morningTimes.clockInTime && dayTimes.morningTimes.clockOutTime) {
                    timeMinutes +=  DateTime.fromISO(dayTimes.morningTimes.clockOutTime).diff(DateTime.fromISO(dayTimes.morningTimes.clockInTime), ['minutes']).minutes;
                }
                if(dayTimes.afternoonTimes && dayTimes.afternoonTimes.clockInTime && dayTimes.afternoonTimes.clockOutTime) {
                    timeMinutes +=  DateTime.fromISO(dayTimes.afternoonTimes.clockOutTime).diff(DateTime.fromISO(dayTimes.afternoonTimes.clockInTime), ['minutes']).minutes;
                }

                workDayRecordApi.insertAbsenceRecord(date.toISODate(), username, timeMinutes, 'holiday').then(response => {
                    if(response.success) {
                        let departmentIndex = this.departmentsData.findIndex(d => d.users.map(u => u.username).includes(username));
                        let userIndex = this.departmentsData[departmentIndex].users.findIndex(u => u.username == username);
                        this.$set(this.departmentsData[departmentIndex].users[userIndex].dayStatuses[day], 'type', "holiday");
                        this.$set(this.departmentsData[departmentIndex].users[userIndex].dayStatuses[day], 'holidayRecordIndex', 0);
                        
                        this.showSnackbar({text: 'FERIE INSERITE CON SUCCESSO', type: 'SUCCESS'}); 
                    } else {
                        this.showSnackbar({text: 'INSERIMENTO FERIE FALLITO', type: 'ERROR'});
                    }
                    this.confirmDialog = false;
                });

            } else {
                this.showSnackbar({text: 'INSERIMENTO FERIE FALLITO, NESSUN ORARIO PIANIFICATO', type: 'ERROR'});
            }

        },
        removeHoliday: function(username, dateIndex, day) {
            let date = DateTime.fromObject({day: day, month: this.selectedMonth.get("month"), year: this.selectedMonth.get("year")});
            this.confirmDialogAction = () => {
                workDayRecordApi.deleteUserWorkDayRecord(username, date.toISODate(), dateIndex).then(response => {
                    if(response.success) {
                        let departmentIndex = this.departmentsData.findIndex(d => d.users.map(u => u.username).includes(username));
                        let userIndex = this.departmentsData[departmentIndex].users.findIndex(u => u.username == username);
                        this.$set(this.departmentsData[departmentIndex].users[userIndex].dayStatuses[day], 'type', "free");
                        
                        this.showSnackbar({text: 'FERIE ELIMINATE CON SUCCESSO', type: 'SUCCESS'}); 
                    } else {
                        this.showSnackbar({text: 'ELIMINAZIONE FERIE FALLITO', type: 'ERROR'});
                    }
                    this.confirmDialog = false;
                });
            }
            this.confirmDialogText = `Eliminare le Ferie del ${date.setLocale('it').toFormat('cccc d LLLL')} per ${username}?`;
            this.confirmDialog = true;
            
        },
        insertSickRecord: function(username, day) {
            let date = DateTime.fromObject({day: day, month: this.selectedMonth.get("month"), year: this.selectedMonth.get("year")});
            workDayRecordApi.insertSickRecord(date.toISODate(), username).then(response => {
                if(response.success) {
                    let departmentIndex = this.departmentsData.findIndex(d => d.users.map(u => u.username).includes(username));
                    let userIndex = this.departmentsData[departmentIndex].users.findIndex(u => u.username == username);
                    this.$set(this.departmentsData[departmentIndex].users[userIndex].dayStatuses[day], 'type', "sick");
                    this.$set(this.departmentsData[departmentIndex].users[userIndex].dayStatuses[day], 'sickRecordIndex', 0);

                    this.showSnackbar({text: 'MALATTIA INSERITA CON SUCCESSO', type: 'SUCCESS'}); 
                } else {
                    this.showSnackbar({text: 'INSERIMENTO MALATTIA FALLITO', type: 'ERROR'});
                }
            });
        },
        removeSickRecord: function(username, dateIndex, day) {
            let date = DateTime.fromObject({day: day, month: this.selectedMonth.get("month"), year: this.selectedMonth.get("year")});
            this.confirmDialogAction = () => {
                workDayRecordApi.deleteUserWorkDayRecord(username, date.toISODate(), dateIndex).then(response => {
                    if(response.success) {
                        let departmentIndex = this.departmentsData.findIndex(d => d.users.map(u => u.username).includes(username));
                        let userIndex = this.departmentsData[departmentIndex].users.findIndex(u => u.username == username);
                        this.$set(this.departmentsData[departmentIndex].users[userIndex].dayStatuses[day], 'type', "free");
                        
                        this.showSnackbar({text: 'MALATTIA ELIMINATA CON SUCCESSO', type: 'SUCCESS'}); 
                    } else {
                        this.showSnackbar({text: 'ELIMINAZIONE MALATTIA FALLITO', type: 'ERROR'});
                    }
                    this.confirmDialog = false;
                });
            }
            this.confirmDialogText = `Eliminare la Malattia del ${date.setLocale('it').toFormat('cccc d LLLL')} per ${username}?`;
            this.confirmDialog = true;
        },
        addMonthsToDate: function(monthsToAdd) {
            this.selectedMonth = this.selectedMonth.plus({months: monthsToAdd});
        },
        isWorkingDayForUser: function(dUser, date) {
            let wrappedDate = DateTime.fromISO(date);
            let result = dUser.userWorkSchedules.find(uws => {
                let bool = DateTime.fromISO(uws.validityStartDate).startOf('day') <= wrappedDate && (uws.validityStopDate == null || wrappedDate <= DateTime.fromISO(uws.validityStopDate).endOf('day'));
                return bool;
            });
            let activeUWS = result;
            if(activeUWS) {
                let dayKey = ['mondayWorkTimes', 'tuesdayWorkTimes', 'wednesdayWorkTimes', 'thursdayWorkTimes', 'fridayWorkTimes', 'saturdayWorkTimes', 'sundayWorkTimes'][wrappedDate.weekday - 1];
                let times = activeUWS.scheduledWeekWorkTimes[dayKey];
                if((times.morningTimes.clockInTime && times.morningTimes.clockOutTime) || (times.afternoonTimes.clockInTime && times.afternoonTimes.clockOutTime)) {
                    if(this.isHolidayCheck(wrappedDate.toJSDate())) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }

        },
        fetchDepartmentUsersData: async function() {
            this.userWorkDaysData = {};
                departmentApi.getDepartmentsWithUsersData().then(async (response) => {
                    if(response.success) {
                        this.departmentsData = response.data;
                        this.loadingUwdData = [];
                        this.departmentsData.forEach(dd => {
                            this.loadingUwdData = [...this.loadingUwdData, ...dd.users.map(user => user.username)];
                        });
                        this.totalUsers = this.loadingUwdData.length;

                        let ddIndex = 0;
                        for(let dd of this.departmentsData) {

                        // }
                        // this.departmentsData.forEach((dd, ddIndex) => {

                            let dUserIndex = 0;
                            for(let dUser of dd.users) {

                            // }
                            // dd.users.forEach((dUser, dUserIndex) => {
                                this.$set(this.departmentsData[ddIndex].users[dUserIndex], "dayStatuses", []);
                                //dUser.dayStatuses = [];
                                let response = await userWorkDayApi.getUserWorkDayByUserDateRange(dUser.username, this.selectedMonth.startOf('month').toISODate(), this.selectedMonth.endOf('month').toISODate());
                                if(response.success) {
                                    this.userWorkDaysData[dUser.username] = response.data;
                                    let registeredDays = response.data.map(userWorkDay => DateTime.fromISO(userWorkDay.workDate).get("day"));
                                    let unregisteredDays = Array.from({length: this.selectedMonth.endOf('month').get('day')}, (_, i) => i + 1);
                                    unregisteredDays = unregisteredDays.filter(day => !registeredDays.includes(day));
                                    unregisteredDays.forEach(day => {
                                        let date = DateTime.fromObject({year: this.selectedMonth.get("year"), month: this.selectedMonth.get("month"), day: day});
                                        let statusObject = {
                                            freezed: false,
                                            type: 'free',
                                            holidayIndex: null
                                        }
                                        if(!this.isWorkingDayForUser(dUser, date.toISODate())) {
                                            statusObject.type = 'disabled';
                                        } else {
                                            statusObject.type = 'free';
                                        }

                                        this.$set(this.departmentsData[ddIndex].users[dUserIndex].dayStatuses, day, statusObject);
                                        
                                    });
                                    response.data.forEach(userWorkDay => {
                                        unregisteredDays = unregisteredDays.splice(unregisteredDays.indexOf(), )
                                        let statusObject = {
                                            freezed: false,
                                            type: 'free',
                                            holidayRecordIndex: null,
                                            sickRecordIndex: null,
                                        }
                                        let sickRecord = userWorkDay.workDayRecords.find(record => record.recordType == "SICK_RECORD");
                                        let holidayRecord = userWorkDay.workDayRecords.find(record => record.recordType == "HOLIDAY_RECORD");

                                        if(sickRecord != null) {
                                            //this.$set(this.departmentsData[ddIndex].users[dUserIndex].dayStatuses, DateTime.fromISO(userWorkDay.workDate).get('day'), 'sick');
                                            statusObject.type = 'sick';
                                            statusObject.sickRecordIndex = sickRecord.dateIndex;
                                        } else if(holidayRecord != null) {
                                            //this.$set(this.departmentsData[ddIndex].users[dUserIndex].dayStatuses, DateTime.fromISO(userWorkDay.workDate).get('day'), 'holiday');
                                            statusObject.type = 'holiday';
                                            statusObject.holidayRecordIndex = holidayRecord.dateIndex;
                                        } else if(userWorkDay.workDayRecords.length > 0 || userWorkDay.businessTrip || userWorkDay.overtimeMinutes > 0) {
                                            //this.$set(this.departmentsData[ddIndex].users[dUserIndex].dayStatuses, DateTime.fromISO(userWorkDay.workDate).get('day'), 'disabled');
                                            statusObject.type = 'disabled';
                                        } else if(!this.isWorkingDayForUser(dUser, userWorkDay.workDate)) {
                                            //this.$set(this.departmentsData[ddIndex].users[dUserIndex].dayStatuses, DateTime.fromISO(userWorkDay.workDate).get('day'), 'disabled');
                                            statusObject.type = 'disabled';
                                        }

                                        if(userWorkDay.freezed) {
                                            statusObject.freezed = true;
                                        }

                                        this.$set(this.departmentsData[ddIndex].users[dUserIndex].dayStatuses, DateTime.fromISO(userWorkDay.workDate).get('day'), statusObject);
                                    })
                                } else {
                                    this.showSnackbar({text: "ERRORE NEL CARICAMENTO DEI DATI DELL'UTENTE " + dUser.username, type: "ERROR"});
                                }
                                this.loadingUwdData.splice(this.loadingUwdData.indexOf(dUser.username), 1);
                                dUserIndex += 1;
                            }//);
                            ddIndex += 1;
                        }//);

                    } else {
                        this.showSnackbar({text: "ERRORE NEL CARICAMENTO DEI DATI", type: "ERROR"});
                    }
                });
            
        },
    },
    computed: {
        ...mapState({
            users: state => state.dataStorage.users,
        }),
        ...mapGetters({
            isHolidayCheck: dataStorageActions.DATASTORAGE_ISHOLIDAYCHECK,
        }),
        displayDepartmentUserData: function() {
            if (this.loadingData)
                return [];
            let result = JSON.parse(JSON.stringify(this.departmentsData));
            result.forEach((dd, ddIndex) => {
                dd.users.forEach((dUser, dUserIndex) => {
                    result[ddIndex].users[dUserIndex].dayStatuses = [];

                    let userWorkDays = this.userWorkDaysData[dUser.username] || [];

                    let registeredDays = userWorkDays.map(userWorkDay => DateTime.fromISO(userWorkDay.workDate).get("day"));
                    let unregisteredDays = Array.from({length: this.selectedMonth.endOf('month').get('day')}, (_, i) => i + 1);
                    unregisteredDays = unregisteredDays.filter(day => !registeredDays.includes(day));
                    unregisteredDays.forEach(day => {
                        let date = DateTime.fromObject({year: this.selectedMonth.get("year"), month: this.selectedMonth.get("month"), day: day});
                        let statusObject = {
                            freezed: false,
                            type: 'free',
                            holidayIndex: null
                        }
                        if(!this.isWorkingDayForUser(dUser, date.toISODate())) {
                            statusObject.type = 'disabled';
                        } else {
                            statusObject.type = 'free';
                        }

                        result[ddIndex].users[dUserIndex].dayStatuses[day] = statusObject;
                        
                    });
                    userWorkDays.forEach(userWorkDay => {
                        unregisteredDays = unregisteredDays.splice(unregisteredDays.indexOf(), )
                        let statusObject = {
                            freezed: false,
                            type: 'free',
                            holidayRecordIndex: null,
                            sickRecordIndex: null,
                        }
                        let sickRecord = userWorkDay.workDayRecords.find(record => record.recordType == "SICK_RECORD");
                        let holidayRecord = userWorkDay.workDayRecords.find(record => record.recordType == "HOLIDAY_RECORD");

                        if(sickRecord != null) {
                            //this.$set(this.departmentsData[ddIndex].users[dUserIndex].dayStatuses, DateTime.fromISO(userWorkDay.workDate).get('day'), 'sick');
                            statusObject.type = 'sick';
                            statusObject.sickRecordIndex = sickRecord.dateIndex;
                        } else if(holidayRecord != null) {
                            //this.$set(this.departmentsData[ddIndex].users[dUserIndex].dayStatuses, DateTime.fromISO(userWorkDay.workDate).get('day'), 'holiday');
                            statusObject.type = 'holiday';
                            statusObject.holidayRecordIndex = holidayRecord.dateIndex;
                        } else if(userWorkDay.workDayRecords.length > 0 || userWorkDay.businessTrip || userWorkDay.overtimeMinutes > 0) {
                            //this.$set(this.departmentsData[ddIndex].users[dUserIndex].dayStatuses, DateTime.fromISO(userWorkDay.workDate).get('day'), 'disabled');
                            statusObject.type = 'disabled';
                        } else if(!this.isWorkingDayForUser(dUser, userWorkDay.workDate)) {
                            //this.$set(this.departmentsData[ddIndex].users[dUserIndex].dayStatuses, DateTime.fromISO(userWorkDay.workDate).get('day'), 'disabled');
                            statusObject.type = 'disabled';
                        }

                        if(userWorkDay.freezed) {
                            statusObject.freezed = true;
                        }

                        result[ddIndex].users[dUserIndex].dayStatuses[DateTime.fromISO(userWorkDay.workDate).get('day')] = statusObject;
                    })
                });
            });
            return result;
        },
        editing: function() {
            return this.holidayEditing || this.sickRecordsEditing;
        },
        loadingData: function() {
            return this.loadingUwdData.length > 0;
        },
        loadingProgress: function() {
            return 100*(this.totalUsers - this.loadingUwdData.length) / this.totalUsers;
        },
        isoSelectedMonth: {
            get: function() {
                return this.selectedMonth.toISODate();
            },
            set: function(val) {
                this.selectedMonth = DateTime.fromISO(val).startOf("month");
            }
        }
    },
    watch: {
        selectedMonth: function(val) {
            this.fetchDepartmentUsersData();
        },
        holidayEditing: function(val) {
            if(val && this.sickRecordsEditing)
                this.sickRecordsEditing = false;
        },
        sickRecordsEditing: function(val) {
            if(val && this.holidayEditing)
                this.holidayEditing = false;
        } 
    },
    mounted: function() {
        this.fetchDepartmentUsersData();

        
    },
    updated: function () {
        // this.$nextTick(function () {
        //     var scrollers = document.getElementsByClassName('scroller');

        //     var scrollerDivs = Array.prototype.filter.call(scrollers, function(testElement) {
        //         return testElement.nodeName === 'DIV';
        //     });

        //     function scrollAll(scrollLeft) {
        //         scrollerDivs.forEach(function(element, index, array) {
        //             element.scrollLeft = scrollLeft;
        //         });
        //     }

        //     scrollerDivs.forEach(function(element, index, array) {
        //         element.addEventListener('scroll', function(e) {
        //             scrollAll(e.target.scrollLeft);
        //         });
        //     });
        // })
    }
}
</script> 
<style>
    
    .scroller {
        overflow-x: auto;
        overflow-y: hidden;
        
    }
    .bordered {
        border: 1px solid;
    }
    /* div {
        border: 1px solid;
    }
    SPAN {
        border: 1px solid;
    } */
</style>