import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height fill-width d-flex flex-column pa-0",attrs:{"fluid":""}},[_c(VContainer,{staticClass:"d-flex align-center justify-center pa-0 overflow-auto",attrs:{"fluid":""}},[_c(VLayout,{staticClass:"pa-0 overflow-auto",staticStyle:{"max-height":"calc(100vh - 64px) !important"},attrs:{"child-flex":""}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.displayConsolidationData,"search":_vm.search,"disable-pagination":"","hide-default-footer":"","single-expand":false,"expanded":_vm.expanded,"item-key":"id","sort-by":"period.periodTime","sort-desc":"","show-expand":"","loading":_vm.dataLoading},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Cerca","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VBtn,{staticClass:"mb-2",attrs:{"color":"light-blue lighten-4"},on:{"click":function($event){_vm.showConsolidationDialog = true}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-file-lock ")]),_vm._v(" Consolida Mensilità ")],1)],1)]},proxy:true},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.period.periodName)+" ")]}},{key:"item.creationTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.displayCreationTime)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status == 'error')?_c(VIcon,_vm._g(_vm._b({attrs:{"color":"red lighten-1"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")]):_vm._e(),(item.status == 'warning')?_c(VIcon,_vm._g(_vm._b({attrs:{"color":"orange lighten-1"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")]):_vm._e(),(item.status == 'ok')?_c(VIcon,_vm._g(_vm._b({attrs:{"color":"green lighten-1"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")]):_vm._e()]}}],null,true)},[(item.status == 'error')?_c('span',[_vm._v(" È necessario intervenire per risolvere gli errori presenti nei turni consolidati ")]):_vm._e(),(item.status == 'warning')?_c('span',[_vm._v(" Sono presenti dei Warnings, è possibile generare Reports sul periodo ")]):_vm._e(),(item.status == 'ok')?_c('span',[_vm._v(" È possibile generare Reports sul periodo ")]):_vm._e()])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.confirmConsolidationUndo(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-undo-variant ")])]}}],null,true)},[_c('span',[_vm._v("Annulla Consolidamento")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VContainer,{staticClass:"d-flex flex-wrap"},_vm._l((item.usersErrors),function(userErrors,index){return _c(VSheet,{key:item.id + '_' + index,staticClass:"d-flex flex-row justify-space-between py-2 px-4 align-center ma-2 clickable",attrs:{"rounded":"pill","color":userErrors.status == 'ok' ? 'green lighten-4' : 'red lighten-3'},on:{"click":function($event){userErrors.status != 'ok' ? _vm.openConsolidationErrorsDisplayDialog(userErrors.user.username, item.id) : function() {}}}},[_c(VSpacer),_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(userErrors.user.username)+" ")]),_c(VSpacer),(userErrors.status == 'ok')?_c(VIcon,{staticClass:"ml-2",attrs:{"small":""}},[_vm._v(" mdi-check ")]):_c(VIcon,{staticClass:"ml-2",attrs:{"small":""}},[_vm._v(" mdi-magnify ")])],1)}),1)],1)]}}])})],1),_c('DataConsolidationDialog',{attrs:{"show":_vm.showConsolidationDialog},on:{"dialog-close":_vm.closeConsolidationDialog,"refresh-consolidation-records":_vm.fetchConsolidationRecords}}),_c('ConsolidationErrorsDisplay',{attrs:{"show":_vm.showConsolidationErrorsDisplayDialog,"userErrors":_vm.displayingUserErrors},on:{"close-user-consolidation-errors-dialog":_vm.closeConsolidationErrorsDisplayDialog,"open-error-resolve-dialog":_vm.openConsolidationErrorResolveDialog}}),_c('ConsolidationErrorResolve',{attrs:{"show":_vm.showConsolidationErrorResolveDialog,"userErrors":_vm.displayingUserErrors,"resolvingErrorIndex":_vm.resolvingErrorIndex},on:{"close-consolidation-error-resolve-dialog":_vm.closeConsolidationErrorResolveDialog,"refresh-consolidation-user-errors":_vm.refreshConsolidationUserError}}),_c('ConfirmDialog',{attrs:{"dialog":_vm.confirmDialogShow,"dialogText":_vm.confirmDialogText,"dialogSubtext":_vm.confirmDialogSubtext},on:{"c-dialog-confirm":function($event){return _vm.confirmDialogAction()},"c-dialog-cancel":function($event){_vm.confirmDialogShow = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }