import gqlRequestManager from './gqlRequestManager'

import { userWorkScheduleEndPoint } from './endpoints'
import { 
    getUserWorkSchedules,
    getUserWorkSchedulesByUser,
    createUserWorkSchedule,
    updateUserWorkSchedule,
    deleteUserWorkSchedule,
 } from '../utils/queries/userWorkSchedule'

export default {
    getUserWorkSchedulesByUser: function(username) {
        return gqlRequestManager(
            userWorkScheduleEndPoint,
            {
                query: getUserWorkSchedulesByUser.query,
                variables: {
                    username: username,
                }
            },
            getUserWorkSchedulesByUser.dataName
        )
    },
    getUserWorkSchedules: function() {
        return gqlRequestManager(
            userWorkScheduleEndPoint, 
            {
                query: getUserWorkSchedules.query,
            },
            getUserWorkSchedules.dataName
        );
    },
    createUserWorkSchedule: function(userWorkSchedule, username, baseWorkScheduleDummyName) {
        return gqlRequestManager(
            userWorkScheduleEndPoint,
            {
                query: createUserWorkSchedule.query,
                variables: {
                    userWorkSchedule: userWorkSchedule,
                    username: username,
                    baseWorkScheduleDummyName: baseWorkScheduleDummyName
                }
            },
            createUserWorkSchedule.dataName
        )
    },
    updateUserWorkSchedule: function(updatedUserWorkSchedule, username, updatedBaseWorkScheduleDummyName, userWorkScheduleValidityStartDate) {
        return gqlRequestManager(
            userWorkScheduleEndPoint,
            {
                query: updateUserWorkSchedule.query,
                variables: {
                    updatedUserWorkSchedule: updatedUserWorkSchedule,
                    username: username,
                    updatedBaseWorkScheduleDummyName: updatedBaseWorkScheduleDummyName,
                    userWorkScheduleValidityStartDate: userWorkScheduleValidityStartDate
                }
            },
            updateUserWorkSchedule.dataName
        )
    },
    deleteUserWorkSchedule: function(username, userWorkScheduleValidityStartDate) {
        return gqlRequestManager(
            userWorkScheduleEndPoint,
            {
                query: deleteUserWorkSchedule.query,
                variables: {
                    username: username,
                    userWorkScheduleValidityStartDate: userWorkScheduleValidityStartDate
                }
            },
            deleteUserWorkSchedule.dataName
        )
    }
}