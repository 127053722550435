import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.displayUsers,"search":_vm.search,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Cerca","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VBtn,{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogEditUser = true}}},[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Crea Utente ")],1)],1)]},proxy:true},{key:"item.username",fn:function(ref){
var item = ref.item;
return [(item.disabled)?_c(VIcon,{staticClass:"mr-2",attrs:{"small":"","color":"red lighten-2"}},[_vm._v(" mdi-lock ")]):_c(VIcon,{staticClass:"mr-2",attrs:{"small":"","color":_vm.userRoles[item.role].color}},[_vm._v(" "+_vm._s(_vm.userRoles[item.role].icon)+" ")]),_vm._v(" "+_vm._s(item.username)+" ")]}},{key:"item.activeWorkSchedule.baseWorkScheduleDummy.workScheduleDummyName",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editUserWorkSchedule(item)}}},[_vm._v(" mdi-account-clock ")]),_vm._v(" "+_vm._s((item.activeWorkSchedule && item.activeWorkSchedule.baseWorkScheduleDummy) ? item.activeWorkSchedule.baseWorkScheduleDummy.workScheduleDummyName : '')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.role != 'SYSTEM')?_c('span',[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),(item.disabled)?_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.enableItem(item)}}},[_vm._v(" mdi-lock-open-outline ")]):_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.disableItem(item)}}},[_vm._v(" mdi-lock ")]),_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}}])}),_c('UserEdit',{attrs:{"editedUser":_vm.editedItem,"edit":_vm.editingUser,"show":_vm.dialogEditUser},on:{"dialog-close":_vm.closeUserEdit,"create-user":_vm.createUser,"update-user":_vm.updateUser}}),_c('UserWorkScheduleEdit',{attrs:{"show":_vm.dialogUserWorkSchedules,"user":_vm.editedItem},on:{"close-dialog":_vm.closeDialogUWSEdit,"create-user-work-schedule":_vm.createUserWorkSchedule,"update-user-work-schedule":_vm.updateUserWorkSchedule,"delete-user-work-schedule":_vm.deleteUserWorkSchedule}}),_c('ConfirmDialog',{attrs:{"dialog":_vm.dialogDelete,"dialogText":'Eliminare definitivamente l\'utente ' + this.editedItem.username + '?'},on:{"c-dialog-confirm":_vm.deleteItemConfirm,"c-dialog-cancel":_vm.closeDialogDelete}}),_c('ConfirmDialog',{attrs:{"dialog":_vm.dialogDisable,"dialogText":'Disattivare l\'utente ' + this.editedItem.username + '?'},on:{"c-dialog-confirm":_vm.disableItemConfirm,"c-dialog-cancel":_vm.closeDialogDisable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }