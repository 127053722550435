<template>
    <v-container fluid style="overflow-y: auto;">
        <v-data-table
            :headers="headers"
            :items="departments"
            :search="search"
            disable-pagination
            hide-default-footer
        >
        <template v-slot:top>
        <v-toolbar
            flat
        >
        
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cerca"
            single-line
            hide-details
        ></v-text-field>
        
            <v-divider
            class="mx-4"
            inset
            vertical
            ></v-divider>
            <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="dialogEditDepartment = true"
                >
                <v-icon
                    left
                    dark
                >
                    mdi-plus
                </v-icon>
                Crea Reparto
                </v-btn>
            
        </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
        >
            mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
        >
            mdi-delete
        </v-icon>
        </template>
        </v-data-table>
        <ConfirmDialog
            :dialog="dialogDelete" 
            :dialogText="'Eliminare definitivamente il Reparto ' + this.editedItem.departmentName + '?'"
            v-on:c-dialog-confirm="deleteItemConfirm"
            v-on:c-dialog-cancel="closeDelete"
        />
        <DepartmentEdit
            :show="dialogEditDepartment"
            :editedDepartment="editedItem"
            :edit="editingDepartment"
            v-on:dialog-close="closeEdit"
            v-on:create-department="createDepartment"
            v-on:update-department="updateDepartment"
        />
    </v-container>
</template>

<script>
export const ConfirmDialog = () => import('../../../global/ConfirmDialog.vue')
export const DepartmentEdit = () => import('../../anagraphics/dialogs/DepartmentEdit.vue')

import departmentApi from '../../../../api/department'

import { mapState } from 'vuex'

export default {
  data: function() {
      return {
        search: '',
        dialog: false,
        dialogEditDepartment: false,
        dialogDelete: false,
        headers: [
            { text: 'Nome Reparto', align: 'start', value: 'departmentName' },
            { text: 'Conteggio Utenti', align: 'start', value: 'usersCount' },
            { text: '', value: 'actions', sortable: false, align: 'end' },
        ],
        editedIndex: -1,
        editedItem: {
            departmentName: '',
        },
        defaultItem: {
            departmentName: '',
        },
      };
  },
  components: {
      ConfirmDialog,
      DepartmentEdit
  },
  computed: {
    ...mapState({
        departments: state => state.dataStorage.departments,
    }), 
    editingDepartment () {
        return this.editedIndex >= 0;
    }
  },
methods: {
    editItem (item) {
      this.editedIndex = this.departments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogEditDepartment = true
    },

    deleteItem (item) {
      this.editedIndex = this.departments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      let self = this;
      let editedDepartment = { ...self.editedItem };
      departmentApi.deleteDepartment(editedDepartment.departmentName).then(response => {
          if(response.success) {
              this.emitFetchDepartmentsEvent();
          }
        });
      self.closeDelete()
    },


    closeEdit() {
        this.dialogEditDepartment = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
        })
    },

    closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
        })
    },
    emitFetchDepartmentsEvent() {
        this.$emit('refresh-departments-data');
    },
    createDepartment(department) {
        departmentApi.createDepartment(department.departmentName).then(response => {
            if(response.success) {
                this.emitFetchDepartmentsEvent();
            }
            this.closeEdit();
        })
    },
    updateDepartment(departmentName, updatedDepartment) {
        departmentApi.updateDepartment(departmentName, updatedDepartment.departmentName).then(response => {
            if(response.success) {
                this.emitFetchDepartmentsEvent();
            }
            this.closeEdit();
        })
    },
  },
}
</script>
