<template>
    <v-container fluid class="pa-0">
        <v-tabs
            fixed-tabs
            background-color="blue lighten-5"
            v-model="tab"
        >
            <v-tab 
                key="users"
                :to="{name: 'UsersAnagraphic'}"
            >
                <v-icon
                    left
                >
                    mdi-account-multiple
                </v-icon>
                Utenti
            </v-tab>
            <v-tab 
                key="companies"
                :to="{name: 'CompaniesAnagraphic'}"
            >
                <v-icon
                    left
                >
                    mdi-factory
                </v-icon>
                Aziende
            </v-tab>
            <v-tab 
                key="departments"
                :to="{name: 'DepartmentsAnagraphic'}"
            >
                <v-icon
                    left
                >
                    mdi-account-group
                </v-icon>
                Reparti
            </v-tab>
            <v-tab 
                key="times"
                :to="{name: 'WorkScheduleDummiesAnagraphic'}"
            >
                <v-icon
                    left
                >
                    mdi-archive-clock
                </v-icon>
                Modelli Orari
            </v-tab>
            <v-tab 
                key="holidays"
                :to="{name: 'HolidaysAnagraphic'}"
            >
                <v-icon
                    left
                >
                    mdi-umbrella-beach
                </v-icon>
                Festività
            </v-tab>
        </v-tabs>
        <router-view 
            v-on:refresh-users-data="fetchUsers"
            v-on:refresh-companies-data="fetchCompanies"
            v-on:refresh-user-work-schedules-data="fetchUserWorkSchedules"
            v-on:refresh-work-schedule-dummies-data="fetchWorkScheduleDummies"
            v-on:refresh-local-years-holidays-data="fetchLocalHolidays"
            v-on:refresh-departments-data="fetchDepartments"
        />
        <!-- <v-tabs-items v-model="tab">
            <v-tab-item key="users">
                <v-container fluid>
                    <UsersTable
                        :users="users"
                        v-on:refresh-users-data="fetchUsers"
                    />
                </v-container>
            </v-tab-item>
            <v-tab-item key="companies">
               <v-container fluid>
                    <CompaniesTable
                        :companies="companies"
                        
                    />
                </v-container>
            </v-tab-item>
            <v-tab-item key="times">
                <v-card
                    color="basil"
                    flat
                >
                <v-card-text></v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items> -->
    </v-container>
</template>
<script>
// export const UsersTable = () => import('./anagraphics/tables/UsersTable.vue')
// export const CompaniesTable = () => import('./anagraphics/tables/CompaniesTable.vue')

import userApi from '../../api/user'
import companyApi from '../../api/company'
import userWorkScheduleApi from '../../api/userWorkSchedule'
import workScheduleDummyApi from '../../api/workScheduleDummy'
import localYearsHolidaysApi from '../../api/localYearHolidays'
import departmentApi from '../../api/department'

import { mapActions, mapState } from 'vuex'

import * as dataStorageActions from '../../store/actions/dataStorage'

export default {
    data: function() {
        return {
            tab: 'users'
        }
    },
    components: {
        // UsersTable,
        // CompaniesTable
    },
    methods: {
        ...mapActions({
            saveUsers: dataStorageActions.DATASTORAGE_SETUSERS,
            saveCompanies: dataStorageActions.DATASTORAGE_SETCOMPANIES,
            saveDepartments: dataStorageActions.DATASTORAGE_SETDEPARTMENTS,
            saveWorkScheduleDummies: dataStorageActions.DATASTORAGE_SETWORKSCHEDULEDUMMIES,
            saveLocalHolidays: dataStorageActions.DATASTORAGE_SETLOCALYEARSHOLIDAYS,
            saveUserWorkSchedules: dataStorageActions.DATASTORAGE_SETUSERWORKSCHEDULES
        }),
        fetchUsers: function() {
            userApi.getUsers().then(response => {
                if(response.success) {
                    this.saveUsers(response.data);
                }
            });
        },
        fetchCompanies: function() {
            companyApi.getCompanies().then(response => {
                if(response.success) {
                    this.saveCompanies(response.data);
                }
            })
        },
        fetchDepartments: function() {
            departmentApi.getDepartments().then(response => {
                if(response.success) {
                    this.saveDepartments(response.data);
                }
            })
        },
        fetchUserWorkSchedules: function() {
            userWorkScheduleApi.getUserWorkSchedules().then(response => {
                if(response.success) {
                    let userWorkSchedulesDict = {};
                    response.data.forEach(uws => {
                        let username = uws.user.username;
                        delete uws.user;
                        if (userWorkSchedulesDict[username] == null)
                            userWorkSchedulesDict[username] = [];
                        userWorkSchedulesDict[username].push(uws);
                    });
                    this.saveUserWorkSchedules(userWorkSchedulesDict);
                }
            })
        },
        fetchWorkScheduleDummies: function() {
            workScheduleDummyApi.getWorkScheduleDummies().then(response => {
                if(response.success) {
                    this.saveWorkScheduleDummies(response.data);
                }
            })
        },
        fetchLocalHolidays: function() {
            localYearsHolidaysApi.getLocalYearsHolidays().then(response => {
                if(response.success) {
                    this.saveLocalHolidays(response.data);
                } 
            })
        }
    },
    // computed: {
    //     ...mapState({
    //         users: state => state.dataStorage.users,
    //         companies: state => state.dataStorage.companies,
    //     }), 
    // },
    mounted: function() {
        this.fetchUsers();
        this.fetchCompanies();
        this.fetchUserWorkSchedules();
        this.fetchWorkScheduleDummies();
        this.fetchLocalHolidays();
        this.fetchDepartments();
    },
}
</script> 