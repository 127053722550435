import loginApi from '../api/login'
import userApi from '../api/user'

class AuthService {
    async triggerAuthenticateControl() {
        return loginApi.checkAuth().then(response => {
            if(response) {
                return {
                    success: true, 
                    userFetchPromise: userApi.currentUser().then(response => {
                        return response;
                    })
                }
            } else {
                return {success: false}
            }
        })
    }
}

const authService = new AuthService();

export default authService;