<template>
  <v-container class="fill-height fill-width d-flex flex-column" fluid>
    <v-container fluid class="d-flex align-center justify-center pa-0">
      <v-row class="fill-width">
        <v-container style="text-transform: none !important;">
          <v-card color="grey lighten-4" class="d-flex flex-row align-center rounded-l-xl rounded-r-pill">

            <div>
                <v-sheet color="rgb(255, 0, 0, 0)" style="text-transform: none !important;" class="mx-6 my-2">
                  <v-row dense justify="center" no-gutters>
                    <div
                      class="text-subtitle-2 transition-swing"
                    >
                      {{representDisplayDate.day}}
                    </div>
                  </v-row>
                  <v-row dense justify="center" no-gutters>
                    <div
                      class="text-h4 transition-swing  fill-height"
                    >
                      {{representDisplayDate.date}}
                    </div>
                  </v-row>
                  <v-row dense justify="center" no-gutters>
                    <div
                      class="text-subtitle-2 transition-swing"
                    >
                      {{representDisplayDate.month}}
                    </div>
                  </v-row>
                </v-sheet>
              </div>
              <div class="d-flex flex-column" style="z-index: 6;">
                <v-sheet color="purple lighten-3" rounded="pill" class="d-flex flex-row justify-space-between py-2 px-4" v-if="isOvertime">
                  <v-icon left color="black">
                    mdi-alarm-plus
                  </v-icon>
                  <v-spacer />
                  <div class="font-weight-medium">
                    {{overtimeString}}
                  </div>
                  <v-spacer />
                </v-sheet>
                <v-sheet rounded="pill" class="text-center py-2 px-4" v-else>
                  <v-icon color="grey lighten-1">
                    mdi-alarm-off
                  </v-icon>
                </v-sheet>
                <v-spacer class="my-1"/>
                <v-sheet color="amber accent-4" rounded="pill" class="d-flex flex-row justify-space-between py-2 px-4" v-if="isTripDay">
                  <v-icon left color="black">
                    mdi-bag-suitcase
                  </v-icon>
                  <v-spacer />
                  <div class="font-weight-medium">
                    Trasferta
                  </div>
                  <v-spacer />
                </v-sheet>
                <v-sheet rounded="pill" class="text-center py-2 px-4" v-else>
                  <v-icon color="grey lighten-1">
                    mdi-bag-suitcase-off
                  </v-icon>
                </v-sheet>
                
              </div>
              <v-spacer />
              <div class="d-flex flex-row" style="z-index: 6;" v-if="((morningTimesPresent || afternoonTimesPresent) && !isCurrentDateHoliday) || isFreezed">
                <v-sheet rounded="pill" class="d-flex flex-row justify-space-between align-center py-2 px-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-progress-circular
                        :rotate="-90"
                        :size="40"
                        :width="5"
                        :value="dayTimeProgressObject.complete || ((isFreezed || hasVariationProposal) && !variationProposalCreating) ? 100 : dayTimeProgressObject.progressPercentage"
                        :color="dayStatusObject.color"
                        class="d-flex align-center justify-center"
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-icon 
                          :color="dayStatusObject.color"
                          @click="dayStatusObject.action()"
                        >
                          {{dayStatusObject.icon}}
                        </v-icon>
                        <!-- <v-icon 
                          color="orange lighten-1"
                          @click="variationProposalCreating = true"
                          v-if="hasVariationProposal && !variationProposalCreating"
                        >
                          mdi-alert-decagram
                        </v-icon>
                        <v-icon v-else :color="dayTimeProgressObject.complete ? 'green lighten-2' : 'grey darken-1'">
                          mdi-clock
                        </v-icon> -->
                      </v-progress-circular>
                    </template>
                    <div v-for="(note, index) in dayStatusObject.notes" :key="index">
                      {{note}}
                    </div>
                    <!-- <div v-if="hasVariationProposal">
                      <div>
                          Proposta di Modifica in attesa di approvazione
                      </div>
                      <div>
                          Creata il:&nbsp;&nbsp;{{formattedTimeExtended(userWorkDay.variationProposal.creationTime)}}
                      </div>
                    </div>
                    <div v-else>
                      <div>
                          Tempo di lavoro pianificato: {{formattedDuration(dayTimeProgressObject.scheduledTime)}}
                      </div>
                      <div>
                          Tempo di lavoro registrato: {{formattedDuration(dayTimeProgressObject.registeredTime)}}
                      </div>
                    </div> -->
              
                  </v-tooltip>
                  
                  <v-spacer class="mx-1" v-if="(morningTimesPresent || afternoonTimesPresent) && !isCurrentDateHoliday"/>
                  <div class="font-weight-medium d-flex flex-column mx-2 justify-center" v-if="(morningTimesPresent || afternoonTimesPresent) && !isCurrentDateHoliday">
                    <span v-if="morningTimesPresent">{{formattedTime( workDayTimes.morningTimes.clockInTime)}}&nbsp;-&nbsp;{{formattedTime( workDayTimes.morningTimes.clockOutTime)}}</span>
                    <span v-if="afternoonTimesPresent">{{formattedTime( workDayTimes.afternoonTimes.clockInTime)}}&nbsp;-&nbsp;{{formattedTime( workDayTimes.afternoonTimes.clockOutTime)}}</span>
                  </div>
                </v-sheet>
              </div>
              <v-spacer />
              <v-tooltip left v-if="variationProposalCreating && hasVariationProposal">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    large
                    class="ma-4"
                    color="orange lighten-2"
                    style="z-index: 6;"
                    @click="deleteDateVariationProposal"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Elimina Proposta di Modifica</span>
              </v-tooltip>
              <v-tooltip left v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    large
                    class="ma-4"
                    :color="monthWorkTimesCoverageStatus == 'ok' || loadingMonthData ? 'orange darken-3' : 'red'"
                    dark
                    @click="emitShowCalendarEvent"
                    v-bind="attrs"
                    v-on="on"
                    :loading="loadingMonthData"
                  >
                    <v-icon v-if="monthWorkTimesCoverageStatus == 'ok'">
                      mdi-calendar
                    </v-icon>
                    <v-icon v-else>
                      mdi-calendar-alert
                    </v-icon>
                  </v-btn>
                </template>
                <span v-if="monthWorkTimesCoverageStatus == 'ok'">Visualizza Calendario</span>
                <span v-else>
                  <div>
                    Visualizza Calendario
                  </div>
                  <div>
                    Sono presenti giornate non coperte
                  </div>
                </span>
              </v-tooltip>
          </v-card>
        </v-container>
      </v-row>
    </v-container>
    <v-container class="d-flex justify-center">
      <v-alert
        width="100vw"
        v-if="hasNote"
        color="grey lighten-1"
        elevation="2"
        dense
        class="rounded-l-xl rounded-r-pill"
        icon="mdi-text"
      >
        <!-- <v-icon>
          mdi-text
        </v-icon>
        <v-spacer /> -->
        <span class="font-italic text--secondary font-weight-medium">
          {{variationProposalCreating ? (proposedUserWorkDay || {}).notes : (userWorkDay || {}).notes}}
        </span>
        <!-- <v-spacer /> -->
      </v-alert>
    </v-container>
    <v-spacer />
    <v-container fluid class="d-flex align-center justify-center">
      <v-row v-if="loadingDayData" class="fill-heigth fill-width" justify="center">
        <v-progress-circular
          :size="80"
          :width="6"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
      <v-row v-else class="fill-heigth fill-width" justify="center">
        <v-col v-if="isEmptyUWD && hasVariationProposal" class="d-flex flex-column justify-center">
          <v-row class="d-flex flex-column justify-center align-center mb-4">
            <div>
              <v-icon>
                mdi-lock
              </v-icon>
            </div>
            <div class="font-italic grey--text text--darken-2 text-h5">
              Proposta di Modifica inviata
            </div>
            <div class="font-italic grey--text text--darken-1">
              in attesa della revisione di un amministratore
            </div>
          </v-row>
        </v-col>
        <v-col v-if="isEmptyUWD && !hasVariationProposal" class="d-flex flex-column justify-center" >
          <v-row class="d-flex flex-column justify-center align-center mb-4">
            <div class="font-italic grey--text text--darken-2 text-h4">
              Buongiorno, {{firstName}}
            </div>
            <div class="font-italic grey--text text--darken-1 text-h6" v-if="isCurrentDayAWorkingDay">
              nessuna timbratura registrata
            </div>
            <div class="font-italic grey--text text--darken-1 text-h6" v-else> 
              nessun orario di lavoro previsto per la giornata corrente
            </div>
          </v-row>
          <v-row class="d-flex justify-center" v-if="!isReadOnly && !isCurrentDayAWorkingDay">
            <v-tooltip bottom v-if="!isCurrentDayAWorkingDay && !isOvertime">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  x-large
                  class="mr-4"
                  color="deep-orange lighten-3"
                  @click="setAvailabilityDay"
                  style="z-index: 6;"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-face-agent</v-icon>
                </v-btn>
              </template>
              <span>Registra Reperibilità</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!isCurrentDayAWorkingDay">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  x-large
                  color="purple lighten-3"
                  style="z-index: 6;"
                  @click="setOvertime"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-alarm</v-icon>
                </v-btn>
              </template>
              <span>Modifica Straordinario</span>
            </v-tooltip>
          </v-row>
          <v-row class="d-flex justify-center" v-if="!isReadOnly && isCurrentDayAWorkingDay">
            <v-tooltip bottom v-if="isCurrentDayAWorkingDay">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  x-large
                  @click="setPunchRecord"
                  :disabled="!(todayRecordsShowing || variationProposalCreating)"
                  class="mr-4"
                  color="grey lighten-1"
                  style="z-index: 6;"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-stamper</v-icon>
                </v-btn>
              </template>
              <span>Registra Orario</span>
            </v-tooltip>
            <v-tooltip bottom v-if="isCurrentDayAWorkingDay">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  x-large
                  @click="setHolidayDay"
                  :disabled="isTripDay || isOvertime"
                  class="mr-4"
                  color="cyan accent-3"
                  style="z-index: 6;"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-umbrella-beach-outline</v-icon>
                </v-btn>
              </template>
              <span>Registra Ferie</span>
            </v-tooltip>
            <v-tooltip bottom v-if="isCurrentDayAWorkingDay">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  x-large
                  @click="setAbsenceRecord"
                  class="mr-4"
                  color="teal accent-3"
                  style="z-index: 6;"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-exit-run</v-icon>
                </v-btn>
              </template>
              <span>Registra Permesso</span>
            </v-tooltip>
            <v-tooltip bottom v-if="isCurrentDayAWorkingDay">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  x-large
                  @click="setSickDay"
                  :disabled="isTripDay || isOvertime"
                  color="pink lighten-3"
                  style="z-index: 6;"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-emoticon-sick-outline</v-icon>
                </v-btn>
              </template>
              <span>Inserisci Malattia</span>
            </v-tooltip>
            
           
          </v-row>
          <v-spacer class="my-12"/>
        </v-col>
        <v-col v-else cols="3" class="d-flex justify-center" 
          v-for="workDayRecord in workDayRecords"
          :key="workDayRecord.dateIndex"
        >
          <TimeRecordCard 
            v-if="workDayRecord.recordType == 'CLOCK_RECORD'"
            :wdRecord="workDayRecord"
            :clockObject="clockObject"
            :editable="variationProposalCreating"
            v-on:undo-clock-in-out="deletingPunchRecord = true"
            v-on:edit-record-times="editTimeRecord(workDayRecord)"
            v-on:edit-record-note="editRecordNote(workDayRecord)"
            style="z-index: 6;"
          />
          <AbsenceRecordCard 
            v-if="workDayRecord.recordType == 'LEAVE_RECORD'"
            :wdRecord="workDayRecord"
            absenceType="LEAVE"
            v-on:absence-edit="editAbsenceRecord(workDayRecord)"
            v-on:absence-delete="deletingRecord(workDayRecord.dateIndex)"
            v-on:edit-record-note="editRecordNote(workDayRecord)"
            style="z-index: 6;"
            :editable="(!isFreezed && !workDayRecord.freezed && !hasVariationProposal) || variationProposalCreating"
          />
          <AbsenceRecordCard 
            v-if="workDayRecord.recordType == 'HOLIDAY_RECORD'"
            :wdRecord="workDayRecord"
            absenceType="HOLIDAY"
            v-on:absence-edit="editAbsenceRecord(workDayRecord)"
            v-on:absence-delete="deletingRecord(workDayRecord.dateIndex)"
            v-on:edit-record-note="editRecordNote(workDayRecord)"
            style="z-index: 6;"
            :editable="(!isFreezed && !workDayRecord.freezed && !hasVariationProposal) || variationProposalCreating"
          />
          <SickRecordCard 
            v-if="workDayRecord.recordType == 'SICK_RECORD'"
            :wdRecord="workDayRecord"
            v-on:delete-sick-record="deletingRecord(workDayRecord.dateIndex)"
            v-on:edit-record-note="editRecordNote(workDayRecord)"
            style="z-index: 6;"
            :editable="(!isFreezed && !workDayRecord.freezed && !hasVariationProposal) || variationProposalCreating"
          />
          <AvailabilityRecordCard
            v-if="workDayRecord.recordType == 'AVAILABILITY_RECORD'"
            :wdRecord="workDayRecord"
            v-on:delete-availability-record="deletingRecord(workDayRecord.dateIndex)"
            style="z-index: 6;"
            :editable="(!isFreezed && !workDayRecord.freezed && !hasVariationProposal) || variationProposalCreating"
          />
        </v-col>
      </v-row>
    </v-container>
    
    <v-spacer />
    
    <v-tooltip left  v-if="!isCurrentDayAWorkingDay">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          @click="uwdNotesEditing = true"
          v-bind="attrs"
          v-on="on"
          color="grey lighten-1"
          bottom
          right
          fixed
          x-large
          :disabled="isReadOnly"
        >
          <v-icon>mdi-note-text-outline</v-icon>
        </v-btn>
      </template>
      <span>Modifica Note</span>
    </v-tooltip>
    <v-speed-dial
      bottom
      right
      direction="top"
      :open-on-hover="false"
      transition="slide-y-reverse-transition"
      absolute
      fixed
      v-else
      :class="{disabled: speedDialLoading || (isReadOnly && !variationProposalCreating)}"
      v-model="addButtonDial"
      style="z-index: 6;"
    >
      <template v-slot:activator >
        <v-btn
          :color="variationProposalCreating ? 'orange lighten-2' : 'primary'"
          fab
          x-large
          :loading="speedDialLoading"
          :disabled="speedDialLoading || (isReadOnly && !variationProposalCreating) || loadingDayData"
        >
          <v-icon v-if="addButtonDial">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="orange lighten-2"
            fab
            @click="variationProposalCreating = !variationProposalCreating"
            v-if="variationProposalCreating || !hasVariationProposal"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon v-if="!variationProposalCreating">mdi-pencil</v-icon>
            <v-icon v-if="variationProposalCreating">mdi-cancel</v-icon>
          </v-btn>   
        </template>
        <span v-if="!variationProposalCreating">Crea Proposta di Modifica</span>
        <span v-if="variationProposalCreating">Annulla creazione Proposta di Modifica</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="orange lighten-2"
            fab
            :disabled="!variationProposalValidated"
            v-if="variationProposalCreating && !isReadOnly"
            @click="variationProposalSendDialog = true"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        <span>
          Invia Proposta di Modifica
        </span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            fab
            @click="uwdNotesEditing = true"
            v-bind="attrs"
            v-on="on"
            v-if="!variationProposalCreating"
          >
            <v-icon>mdi-note-text-outline</v-icon>
          </v-btn>   
        </template>
        <span>Modifica Note</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            @click="setTripDay"
            v-if="!isHolidayDay && !isSickDay && !isReadOnly && !isAvailabilityDay"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon v-if="isTripDay">mdi-bag-suitcase-off</v-icon>
            <v-icon v-else>mdi-bag-suitcase</v-icon>
          </v-btn>
        </template>
        <span v-if="isTripDay">Rimuovi Trasferta</span>
        <span v-else>Inserisci Trasferta</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            @click="setOvertime"
            v-if="!isHolidayDay && !isSickDay && !isReadOnly && !isAvailabilityDay"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-alarm</v-icon>
          </v-btn>
        </template>
        <span>Modifica Straordinario</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            @click="setAbsenceRecord"
            v-if="!isHolidayDay && !isSickDay && !isEmptyUWD && !isReadOnly && !isAvailabilityDay"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-exit-run</v-icon>
          </v-btn>
        </template>
        <span>Registra Permesso</span>
      </v-tooltip>


      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            @click="setAvailabilityDay"
            v-if="variationProposalCreating && isEmptyUWD"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-face-agent</v-icon>
          </v-btn>  
        </template>
        <span>Inserisci Turno</span>
      </v-tooltip>
      <!-- <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            @click="setSickDay"
            v-if="!(isTripDay || isOvertime || workDayRecords.length > 0) && !isEmptyUWD && !isReadOnly"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-umbrella-beach-outline</v-icon>
          </v-btn>
        </template>
        <span>Inserisci Ferie</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            @click="setSickDay"
            v-if="!(isTripDay || isOvertime || workDayRecords.length > 0) && !isEmptyUWD && !isReadOnly"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-emoticon-sick</v-icon>
          </v-btn>
        </template>
        <span>Inserisci Malattia</span>
      </v-tooltip> -->
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            large
            @click="setPunchRecord"
            v-if="canPunchInOut && !isEmptyUWD"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-stamper</v-icon>
          </v-btn>
        </template>
        <span>Registra Orario</span>
      </v-tooltip>
    </v-speed-dial>
    <v-overlay
        :value="variationProposalCreating"
        z-index="5"
        opacity="0.7"
    >
    </v-overlay>

    <ConfirmDialog
      :dialog="deletingPunchRecord" 
      :dialogText="'Annullare la Timbratura?'"
      v-on:c-dialog-confirm="undoClockInOut"
      v-on:c-dialog-cancel="deletingPunchRecord = false"
    />
    <ConfirmDialog
      :dialog="confirmDialog" 
      :dialogText="confirmDialogText"
      v-on:c-dialog-confirm="confirmDialogAction"
      v-on:c-dialog-cancel="confirmDialog = false"
    />
    <TimeRecordEditDialog
      :show="timeRecordEditDialog"
      :record="editingRecord"
      v-on:close-time-record-edit-dialog="timeRecordEditDialog = false"
      v-on:delete-time-record="deletingRecord"
      v-on:save-time-record="storeEditedTimeRecord"
      :forbiddenTimeRanges="clockRecordTimes"
      :dayRecordsDate="dayRecordsDate"
    />
    <AbsenceRecordCreateDialog
      :show="absenceRecordCreateDialog"
      v-on:close-absence-record-edit-dialog="absenceRecordCreateDialog = false"
      :workDayScheduledTimes="workDayTimes"
      :lastClockRecord="(clockRecords[clockRecords.length - 1] || {}).clockRecord"
      v-on:create-leave-record="registerLeaveRecord"
      v-on:create-holiday-record="registerHolidayRecord"
    />
    <AbsenceRecordEditDialog
      :show="absenceRecordEditDialog"
      :record="editingRecord"
      v-on:close-absence-record-edit-dialog="absenceRecordEditDialog = false"
      v-on:delete-absence-record="deletingRecord"
      v-on:save-absence-record="updatingAbsenceRecord"
    />
    <OvertimeEditDialog
      :show="overtimeEditDialog"
      :overtimeMinutes="workDayOvertimeMinutes"
      v-on:close-overtime-edit-dialog="overtimeEditDialog = false"
      v-on:save-overtime="updateOvertime"
    />
    <UwdNotesEditDialog
      :show="uwdNotesEditing"
      :notes="variationProposalCreating ? (proposedUserWorkDay || {}).notes : (userWorkDay || {}).notes"
      v-on:close-notes-edit-dialog="uwdNotesEditing = false"
      v-on:save-notes="updateUwdNotes"
    />
    <RecordNoteEditDialog
      :show="recordNoteEditDialog"
      :recordNote="editingRecord.note"
      v-on:close-record-note-edit-dialog="recordNoteEditDialog = false"
      v-on:save-record-note="updateRecordNote"
    />
    <VariationProposalCreateDialog
      :show="variationProposalSendDialog"
      :overWrite="hasVariationProposal"
      :loading="variationProposalSending"
      :note="hasVariationProposal ? userWorkDay.variationProposal.notes : ''"
      v-on:close-variation-proposal-dialog="variationProposalSendDialog = variationProposalSending = false"
      v-on:send-variation-proposal="createVariationProposal"
    />
  </v-container>
</template>

<script>
export const TimeRecordCard = () => import('./recordCards/TimeRecordCard.vue')
export const AbsenceRecordCard = () => import('./recordCards/AbsenceRecordCard.vue')
export const SickRecordCard = () => import('./recordCards/SickRecordCard.vue')
export const AvailabilityRecordCard = () => import('./recordCards/AvailabilityRecordCard.vue')

export const ConfirmDialog = () => import('../global/ConfirmDialog.vue')
export const TimeRecordEditDialog = () => import('./dialogs/TimeRecordEdit.vue')
export const AbsenceRecordCreateDialog = () => import('./dialogs/AbsenceRecordCreate.vue')
export const AbsenceRecordEditDialog = () => import('./dialogs/AbsenceRecordEdit.vue')
export const OvertimeEditDialog = () => import('./dialogs/OvertimeEdit.vue')
export const UwdNotesEditDialog = () => import('./dialogs/UwdNotesEdit.vue')
export const RecordNoteEditDialog = () => import('./dialogs/RecordNoteEdit.vue')
export const VariationProposalCreateDialog = () => import('./dialogs/VariationProposalCreate.vue')

import workTimesService from '../../services/mixins/workTimesMixin'

import userWorkDayApi from '../../api/userWorkDay'
import workDayRecordApi from '../../api/workDayRecord'
import workDayVariationProposalApi from '../../api/userWorkDayVariationProposal'

import { mapState, mapGetters, mapActions } from 'vuex'
import { DateTime } from 'luxon'

import * as currentUserActions from '../../store/actions/currentUser'
import * as dataStoragesActions from '../../store/actions/dataStorage'
import * as snackbarActions from '../../store/actions/snackbar'

export default {
  data: () => ({
    addButtonDial: false,
    speedDialLoading: false,
    deletingPunchRecord: false,
    confirmDialog: false,
    timeRecordEditDialog: false,
    absenceRecordCreateDialog: false,
    absenceRecordEditDialog: false,
    overtimeEditDialog: false,
    recordNoteEditDialog: false,
    confirmDialogText: '',
    confirmDialogAction: function() {},
    deletingRecordIndex: null,
    editingRecord: {},
    variationProposalCreating: false,
    variationProposalSendDialog: false,
    uwdNotesEditing: false,
    proposedUserWorkDay: {},
    variationProposalSending: false,
  }),
  mixins: [workTimesService],
  props: {
    userWorkDay: Object,
    clockObject: {
      type: Object,
      required: true
    },
    dayRecordsDate: {
      type: Date,
      required: true
    },
    monthWorkTimesCoverageStatus: {
      type: String,
      default: 'ok'
    },
    loadingMonthData: {
      type: Boolean,
      required: true
    },
    loadingDayData: {
      type: Boolean,
      required: true
    }
  },
  components: {
    TimeRecordCard,
    AbsenceRecordCard,
    SickRecordCard,
    AvailabilityRecordCard,
    ConfirmDialog,
    AbsenceRecordCreateDialog,
    AbsenceRecordEditDialog,
    OvertimeEditDialog,
    UwdNotesEditDialog,
    RecordNoteEditDialog,
    VariationProposalCreateDialog,
    TimeRecordEditDialog
  },
  watch: {
    variationProposalCreating: function(val) {
      if(val) {
        if(this.userWorkDay) {
          if(this.hasVariationProposal) {
            this.proposedUserWorkDay = JSON.parse(JSON.stringify(this.userWorkDay.variationProposal))
          } else {
            this.proposedUserWorkDay = JSON.parse(JSON.stringify(this.userWorkDay));
          }
        }
        else {
          this.proposedUserWorkDay = {
            workDate: this.dayRecordsDate.toISOString(),
            workDayRecords: [],
            overtimeMinutes: 0,
            businessTrip: false
          };
        }
      } else {
        this.proposedUserWorkDay = {};
      }
    }
  },
  methods: {
    ...mapActions({
      showSnackbar: snackbarActions.SNACKBAR_SHOW
    }),
    formattedTime: function(timeISO) {
      if(timeISO) {
        return DateTime.fromISO(timeISO).toLocaleString(DateTime.TIME_24_SIMPLE)
      } else {
        return null;
      }
    },
    formattedTimeExtended: function(timeISO) {
      if(timeISO) {
        return DateTime.fromISO(timeISO).toLocaleString(DateTime.DATETIME_MED)
      } else {
        return null;
      }
    },
    formattedDuration: function(minutes) {
        let otm = minutes;
        return (otm >= 60 ? Math.floor(otm/60) + ' ore ' : '') + (otm % 60 != 0 ? otm % 60 + ' minuti': '').trim();
    },
    createVariationProposal: function(variationProposalNotes) {
      this.variationProposalSending = true;
      this.proposedUserWorkDay.workDayRecords.forEach(record => {
        delete record.freezed;
        // if(record.recordType == "CLOCK_RECORD") {
        //   record.punchInTimeOffset = new Date(record.clockRecord.punchInTime).getTimezoneOffset();
        //   record.punchOutTimeOffset = new Date(record.clockRecord.punchOutTime).getTimezoneOffset();
        // }
      });

      workDayVariationProposalApi.createUserWorkDayVariationProposal(
        DateTime.fromJSDate(this.dayRecordsDate).toISODate(),
        this.proposedUserWorkDay.businessTrip,
        this.proposedUserWorkDay.overtimeMinutes,
        variationProposalNotes,
        this.proposedUserWorkDay.workDayRecords
      ).then(response => {
        if(response.success) {
          this.showSnackbar({text: "PROPOSTA DI MODIFICA CREATA CON SUCCESSO", type: "SUCCESS"});
          this.$emit('refresh-user-work-day-data');
          this.variationProposalSendDialog = false;
          this.variationProposalCreating = false;
        } else {
          this.showSnackbar({text: "CREAZIONE DELLA PROPOSTA DI MODIFICA FALLITA", type: "ERROR"});
          this.variationProposalSendDialog = false;
          this.variationProposalCreating = false;
        }
        this.variationProposalSending = false;
      });
    },
    deleteDateVariationProposal: function() {
      this.confirmDialogText = "Eliminare definitivamente la Proposta di Modifica?"
      this.confirmDialogAction = () => {
        workDayVariationProposalApi.deleteUserWorkDayVariationProposal(DateTime.fromJSDate(this.dayRecordsDate).toISODate()).then(response => {
          if(response.success) {
            this.showSnackbar({text: "PROPOSTA DI MODIFICA ELIMINATA CON SUCCESSO", type: "SUCCESS"});
            this.$emit('refresh-user-work-day-data');
            this.confirmDialog = false;
            this.variationProposalCreating = false;
          } else {
            this.showSnackbar({text: "ELIMINAZIONE DELLA PROPOSTA DI MODIFICA FALLITA", type: "ERROR"});
            this.confirmDialog = false;
            this.variationProposalCreating = false;
          }
        })
      };
      this.confirmDialog = true;
    },
    storeEditedTimeRecord: function(timeRecordObject) {
      if(this.variationProposalCreating) {
        if(timeRecordObject.dateIndex != null && timeRecordObject.dateIndex != undefined) {
          let record = this.proposedUserWorkDay.workDayRecords.find(record => record.dateIndex == timeRecordObject.dateIndex);
          record.clockRecord.punchInTime = timeRecordObject.punchInTime;
          record.clockRecord.punchOutTime = timeRecordObject.punchOutTime;
          record.clockRecord.punchInTimeOffset = new Date(timeRecordObject.punchInTime).getTimezoneOffset();
          record.clockRecord.punchOutTimeOffset = new Date(timeRecordObject.punchOutTime).getTimezoneOffset();
        } else {
          let newIndex = Math.max(...(this.proposedUserWorkDay.workDayRecords.map(record => record.dateIndex)), 0) + 1;
          this.proposedUserWorkDay.workDayRecords.push({
            dateIndex: newIndex,
            recordType: "CLOCK_RECORD",
            clockRecord: {
              punchInTime: timeRecordObject.punchInTime,
              punchOutTime: timeRecordObject.punchOutTime,
              punchInTimeOffset: new Date(timeRecordObject.punchInTime).getTimezoneOffset(),
              punchOutTimeOffset: new Date(timeRecordObject.punchOutTime).getTimezoneOffset(),
            }
          })
        }
      }
      
      this.timeRecordEditDialog = false;
      this.editingRecord = {};
    },
    emitShowCalendarEvent: function() {
      this.$emit('show-uwd-calendar');
    },
    speedDialClick: function() {
      this.addButtonDial = !this.addButtonDial;
    },
    setAbsenceRecord: function() {
      if (this.isSickDay) {
        //gestione errore
        console.log('cant create an absence record on a sick day');
      } else {
        this.absenceRecordCreateDialog = true
      }
    },
    setPunchRecord: function() {
      if(this.variationProposalCreating) {
        if(this.openPunchRecord) {
          console.log("impossibile creare una nuova timbratura quando è presente una timbratura non chiusa")
        } else if (this.isSickDay) {
          //gestione errore
          console.log('cant create a punch record on a non empty day');
        } else {
          this.editingRecord = {};
          this.timeRecordEditDialog = true;
        }
      } else {
        if (this.isSickDay) {
          //gestione errore
          console.log('cant create a punch record on a non empty day');
        } else {
          this.registerPunchInOut();
        }
      }
    },
    setSickDay: function() {
      if (this.workDayRecords.length > 0) {
        //gestione errore
        console.log('cant create a sick record on a non empty day');
      } else {
        this.registerSickRecord();
      }
    },
    setAvailabilityDay: function() {
      if (this.workDayRecords.length > 0) {
        //gestione errore
        console.log('cant create an availability record on a non empty day');
      } else {
        this.registerAvailabilityRecord();
      }
    },
    setHolidayDay: function() {
      if (this.workDayRecords.length > 0) {
        //gestione errore
        console.log('cant create an holiday record on a non empty day');
      } else {
        this.registerHolidayRecord(this.dayTimeProgressObject.scheduledTime);
      }
    },
    setOvertime: function() {
      if (this.isSickDay) {
        //gestione errore
        console.log('cant set overtime in a sick day');
      } else {
        this.overtimeEditDialog = true;
      }
    },
    setTripDay: function() {
      if (this.isSickDay) {
        //gestione errore
        console.log('cant set a sick day as trip day');
      } else {
        this.isTripDay = !this.isTripDay;
      }
    },
    registerPunchInOut: function() {
      this.speedDialLoading = true;
      workDayRecordApi.punchInOut().then(response => {
        if(response.success) {
          this.showSnackbar({text: 'TIMBRATURA REGISTRATA CON SUCCESSO', type: 'SUCCESS'});
          this.$emit('refresh-user-work-day-data');
        } else {
          this.showSnackbar({text: 'REGISTRAZIONE DELLA TIMBRATURA FALLITA', type: 'ERROR'});
        }
        this.speedDialLoading = false;
      })
    },
    registerSickRecord: function() {
      if(this.variationProposalCreating) {
        if(this.proposedUserWorkDay.workDayRecords.length == 0 && !this.isTripDay && !this.isOvertime) {
          this.proposedUserWorkDay.workDayRecords.push({
            dateIndex: 0,
            recordType: 'SICK_RECORD',
            sickRecord: {
              medicalCertificationDelivered: false
            }
          })
        } else {
          console.log("cannot register a sick record");
        }
        
      } else {
        workDayRecordApi.registerSickRecord(this.isoDayRecordsDate).then(response => {
          if(response.success) {
            this.showSnackbar({text: 'MALATTIA REGISTRATA CON SUCCESSO', type: 'SUCCESS'});
            this.$emit('refresh-user-work-day-data');
          } else {
            this.showSnackbar({text: 'REGISTRAZIONE DELLA MALATTIA FALLITA', type: 'ERROR'});
          }
        })
      }
    },
    registerAvailabilityRecord: function() {
      if(this.variationProposalCreating) {
        if(this.proposedUserWorkDay.workDayRecords.length == 0) {
          this.proposedUserWorkDay.workDayRecords.push({
            dateIndex: 0,
            recordType: 'AVAILABILITY_RECORD',
            availabilityRecord: {
              temp: null
            }
          })
        } else {
          console.log("cannot register a sick record");
        }
      } else {
        workDayRecordApi.registerAvailabilityRecord(this.isoDayRecordsDate).then(response => {
          if(response.success) {
            this.showSnackbar({text: 'REPERIBILITÀ REGISTRATA CON SUCCESSO', type: 'SUCCESS'});
            this.$emit('refresh-user-work-day-data');
          } else {
            this.showSnackbar({text: 'REGISTRAZIONE DELLA REPERIBILITÀ FALLITA', type: 'ERROR'});
          }
        })
      }
    },
    registerLeaveRecord: function(minuteDuration, apply104) {
      if(this.variationProposalCreating) {
        let newIndex = Math.max(...(this.proposedUserWorkDay.workDayRecords.map(record => record.dateIndex)), 0) + 1;
        this.proposedUserWorkDay.workDayRecords.push({
          dateIndex: newIndex,
          recordType: 'LEAVE_RECORD',
          leaveRecord: {
            minuteDuration: minuteDuration,
            apply104: apply104
          }
        })
      } else {
        workDayRecordApi.registerAbsenceRecord(this.isoDayRecordsDate, minuteDuration, 'leave', apply104).then(response => {
          if(response.success) {
            this.showSnackbar({text: 'PERMESSO REGISTRATO CON SUCCESSO', type: 'SUCCESS'});
            this.$emit('refresh-user-work-day-data');
            this.absenceRecordCreateDialog = false;
          } else {
            this.showSnackbar({text: 'REGISTRAZIONE DEL PERMESSO FALLITA', type: 'ERROR'});
            this.absenceRecordCreateDialog = false;
          }
        })
      }
    },
    registerHolidayRecord: function(minuteDuration) {
      if(this.variationProposalCreating) {
        let newIndex = Math.max(...(this.proposedUserWorkDay.workDayRecords.map(record => record.dateIndex)), 0) + 1;
        this.proposedUserWorkDay.workDayRecords.push({
          dateIndex: newIndex,
          recordType: 'HOLIDAY_RECORD',
          holidayRecord: {
            minuteDuration: minuteDuration,
            fullDay: true
          }
        })
      } else {
        workDayRecordApi.registerAbsenceRecord(this.isoDayRecordsDate, minuteDuration, 'holiday').then(response => {
          if(response.success) {
            this.showSnackbar({text: 'FERIE REGISTRATA CON SUCCESSO', type: 'SUCCESS'});
            this.$emit('refresh-user-work-day-data');
            this.absenceRecordCreateDialog = false;
          } else {
            this.showSnackbar({text: 'REGISTRAZIONE DELLA FERIE FALLITA', type: 'ERROR'});
            this.absenceRecordCreateDialog = false;
          }
        })
      }
    },
    undoClockInOut: function() {
      if(!this.variationProposalCreating) {
        workDayRecordApi.undoLastPunchInOut().then(response => {
          if(response.success) {
            this.showSnackbar({text: 'TIMBRATURA ANNULLATA CON SUCCESSO', type: 'SUCCESS'});
            this.$emit('refresh-user-work-day-data');
          } else {
            this.showSnackbar({text: 'ANNULLAMENTO DELLA TIMBRATURA FALLITO', type: 'ERROR'});
          }
          this.deletingPunchRecord = false;
        })
      }
    },
    deletingRecord: function(dateIndex) {
      this.deletingRecordIndex = dateIndex;
      this.confirmDialogText = this.deletingRecordMessage;
      this.confirmDialogAction = this.deleteWorkDayRecord;
      this.confirmDialog = true;
    },
    deleteWorkDayRecord: function() {
      if(this.variationProposalCreating) {
        this.proposedUserWorkDay.workDayRecords = this.proposedUserWorkDay.workDayRecords.filter(record => record.dateIndex != this.deletingRecordIndex);
        this.confirmDialog = false;
      } else {
        workDayRecordApi.deleteWorkDayRecord(this.isoDayRecordsDate, this.deletingRecordIndex).then(response => {
          if(response.success) {
            this.showSnackbar({text: 'RECORD ELIMINATO CON SUCCESSO', type: 'SUCCESS'});
            this.$emit('refresh-user-work-day-data');
          } else {
            this.showSnackbar({text: 'ELIMINAZIONE DEL RECORD FALLITA', type: 'ERROR'});
          }
          this.confirmDialog = false;
        })
      }
    },
    editRecordNote: function(record) {
      this.editingRecord = JSON.parse(JSON.stringify(record));
      this.recordNoteEditDialog = true;
    },
    updateRecordNote: function(updatedNote) {
      if(this.variationProposalCreating) { 
        let record = this.proposedUserWorkDay.workDayRecords.find(record => record.dateIndex == this.editingRecord.dateIndex);
        record.note = updatedNote;
      } else {
        workDayRecordApi.editRecordNote(this.isoDayRecordsDate, this.editingRecord.dateIndex, updatedNote).then(response => {
          if(response.success) {
            this.$emit('refresh-user-work-day-data');
          }
        })
      }
    },
    updateUwdNotes: function(updatedNote) {
      if(this.variationProposalCreating) { 
        this.proposedUserWorkDay.notes = updatedNote;
      } else {
        userWorkDayApi.updateUWD(DateTime.fromJSDate(this.dayRecordsDate).toISODate(), null, null, updatedNote).then(response => {
          if(response.success) {
            this.showSnackbar({text: 'NOTE AGGIORNATE CON SUCCESSO', type: 'SUCCESS'});
            this.$emit('refresh-user-work-day-data');
          } else {
            this.showSnackbar({text: 'AGGIORNAMENTO DELLE NOTE FALLITO', type: 'ERROR'});
          }
          this.speedDialLoading = false;
        })
      }
    },
    editTimeRecord: function(record) {
      this.editingRecord = JSON.parse(JSON.stringify(record));
      this.timeRecordEditDialog = true;
    },
    editAbsenceRecord: function(record) {
      this.editingRecord = JSON.parse(JSON.stringify(record));
      this.absenceRecordEditDialog = true;
    },
    updatingAbsenceRecord: function(updatePayload) {
      let updatedMinuteDuration = updatePayload.minuteDuration;
      let updatedApply104 = updatePayload.apply104;
      let updatedAbsenceKind = updatePayload.absenceKind;
      if(this.variationProposalCreating) {
        let recordIndex = this.proposedUserWorkDay.workDayRecords.findIndex(record => record.dateIndex == this.editingRecord.dateIndex);
        let record = {dateIndex: this.editingRecord.dateIndex};
        this.proposedUserWorkDay.workDayRecords.splice(recordIndex, 1);
        switch(updatePayload.absenceKind) {
        case 'leave':
            record.recordType = 'LEAVE_RECORD';
            record.leaveRecord = {
                minuteDuration: updatePayload.minuteDuration,
                apply104: updatedApply104
            }
            break;
        case 'holiday':
            record.recordType = 'HOLIDAY_RECORD';
            record.holidayRecord = {
                minuteDuration: updatePayload.minuteDuration
            }
            break;
        }
        this.proposedUserWorkDay.workDayRecords.splice(recordIndex, 0, record);
        this.absenceRecordEditDialog = false;
      } else {
        workDayRecordApi.editAbsenceRecord(this.isoDayRecordsDate, this.editingRecord.dateIndex, updatedMinuteDuration, updatedAbsenceKind, updatedApply104).then(response => {
        if(response.success) {
          this.showSnackbar({text: 'RECORD MODIFICATO CON SUCCESSO', type: 'SUCCESS'});
          this.$emit('refresh-user-work-day-data');
          this.absenceRecordEditDialog = false;
        } else {
          this.showSnackbar({text: 'MODIFICA DEL RECORD FALLITA', type: 'ERROR'});
          this.absenceRecordEditDialog = false;
        }
      })
      }
    },
    updateOvertime: function(val) {
      if(this.variationProposalCreating) {
        this.proposedUserWorkDay.overtimeMinutes = val;
      } else {
        this.speedDialLoading = true;
        userWorkDayApi.updateUWD(DateTime.fromJSDate(this.dayRecordsDate).toISODate(), null, val, null).then(response => {
          if(response.success) {
            this.showSnackbar({text: 'STRAORDINARIO AGGIORNATO CON SUCCESSO', type: 'SUCCESS'});
            this.$emit('refresh-user-work-day-data');
          } else {
            this.showSnackbar({text: 'AGGIORNAMENTO DELLO STRAORDINARIO FALLITO', type: 'ERROR'});
          }
          this.speedDialLoading = false;
        })
      }
    }
  },
  computed: {
    ...mapState({
      username: state => state.currentUser.username,
      firstName: state => state.currentUser.firstName,
      email: state => state.currentUser.email,
      // company: state => state.currentUser.company,
      // role: state => state.currentUser.role,
      // userRoles: state => state.dataStorage.userRoles
    }),
    ...mapGetters({
      getActiveUserWorkSchedule: currentUserActions.USER_GETACTIVEUWS,
      isHolidayCheck: dataStoragesActions.DATASTORAGE_ISHOLIDAYCHECK,
    }),
    isCurrentDayAWorkingDay: function() {
      return (this.morningTimesPresent || this.afternoonTimesPresent) && !this.isCurrentDateHoliday;
    },
    isCurrentDateHoliday: function() {
      return this.isHolidayCheck(this.dayRecordsDate);
    },
    hasNote: function() {
      return this.variationProposalCreating ? !!(this.proposedUserWorkDay || {}).notes : !!(this.userWorkDay || {}).notes
    },
    isReadOnly: function() {
      return (this.isFreezed || this.hasVariationProposal);
    },
    isFreezed: function() {
      return (this.userWorkDay || {}).freezed || false;
    },
    isEmptyUWD: function() {
      return this.workDayRecords.length == 0;
    },
    dayStatusObject: function() {
      if(this.hasVariationProposal) {
        return {
          color: "orange lighten-1",
          icon: "mdi-alert-decagram",
          action: () => { this.variationProposalCreating = true },
          notes: [
            "Proposta di Modifica in attesa di approvazione",
            "Creata il: " + this.formattedTimeExtended(this.userWorkDay.variationProposal.creationTime)
          ],
        }
      } else if(this.isFreezed) {
         return {
          color: "light-blue lighten-3",
          icon: "mdi-snowflake",
          notes: [
            "Orario in Consolidamento"
          ],
          action: () => {}
        }
      } else {
         return {
          color: this.dayTimeProgressObject.complete ? 'green lighten-2' : 'grey darken-1',
          icon: "mdi-clock",
          notes: [
            "Tempo di lavoro pianificato: " + this.formattedDuration(this.dayTimeProgressObject.scheduledTime),
            "Tempo di lavoro registrato: " + this.formattedDuration(this.dayTimeProgressObject.registeredTime)
          ],
          action: () => {}
        }
      }
    },
    hasVariationProposal: function() {
      return ((this.userWorkDay || {}).variationProposal || {}).creationTime != null;
    },
    variationProposalValidated: function() {
      // let result = this.validateProposedUserWorkDay(this.proposedUserWorkDay);
      // //console.log(result);
      // return result.isValid;
      return this.workDaySummary.isValid;
    },
    dayTimeProgressObject: function() {
      let percentage = Math.min(Math.floor((((this.isTripDay ? this.workDaySummary.clockMinutes : this.workDaySummary.registeredMinutes) + this.workDaySummary.absenceMinutes) / this.workDaySummary.scheduledMinutes) * 100), 100);
      return {
        progressPercentage: percentage,
        registeredTime: (this.isTripDay ? this.workDaySummary.clockMinutes : this.workDaySummary.registeredMinutes) + this.workDaySummary.absenceMinutes,
        scheduledTime: this.workDaySummary.scheduledMinutes,
        complete: percentage == 100 || this.isSickDay || this.isAvailabilityDay,
      };
    },
    workDaySummary: function() {
      let summary = this.userWorkDaySummary(this.workDayRecords, this.workDayTimes, this.isoDayRecordsDate, this.isTripDay);
      return summary;
    },
    deletingRecordMessage: function() {
      let record = this.workDayRecords.find(record => record.dateIndex == this.deletingRecordIndex) || {};
      switch(record.recordType) {
        case 'LEAVE_RECORD': 
          return 'Eliminare il Permesso?';
        case 'HOLIDAY_RECORD': 
          return 'Eliminare le Ferie?';
        case 'SICK_RECORD': 
          return 'Eliminare la Malattia?';
        case 'AVAILABILITY_RECORD': 
          return 'Eliminare la Reperibilità?';
        case 'CLOCK_RECORD': 
          return 'Eliminare la Timbratura?';
        default:
          return '';
      }
    },
    isoDayRecordsDate: function() {
      return DateTime.fromJSDate(this.dayRecordsDate).toISODate();
    },
    activeUserWorkSchedule: function() {
      return this.getActiveUserWorkSchedule(this.dayRecordsDate);
    },
    workDayTimes: function() {
      if(this.activeUserWorkSchedule.scheduledWeekWorkTimes == null)
        return {};
      switch(this.dayRecordsDate.getDay()) {
        case 1:
          return this.activeUserWorkSchedule.scheduledWeekWorkTimes.mondayWorkTimes;
        case 2:
          return this.activeUserWorkSchedule.scheduledWeekWorkTimes.tuesdayWorkTimes;
        case 3:
          return this.activeUserWorkSchedule.scheduledWeekWorkTimes.wednesdayWorkTimes;
        case 4:
          return this.activeUserWorkSchedule.scheduledWeekWorkTimes.thursdayWorkTimes;
        case 5:
          return this.activeUserWorkSchedule.scheduledWeekWorkTimes.fridayWorkTimes;
        case 6:
          return this.activeUserWorkSchedule.scheduledWeekWorkTimes.saturdayWorkTimes;
        case 0:
          return this.activeUserWorkSchedule.scheduledWeekWorkTimes.sundayWorkTimes;
        default: return {};
      }
    },
    morningTimesPresent: function() {
      return this.workDayTimes.morningTimes && !!this.workDayTimes.morningTimes.clockInTime && !!this.workDayTimes.morningTimes.clockOutTime;
    },
    afternoonTimesPresent: function() {
      return this.workDayTimes.afternoonTimes && !!this.workDayTimes.afternoonTimes.clockInTime && !!this.workDayTimes.afternoonTimes.clockOutTime;
    },
    workDayObject: function() {
      if(this.variationProposalCreating) {
          return this.proposedUserWorkDay;
      } else {
          return this.userWorkDay || {};
      } 
    },
    workDayRecords: function() {
      if(this.variationProposalCreating) {
        if (this.proposedUserWorkDay) {
          return this.proposedUserWorkDay.workDayRecords || [];
        } else {
          return [];
        }
      } else {
        if (this.userWorkDay) {
          return this.userWorkDay.workDayRecords || [];
        } else {
          return [];
        }
      } 
    },
    representDisplayDate: function() {
      let repDay = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'][this.dayRecordsDate.getDay()]
      let repMonth = 
        ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 
        'Maggio', 'Giugno', 'Luglio', 'Agosto', 
        'Settembre', 'Ottobre', 'Novembre', 'Dicembre'][this.dayRecordsDate.getMonth()]
      return {
        day: repDay,
        month: repMonth,
        date: this.dayRecordsDate.getDate()
      }
    },
    clockRecords: function() {
      return this.workDayRecords.filter(wdr => wdr.recordType == 'CLOCK_RECORD')
    },
    clockRecordTimes: function() {
      return this.clockRecords.map(record => {
        return {
          punchInTime: record.clockRecord.punchInTime,
          punchOutTime: record.clockRecord.punchOutTime,
          dateIndex: record.dateIndex,
        }
      });
    },
    lastPunchTime: function() {
      return DateTime.max(...this.clockRecordTimes.map(crt => crt.punchOutTime || crt.punchInTime).map(pt => DateTime.fromISO(pt)));
    },
    canPunchInOut: function() {
      // (todayRecordsShowing || variationProposalCreating) && !isHolidayDay && !isSickDay && !isEmptyUWD && !isReadOnly && canPunchInOut
      if(this.variationProposalCreating) {
        if(this.isHolidayDay || this.isSickDay || this.isAvailabilityDay)
          return false;

        const lastCR = this.clockRecords.slice(-1)[0]
        if(lastCR) {
          if(lastCR.clockRecord.punchOutTime) {
            return true
          } else {
            return false
          }
        } else {
          return true;
        }

      } else {
        if(!this.todayRecordsShowing || this.isHolidayDay || this.isSickDay || this.isReadOnly || this.isAvailabilityDay)
          return false;
        if(this.lastPunchTime)
          return this.clockObject > this.lastPunchTime;
        return true;
      }
      
    },
    openPunchRecordIndex: function() {
      return (this.openPunchRecord || {}).dateIndex;
    },
    openPunchRecord: function() {
      return this.clockRecords.find(record => !record.clockRecord.punchOutTime)
    },
    todayRecordsShowing: function() {
      if(this.clockObject)
        return DateTime.fromJSDate(this.dayRecordsDate).toLocaleString(DateTime.DATE_SHORT) == this.clockObject.toLocaleString(DateTime.DATE_SHORT)
      return false;
    },
    isTripDay: {
      get: function() {
        if(this.variationProposalCreating) {
          return (this.proposedUserWorkDay || {}).businessTrip;
        } else {
          return (this.userWorkDay || {}).businessTrip;
        }
      },
      set: function(val) {
        if(this.variationProposalCreating) {
          this.proposedUserWorkDay.businessTrip = val;
        } else {
          this.speedDialLoading = true;
          userWorkDayApi.updateUWD(DateTime.fromJSDate(this.dayRecordsDate).toISODate(), val, null, null).then(response => {
            if(response.success) {
              this.showSnackbar({text: 'TRASFERTA AGGIORNATA CON SUCCESSO', type: 'SUCCESS'});
              this.$emit('refresh-user-work-day-data');
            } else {
              this.showSnackbar({text: 'AGGIORNAMENTO TRASFERTA FALLITO', type: 'ERROR'});
            }
            this.speedDialLoading = false;
          })
        }
      }
    },
    workDayOvertimeMinutes: function() {
      if(this.variationProposalCreating) 
        return (this.proposedUserWorkDay || {}).overtimeMinutes || 0;
      else 
        return (this.userWorkDay || {}).overtimeMinutes || 0;
    },
    isOvertime: function() {
      return this.workDayOvertimeMinutes > 0;
    },
    overtimeString: function() {
      let otm = this.workDayOvertimeMinutes;
      return (otm >= 60 ? Math.floor(otm/60) + 'h ' : '') + (otm % 60 != 0 ? otm % 60 + 'm': '').trim();
      // if(otm >= 60) {
      //   return Math.floor(otm/60) + 'o ' + otm%60 + 'm'; 
      // } else {
      //   return otm + 'm';
      // }
    },
    // dayRecords: function() {
    //   return (this.userWorkDay || {}).workDayRecords || [];
    // },
    isSickDay: function() {
        return this.workDayRecords.find(record => record.recordType == 'SICK_RECORD')
    },
    isHolidayDay: function() {
        return this.workDayRecords.find(record => {
          return record.recordType == 'HOLIDAY_RECORD' && record.holidayRecord && record.holidayRecord.fullDay
        }) != null;
    },
    isAvailabilityDay: function() {
        return this.workDayRecords.find(record => record.recordType == 'AVAILABILITY_RECORD') != null;
    },
  }
}
</script>
<style>
  /* This is for documentation purposes and will not be needed in your application */
  #calendarIcon {
    position: absolute;
  }
  .disabled {
    pointer-events: none;
  }
</style>