import { mapState, mapGetters } from 'vuex';
import { DateTime } from 'luxon';

import * as currentUserActions from '../../store/actions/currentUser'
import * as dataStoragesActions from '../../store/actions/dataStorage'

export default {
    computed: {
        ...mapGetters({
            getActiveUserWorkScheduleMethod: currentUserActions.USER_GETACTIVEUWS,
            isHolidayCheck: dataStoragesActions.DATASTORAGE_ISHOLIDAYCHECK,
        }),
        ...mapState({
            savedUserWorkDays: state => state.dataStorage.calendarUserWorkDays,
            localYearsHolidays: state => state.dataStorage.localYearsHolidays
        }),
    },
    methods: {
        dayUncoveredTime: function(jsDate) { //!! da usare solo su uno dei giorni in calendario (senno non ho caricato i dati)
            // problema di gestione delle timezones
            let uws = this.getActiveUserWorkScheduleMethod(jsDate);
            let isHoliday = this.isHolidayCheck(jsDate);

            if(!uws || !uws.scheduledWeekWorkTimes || isHoliday) {
                return null;
            }
            let wrappedDate = DateTime.fromJSDate(jsDate);
            let startOfDate = wrappedDate.startOf('day');
            let endOfDate = wrappedDate.endOf('day');
            let dateObject = {
                day: wrappedDate.day,
                month: wrappedDate.month,
                year: wrappedDate.year
            };
            let weekDay = wrappedDate.weekday;
            let weekDayKey = ['mondayWorkTimes', 'tuesdayWorkTimes', 'wednesdayWorkTimes', 'thursdayWorkTimes', 'fridayWorkTimes', 'saturdayWorkTimes', 'sundayWorkTimes'][weekDay - 1];
            
            let absenceMinutes = 0;
            let registeredMinutes = 0;
            let unregisteredMinutes = 0;
    
            let selectedUwd = this.savedUserWorkDays.find(uwd => {
                let uwdDate = DateTime.fromISO(uwd.workDate);
                return (uwdDate.day == startOfDate.day && uwdDate.month == startOfDate.month && uwdDate.year == startOfDate.year)
            });

            const isTripDay = selectedUwd?.businessTrip;
            let dateScheduledTimes = uws.scheduledWeekWorkTimes[weekDayKey];

            let wrappedMorningScheduledIn = dateScheduledTimes.morningTimes.clockInTime ? DateTime.fromISO(dateScheduledTimes.morningTimes.clockInTime) : null;
            let wrappedMorningScheduledOut = dateScheduledTimes.morningTimes.clockOutTime ? DateTime.fromISO(dateScheduledTimes.morningTimes.clockOutTime) : null;
            let wrappedAfternoonScheduledIn = dateScheduledTimes.afternoonTimes.clockInTime ? DateTime.fromISO(dateScheduledTimes.afternoonTimes.clockInTime) : null;
            let wrappedAfternoonScheduledOut = dateScheduledTimes.afternoonTimes.clockOutTime ? DateTime.fromISO(dateScheduledTimes.afternoonTimes.clockOutTime) : null;

            if(wrappedMorningScheduledIn && wrappedMorningScheduledOut && wrappedMorningScheduledIn.isValid && wrappedMorningScheduledOut.isValid) {
                unregisteredMinutes += wrappedMorningScheduledOut.diff(wrappedMorningScheduledIn, ['minutes']).minutes;

                wrappedMorningScheduledIn = wrappedMorningScheduledIn.set(dateObject);
                wrappedMorningScheduledOut = wrappedMorningScheduledOut.set(dateObject); 
            } else {
                wrappedMorningScheduledIn = null;
                wrappedMorningScheduledOut = null;
            }
            if(wrappedAfternoonScheduledIn && wrappedAfternoonScheduledOut && wrappedAfternoonScheduledIn.isValid && wrappedAfternoonScheduledOut.isValid) {
                unregisteredMinutes += wrappedAfternoonScheduledOut.diff(wrappedAfternoonScheduledIn, ['minutes']).minutes;

                wrappedAfternoonScheduledIn = wrappedAfternoonScheduledIn.set(dateObject);
                wrappedAfternoonScheduledOut = wrappedAfternoonScheduledOut.set(dateObject);
            } else {
                wrappedAfternoonScheduledIn = null;
                wrappedAfternoonScheduledOut = null;
            }

            if (wrappedMorningScheduledIn == null && wrappedMorningScheduledOut == null && wrappedAfternoonScheduledIn == null && wrappedAfternoonScheduledOut == null)
                return null;

            let loopArray = (selectedUwd || {}).workDayRecords || [];

            for(let i = 0; i < loopArray.length; i++) {
                let wdr = loopArray[i];
                if(wdr.recordType == 'LEAVE_RECORD') {
                    absenceMinutes += wdr.leaveRecord.minuteDuration;
                }
                if(wdr.recordType == 'HOLIDAY_RECORD') {
                    absenceMinutes += wdr.holidayRecord.minuteDuration;
                }
                if(wdr.recordType == 'CLOCK_RECORD') {
                    let clockRecord = wdr.clockRecord;
                    if(clockRecord.punchInTime && clockRecord.punchOutTime) {
                        
                        let wrappedInTime = DateTime.fromISO(clockRecord.punchInTime).set({seconds: 0, milliseconds: 0});
                        let wrappedOutTime = DateTime.fromISO(clockRecord.punchOutTime).set({seconds: 0, milliseconds: 0});

                        if(isTripDay) {
                            registeredMinutes += wrappedOutTime.diff(wrappedInTime, ['minutes']).minutes;
                        } else {
                            if(wrappedMorningScheduledOut && wrappedInTime < wrappedMorningScheduledOut) {
                                if (wrappedInTime < wrappedMorningScheduledIn)
                                    wrappedInTime = wrappedMorningScheduledIn;
                                if (wrappedOutTime > endOfDate)
                                    wrappedOutTime = endOfDate;
                                registeredMinutes += Math.max(wrappedOutTime.diff(wrappedInTime, ['minutes']).minutes, 0);
                            } else if(wrappedAfternoonScheduledOut && wrappedInTime < wrappedAfternoonScheduledOut){
                                if (wrappedInTime < wrappedAfternoonScheduledIn)
                                    wrappedInTime = wrappedAfternoonScheduledIn;
                                if (wrappedOutTime > endOfDate)
                                    wrappedOutTime = endOfDate;
                                registeredMinutes += Math.max(wrappedOutTime.diff(wrappedInTime, ['minutes']).minutes, 0);
                            }
                        }


                        
                    } 
                }
                if(wdr.recordType == 'SICK_RECORD' || wdr.recordType == 'AVAILABILITY_RECORD') {
                    // no need to have registered times
                    return 0;
                }
            }
            console.log(jsDate, registeredMinutes, absenceMinutes, unregisteredMinutes)
            return Math.max(unregisteredMinutes - (registeredMinutes + absenceMinutes), 0);
        },

        validateProposedUserWorkDay: function(proposedUserWorkDay) {
            let parsedDate = new Date(proposedUserWorkDay.workDate);
            let uws = this.getActiveUserWorkScheduleMethod(parsedDate);
            let isHoliday = this.isHolidayCheck(parsedDate);

            //console.log('....', uws, Date.parse(proposedUserWorkDay.workDate), proposedUserWorkDay.workDate)

            let wrappedDate = DateTime.fromISO(proposedUserWorkDay.workDate);
            let startOfDate = wrappedDate.startOf('day');
            let endOfDate = wrappedDate.endOf('day');
            let dateObject = {
                day: wrappedDate.day,
                month: wrappedDate.month,
                year: wrappedDate.year
            };
            let weekDay = wrappedDate.weekday;
            let weekDayKey = ['mondayWorkTimes', 'tuesdayWorkTimes', 'wednesdayWorkTimes', 'thursdayWorkTimes', 'fridayWorkTimes', 'saturdayWorkTimes', 'sundayWorkTimes'][weekDay - 1];
            
            let absenceMinutes = 0;
            let leaveMinutes = 0;
            let holidayMinutes = 0;
            let registeredMinutes = 0;
            let unregisteredMinutes = 0;

            let scheduledTimePresent = true;
            let sickDay = false;
            let errors = [];

            if(!uws || !uws.scheduledWeekWorkTimes || !uws.scheduledWeekWorkTimes[weekDayKey] || 
                (
                    !uws.scheduledWeekWorkTimes[weekDayKey].morningTimes &&
                    !uws.scheduledWeekWorkTimes[weekDayKey].afternoonTimes
                ) ||
                (
                    !uws.scheduledWeekWorkTimes[weekDayKey].morningTimes.clockInTime &&
                    !uws.scheduledWeekWorkTimes[weekDayKey].morningTimes.clockOutTime &&
                    !uws.scheduledWeekWorkTimes[weekDayKey].afternoonTimes.clockInTime &&
                    !uws.scheduledWeekWorkTimes[weekDayKey].afternoonTimes.clockOutTime
                ) || isHoliday
            ) {
                scheduledTimePresent = false;

                let loopArray = ((proposedUserWorkDay || {}).workDayRecords || []).filter(wdr => wdr.recordType == 'CLOCK_RECORD');

                for(let i = 0; i < loopArray.length; i++) {
                    let wdr = loopArray[i];
                    let clockRecord = wdr.clockRecord;
                    if(clockRecord.punchInTime && clockRecord.punchOutTime) {
                        
                        let wrappedInTime = DateTime.fromISO(clockRecord.punchInTime).set({seconds: 0, milliseconds: 0});
                        let wrappedOutTime = DateTime.fromISO(clockRecord.punchOutTime).set({seconds: 0, milliseconds: 0});

                        registeredMinutes += wrappedOutTime.diff(wrappedInTime, ['minutes']).minutes;
                    } 
                }
            } else {
                let dateScheduledTimes = uws.scheduledWeekWorkTimes[weekDayKey];

                let wrappedMorningScheduledIn = dateScheduledTimes.morningTimes.clockInTime ? DateTime.fromISO(dateScheduledTimes.morningTimes.clockInTime) : null;
                let wrappedMorningScheduledOut = dateScheduledTimes.morningTimes.clockOutTime ? DateTime.fromISO(dateScheduledTimes.morningTimes.clockOutTime) : null;
                let wrappedAfternoonScheduledIn = dateScheduledTimes.afternoonTimes.clockInTime ? DateTime.fromISO(dateScheduledTimes.afternoonTimes.clockInTime) : null;
                let wrappedAfternoonScheduledOut = dateScheduledTimes.afternoonTimes.clockOutTime ? DateTime.fromISO(dateScheduledTimes.afternoonTimes.clockOutTime) : null;

                if(wrappedMorningScheduledIn && wrappedMorningScheduledOut && wrappedMorningScheduledIn.isValid && wrappedMorningScheduledOut.isValid) {
                    unregisteredMinutes += wrappedMorningScheduledOut.diff(wrappedMorningScheduledIn, ['minutes']).minutes;

                    wrappedMorningScheduledIn = wrappedMorningScheduledIn.set(dateObject);
                    wrappedMorningScheduledOut = wrappedMorningScheduledOut.set(dateObject);
                } else {
                    wrappedMorningScheduledIn = null;
                    wrappedMorningScheduledOut = null;
                }
                if(wrappedAfternoonScheduledIn && wrappedAfternoonScheduledOut && wrappedAfternoonScheduledIn.isValid && wrappedAfternoonScheduledOut.isValid) {
                    unregisteredMinutes += wrappedAfternoonScheduledOut.diff(wrappedAfternoonScheduledIn, ['minutes']).minutes;

                    wrappedAfternoonScheduledIn = wrappedAfternoonScheduledIn.set(dateObject);
                    wrappedAfternoonScheduledOut = wrappedAfternoonScheduledOut.set(dateObject);
                } else {
                    wrappedAfternoonScheduledIn = null;
                    wrappedAfternoonScheduledOut = null;
                }

                // if (wrappedMorningScheduledIn == null && wrappedMorningScheduledOut == null && wrappedAfternoonScheduledIn == null && wrappedAfternoonScheduledOut == null)
                //     return {
                //         scheduledTimePresent: false,
                //     };

                let loopArray = ((proposedUserWorkDay || {}).workDayRecords || []).filter(wdr => wdr.recordType == 'CLOCK_RECORD');

                for(let i = 0; i < loopArray.length; i++) {
                    let wdr = loopArray[i];
                    // if(wdr.recordType == 'LEAVE_RECORD') {
                    //     absenceMinutes += wdr.leaveRecord.minuteDuration;
                    // }
                    // if(wdr.recordType == 'HOLIDAY_RECORD') {
                    //     absenceMinutes += wdr.holidayRecord.minuteDuration;
                    // }
                    // if(wdr.recordType == 'CLOCK_RECORD') {
                        let clockRecord = wdr.clockRecord;
                        if(clockRecord.punchInTime && clockRecord.punchOutTime) {
                            
                            let wrappedInTime = DateTime.fromISO(clockRecord.punchInTime).set({seconds: 0, milliseconds: 0});
                            let wrappedOutTime = DateTime.fromISO(clockRecord.punchOutTime).set({seconds: 0, milliseconds: 0});


                            if(wrappedMorningScheduledOut && wrappedInTime < wrappedMorningScheduledOut) {
                                if (wrappedInTime < wrappedMorningScheduledIn)
                                    wrappedInTime = wrappedMorningScheduledIn;
                                if (wrappedOutTime > endOfDate)
                                    wrappedOutTime = endOfDate;
                                registeredMinutes += Math.max(wrappedOutTime.diff(wrappedInTime, ['minutes']).minutes, 0);
                            } else if(wrappedAfternoonScheduledOut && wrappedInTime < wrappedAfternoonScheduledOut){
                                if (wrappedInTime < wrappedAfternoonScheduledIn)
                                    wrappedInTime = wrappedAfternoonScheduledIn;
                                if (wrappedOutTime > endOfDate)
                                    wrappedOutTime = endOfDate;
                                registeredMinutes += Math.max(wrappedOutTime.diff(wrappedInTime, ['minutes']).minutes, 0);
                            }
                        } else {
                            errors.push('non-closed-clock-record');
                        }
                    // }
                    // if(wdr.recordType == 'SICK_RECORD') {
                    //     // no need to have registered times
                    //     return 0;
                    // }
                }
            }
    
            let loopArray = ((proposedUserWorkDay || {}).workDayRecords || []).filter(wdr => wdr.recordType != 'CLOCK_RECORD');

            for(let i = 0; i < loopArray.length; i++) {
                let wdr = loopArray[i];
                if(wdr.recordType == 'LEAVE_RECORD') {
                    leaveMinutes += wdr.leaveRecord.minuteDuration;
                }
                if(wdr.recordType == 'HOLIDAY_RECORD') {
                    holidayMinutes += wdr.holidayRecord.minuteDuration;
                }
                if(wdr.recordType == 'SICK_RECORD') {
                    // no need to have registered times
                    sickDay = true;
                }
            }
            absenceMinutes = holidayMinutes = leaveMinutes;

            if(!sickDay && unregisteredMinutes - (registeredMinutes + absenceMinutes) > 0) {
                errors.push('scheduled-times-not-covered');
            }
        
            return {
                scheduledMinutes: unregisteredMinutes,
                registeredMinutes: registeredMinutes,
                holidayMinutes: holidayMinutes,
                leaveMinutes: leaveMinutes,
                absenceMinutes: absenceMinutes,
                scheduledTimePresent: scheduledTimePresent,
                sickDay: sickDay,
                errors: errors,
                isValid: errors.length == 0
            };
            
        },

        userWorkDaySummary: function(workDayRecords, userWorkDayTimeSchedule, workDate, isTripDay = false) {
            let absenceMinutes = 0;
            let leaveMinutes = 0;
            let holidayMinutes = 0;
            let clockMinutes = 0;
            let registeredMinutes = 0;
            let unregisteredMinutes = 0;

            let wrappedDate = DateTime.fromISO(workDate);
            //let startOfDate = wrappedDate.startOf('day');
            let endOfDate = wrappedDate.endOf('day');
            let dateObject = {
                day: wrappedDate.day,
                month: wrappedDate.month,
                year: wrappedDate.year
            };
            let isHoliday = this.isHolidayCheck(new Date(workDate));

            let scheduledTimePresent = !isHoliday && !!userWorkDayTimeSchedule &&
            (
                (!!userWorkDayTimeSchedule.morningTimes && !!userWorkDayTimeSchedule.morningTimes.clockInTime && !!userWorkDayTimeSchedule.morningTimes.clockOutTime) ||
                (!!userWorkDayTimeSchedule.afternoonTimes && !!userWorkDayTimeSchedule.afternoonTimes.clockInTime && !!userWorkDayTimeSchedule.afternoonTimes.clockOutTime)
            );

            let sickDay = false;
            let availabilityDay = false;
            let errors = [];

            let wrappedMorningScheduledIn = null;
            let wrappedMorningScheduledOut = null;
            let wrappedAfternoonScheduledIn = null;
            let wrappedAfternoonScheduledOut = null;

            if(scheduledTimePresent) {
                wrappedMorningScheduledIn = userWorkDayTimeSchedule.morningTimes.clockInTime ? DateTime.fromISO(userWorkDayTimeSchedule.morningTimes.clockInTime) : null;
                wrappedMorningScheduledOut = userWorkDayTimeSchedule.morningTimes.clockOutTime ? DateTime.fromISO(userWorkDayTimeSchedule.morningTimes.clockOutTime) : null;
                wrappedAfternoonScheduledIn = userWorkDayTimeSchedule.afternoonTimes.clockInTime ? DateTime.fromISO(userWorkDayTimeSchedule.afternoonTimes.clockInTime) : null;
                wrappedAfternoonScheduledOut = userWorkDayTimeSchedule.afternoonTimes.clockOutTime ? DateTime.fromISO(userWorkDayTimeSchedule.afternoonTimes.clockOutTime) : null;
                if(wrappedMorningScheduledIn && wrappedMorningScheduledOut && wrappedMorningScheduledIn.isValid && wrappedMorningScheduledOut.isValid) {
                    unregisteredMinutes += wrappedMorningScheduledOut.diff(wrappedMorningScheduledIn, ['minutes']).minutes;

                    wrappedMorningScheduledIn = wrappedMorningScheduledIn.set(dateObject);
                    wrappedMorningScheduledOut = wrappedMorningScheduledOut.set(dateObject);
                } else {
                    wrappedMorningScheduledIn = null;
                    wrappedMorningScheduledOut = null;
                }
                if(wrappedAfternoonScheduledIn && wrappedAfternoonScheduledOut && wrappedAfternoonScheduledIn.isValid && wrappedAfternoonScheduledOut.isValid) {
                    unregisteredMinutes += wrappedAfternoonScheduledOut.diff(wrappedAfternoonScheduledIn, ['minutes']).minutes;

                    wrappedAfternoonScheduledIn = wrappedAfternoonScheduledIn.set(dateObject);
                    wrappedAfternoonScheduledOut = wrappedAfternoonScheduledOut.set(dateObject);
                } else {
                    wrappedAfternoonScheduledIn = null;
                    wrappedAfternoonScheduledOut = null;
                }

            }

            let loopArray = (workDayRecords || []).filter(wdr => wdr.recordType == 'CLOCK_RECORD');

            for(let i = 0; i < loopArray.length; i++) {
                let wdr = loopArray[i];
                let clockRecord = wdr.clockRecord;
                if(clockRecord.punchInTime && clockRecord.punchOutTime) {
                    
                    let wrappedInTime = DateTime.fromISO(clockRecord.punchInTime).set({seconds: 0, milliseconds: 0});
                    let wrappedOutTime = DateTime.fromISO(clockRecord.punchOutTime).set({seconds: 0, milliseconds: 0});

                    clockMinutes += wrappedOutTime.diff(wrappedInTime, ['minutes']).minutes;

                    if(scheduledTimePresent) {
                        if(wrappedMorningScheduledOut && wrappedInTime < wrappedMorningScheduledOut) {
                            if (wrappedInTime < wrappedMorningScheduledIn)
                                wrappedInTime = wrappedMorningScheduledIn;
                            if (wrappedOutTime > endOfDate)
                                wrappedOutTime = endOfDate;
                            registeredMinutes += Math.max(wrappedOutTime.diff(wrappedInTime, ['minutes']).minutes, 0);
                        } else if(wrappedAfternoonScheduledOut && wrappedInTime < wrappedAfternoonScheduledOut){
                            if (wrappedInTime < wrappedAfternoonScheduledIn)
                                wrappedInTime = wrappedAfternoonScheduledIn;
                            if (wrappedOutTime > endOfDate)
                                wrappedOutTime = endOfDate;
                            registeredMinutes += Math.max(wrappedOutTime.diff(wrappedInTime, ['minutes']).minutes, 0);
                        }
                    }
                } 
                // else {
                //     errors.push('non-closed-clock-record');
                // }
            }
    
            loopArray = (workDayRecords || []).filter(wdr => wdr.recordType != 'CLOCK_RECORD');

            for(let i = 0; i < loopArray.length; i++) {
                let wdr = loopArray[i];
                if(wdr.recordType == 'LEAVE_RECORD') {
                    leaveMinutes += wdr.leaveRecord.minuteDuration;
                }
                if(wdr.recordType == 'HOLIDAY_RECORD') {
                    holidayMinutes += wdr.holidayRecord.minuteDuration;
                }
                if(wdr.recordType == 'SICK_RECORD') {
                    // no need to have registered times
                    sickDay = true;
                }
                if(wdr.recordType == 'AVAILABILITY_RECORD') {
                    availabilityDay = true;
                }
            }
            absenceMinutes = holidayMinutes + leaveMinutes;

            if(!sickDay && !availabilityDay && unregisteredMinutes - ((isTripDay ? clockMinutes : registeredMinutes) + absenceMinutes) > 0) {
                errors.push('scheduled-times-not-covered');
            }
            // if(!scheduledTimePresent) {
            //     errors.push('scheduled-times-not-present')
            // }
        
            return {
                scheduledMinutes: unregisteredMinutes,
                registeredMinutes: registeredMinutes,
                holidayMinutes: holidayMinutes,
                leaveMinutes: leaveMinutes,
                absenceMinutes: absenceMinutes,
                clockMinutes: clockMinutes,
                scheduledTimePresent: scheduledTimePresent,
                sickDay: sickDay,
                availabilityDay: availabilityDay,
                errors: errors,
                isValid: errors.length == 0
            };
            
        }
    }
}