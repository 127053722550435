export const getCurrentUser = {
    query: `
        query
        {
            currentUser
            {
                username
                email
                firstName
                lastName
                disabled
                role
                company {
                    companyName
                }
                department {
                    departmentName
                }
                userWorkSchedules {
                    baseWorkScheduleDummy {
                        workScheduleDummyName
                    }
                    validityStartDate
                    validityStopDate
                    scheduledWeekWorkTimes{
                        mondayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        tuesdayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        wednesdayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        thursdayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        fridayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        saturdayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        sundayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                    }
                }
            }
        }
    `,
    dataName: 'currentUser'

}

export const getUsers = {
    query: `
        query
        {
            users
            {
                username
                email
                firstName
                lastName
                disabled
                includeInReports
                role
                company {
                    companyName
                }
                department {
                    departmentName
                }
                userWorkSchedules {
                    baseWorkScheduleDummy {
                        workScheduleDummyName
                    }
                    validityStartDate
                    validityStopDate
                    scheduledWeekWorkTimes{
                        mondayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        tuesdayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        wednesdayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        thursdayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        fridayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        saturdayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        sundayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                    }
                }
            }
        }
    `,
    dataName: 'users'

}

export const createUser = {
    query: `
        mutation($user:PostUserInput!, $userCompanyName:String!)
        {
            createUser(user: $user, userCompanyName: $userCompanyName) 
            {
                username
                email
                firstName
                lastName
                disabled
                includeInReports
                role
                company {
                    companyName
                }
            }
        }
    `,
    dataName: 'createUser'

} 

export const updateUser = {
    query: `
    mutation($username:String!, $updatedUser:FetchUserInput!, $updatedCompanyName:String)
    {
        updateUser(
            username: $username
            updatedUser: $updatedUser,
            updatedUserCompanyName: $updatedCompanyName
        ) {
            username
            email
            firstName
            lastName
            disabled
            includeInReports
            role
            company {
                companyName
            }
        }
    }
    `,
    dataName: 'updateUser'

} 

export const deleteUser = {
    query: `
        mutation($username:String!)
        {
            deleteUser(username: $username) 
        }
    `,
    dataName: 'deleteUser'

} 

export const disableUser = {
    query: `
        mutation($username:String!)
        {
            disableUser(username: $username) 
        }
    `,
    dataName: 'disableUser'

} 

export const enableUser = {
    query: `
        mutation($username:String!)
        {
            enableUser(username: $username) 
        }
    `,
    dataName: 'enableUser'

} 