import axios from 'axios';
import router from '../router'

const instance = axios.create({
    //baseURL: `http://${process.env.VUE_APP_GRAPHQL_SERVER_ADDRESS}:${process.env.VUE_APP_GRAPHQL_SERVER_PORT}`,
    headers: {
        "content-type": "application/json",
        "Accept": "application/json"
    },
    timeout: 1200000
});

const responseInterceptor = function (instance) {
    instance.interceptors.response.use(response => response, error => {
        if (error.response.status === 401) {
            if(router.currentRoute.name != 'Login')
                router.push({ name: 'Login' });
            return error;
        }
        if (error.response.status === 500) {
            if(router.currentRoute.name != 'InternalError')
                router.push({ name: 'InternalError' });
            return error;
        }
        else throw error.response.data; //gestione
    })
}

responseInterceptor(instance);

export default instance;