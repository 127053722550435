export const authCheck = {
    query: `
        query{
            needToLogin
        }
    `,
    dataName: null
}   

export const loginUser = {
    query: `
        mutation($username:String!, $password:String!)
        {
            loginUser(username: $username, password: $password) 
            {
                username
                email
                firstName
                lastName
                disabled
                company {
                    companyName
                }
                userWorkSchedules {
                    baseWorkScheduleDummy {
                        workScheduleDummyName
                    }
                    validityStartDate
                    validityStopDate
                    scheduledWeekWorkTimes{
                        mondayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        tuesdayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        wednesdayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        thursdayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        fridayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        saturdayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                        sundayWorkTimes{
                            morningTimes{
                                clockInTime
                                clockOutTime
                            }
                            afternoonTimes{
                                clockInTime
                                clockOutTime
                            }
                        }
                    }
                }
            }
        }
    `,
    dataName: 'loginUser'
}

export const logoutUser = {
    query: `
        mutation{
            logoutUser
        }
    `,
    dataName: null
}