import * as currentUserActions from '../actions/currentUser'

import Vue from 'vue'

import { DateTime } from 'luxon'
import { userWorkDayEndpoint } from '../../api/endpoints';

const state = {
    logged: false,
    firstName: '',
    lastName: '',
    username: '',
    company: {},
    email: '',
    role: null,
    userWorkSchedules: []
}

const getters = {
    [currentUserActions.USER_GETACTIVEUWS]: (state) => (date) => {
        let wrappedDate = DateTime.fromJSDate(date);
        let result = state.userWorkSchedules.find(uws => {
            let bool = DateTime.fromISO(uws.validityStartDate).startOf('day') <= wrappedDate && (uws.validityStopDate == null || wrappedDate <= DateTime.fromISO(uws.validityStopDate).endOf('day'))
            return bool
        });
        return result || {};
    },
    [currentUserActions.USER_GETROLE]: state => state.role,
}

const actions = {
    [currentUserActions.USER_DOLOGIN]: async ({
        rootState,
        dispatch,
        commit
    }, userData) => {
        state.logged = true
        commit(currentUserActions.USER_SETDATA, userData)
        return
    },

    [currentUserActions.USER_DOLOGOUT]: async ({
        rootState,
        dispatch,
        commit
    }) => {
        state.logged = false
        commit(currentUserActions.USER_SETDATA, {
            firstName: '',
            lastName: '',
            username: '',
            company: {},
            email: '',
            role: null,
            userWorkSchedules: []
        })
        return
    },
}

const mutations = {
    [currentUserActions.USER_SETDATA]: (state, userData) => {
        state.firstName = userData.firstName;
        state.lastName = userData.lastName;
        state.email = userData.email;
        state.username = userData.username;
        state.role = userData.role;
        Vue.set(state, 'company', JSON.parse(JSON.stringify(userData.company)));
        Vue.set(state, 'userWorkSchedules', JSON.parse(JSON.stringify(userData.userWorkSchedules)));
        // state.company = JSON.parse(JSON.stringify(userData.company));
        // state.userWorkSchedules = JSON.parse(JSON.stringify(userData.userWorkSchedules));
        state.logged = true
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}