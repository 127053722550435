
export const getUserWorkDayByCurrentUserDate = {
    query: `
        query($date:Date!)
        {
            userWorkDayByCurrentUserDate(date: $date)
            {
                workDate
                consolidated
                freezed
                notes
                businessTrip
                overtimeMinutes
                workDayRecords {
                        dateIndex
                        note
                        recordType
                        freezed
                        clockRecord {
                            punchInTime
                            punchOutTime
                            punchInTimeOffset
                            punchOutTimeOffset
                        }
                        leaveRecord {
                            minuteDuration
                            apply104
                        }
                        holidayRecord {
                            minuteDuration
                            fullDay
                        }
                        sickRecord {
                            medicalCertificationDelivered
                        }
                        availabilityRecord {
                            temp
                        }
                }
                variationProposal {
                    creationTime
                    notes
                    workDate
                    businessTrip
                    overtimeMinutes
                    workDayRecords {
                        dateIndex
                        note
                        recordType
                        clockRecord {
                            punchInTime
                            punchOutTime
                            punchInTimeOffset
                            punchOutTimeOffset
                        }
                        leaveRecord {
                            minuteDuration
                            apply104
                        }
                        holidayRecord {
                            minuteDuration
                            fullDay
                        }
                        sickRecord {
                            medicalCertificationDelivered
                        }
                        availabilityRecord {
                            temp
                        }
                    }
                }
            }
        }
    `,
    dataName: 'userWorkDayByCurrentUserDate'

}

export const getUserWorkDayByUserDate = {
    query: `
        query($date:Date!, $username:String!)
        {
            userWorkDayByUserDate(date: $date, username: $username)
            {
                workDate
                consolidated
                freezed
                notes
                businessTrip
                overtimeMinutes
                workDayRecords {
                        dateIndex
                        note
                        recordType
                        freezed
                        clockRecord {
                            punchInTime
                            punchOutTime
                            punchInTimeOffset
                            punchOutTimeOffset
                        }
                        leaveRecord {
                            minuteDuration
                            apply104
                        }
                        holidayRecord {
                            minuteDuration
                            fullDay
                        }
                        sickRecord {
                            medicalCertificationDelivered
                        }
                        availabilityRecord {
                            temp
                        }
                }
                variationProposal {
                    creationTime
                    notes
                    workDate
                    businessTrip
                    overtimeMinutes
                    workDayRecords {
                        dateIndex
                        note
                        recordType
                        clockRecord {
                            punchInTime
                            punchOutTime
                            punchInTimeOffset
                            punchOutTimeOffset
                        }
                        leaveRecord {
                            minuteDuration
                            apply104
                        }
                        holidayRecord {
                            minuteDuration
                            fullDay
                        }
                        sickRecord {
                            medicalCertificationDelivered
                        }
                        availabilityRecord {
                            temp
                        }
                    }
                }
            }
        }
    `,
    dataName: 'userWorkDayByUserDate'

}

export const updateUserWorkDay = {
    query: `
        mutation($date:Date!, $businessTrip:Boolean, $overtimeMinutes:Int, $notes:String)
        {
            updateUserWorkDay(date: $date, businessTrip: $businessTrip, overtimeMinutes: $overtimeMinutes, notes: $notes) 
            {
                workDate
                consolidated
                freezed
                notes
                businessTrip
            }
        }
    `,
    dataName: 'updateUserWorkDay'

} 

export const getUserWorkDayByCurrentUserDateRange = {
    query: `
        query($dateStart:Date!, $dateStop:Date!)
        {
            userWorkDayByCurrentUserDateRange(dateStart: $dateStart, dateStop: $dateStop)
            {
                workDate
                consolidated
                freezed
                notes
                businessTrip
                overtimeMinutes
                workDayRecords {
                        dateIndex
                        note
                        recordType
                        freezed
                        clockRecord {
                            punchInTime
                            punchOutTime
                            punchInTimeOffset
                            punchOutTimeOffset
                        }
                        leaveRecord {
                            minuteDuration
                            apply104
                        }
                        holidayRecord {
                            minuteDuration
                            fullDay
                        }
                        sickRecord {
                            medicalCertificationDelivered
                        }
                        availabilityRecord {
                            temp
                        }
                }
                variationProposal {
                    creationTime
                    notes
                }
            }
        }
    `,
    dataName: 'userWorkDayByCurrentUserDateRange'

}

export const getUserWorkDayByUserDateRange = {
    query: `
        query($username:String!, $dateStart:Date!, $dateStop:Date!)
        {
            userWorkDayByUserDateRange(username: $username, dateStart: $dateStart, dateStop: $dateStop)
            {
                workDate
                consolidated
                freezed
                notes
                businessTrip
                overtimeMinutes
                workDayRecords {
                        dateIndex
                        note
                        recordType
                        freezed
                        clockRecord {
                            punchInTime
                            punchOutTime
                            punchInTimeOffset
                            punchOutTimeOffset
                        }
                        leaveRecord {
                            minuteDuration
                            apply104
                        }
                        holidayRecord {
                            minuteDuration
                            fullDay
                        }
                        sickRecord {
                            medicalCertificationDelivered
                        }
                        availabilityRecord {
                            temp
                        }
                }
                variationProposal {
                    creationTime
                    notes
                }
            }
        }
    `,
    dataName: 'userWorkDayByUserDateRange'

}
