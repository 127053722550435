<template>
    <v-container class="fill-height fill-width d-flex flex-column pa-0" fluid>
    <v-container fluid class="d-flex align-center justify-center pa-0 overflow-auto">
        <v-layout child-flex class="pa-0 overflow-auto" style="max-height: calc(100vh - 64px) !important">
        <v-data-table
            :headers="headers"
            :items="displayConsolidationData"
            :search="search"
            disable-pagination
            hide-default-footer
            :single-expand="false"
            :expanded.sync="expanded"
            item-key="id"
            sort-by="period.periodTime"
            sort-desc
            show-expand
            :loading="dataLoading"
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Cerca"
                        single-line
                        hide-details
                    ></v-text-field>
                
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider>
                    <v-btn
                        color="light-blue lighten-4"
                        class="mb-2"
                        @click="showConsolidationDialog = true"
                    >
                        <v-icon
                            left
                        >
                            mdi-file-lock
                        </v-icon>
                        Consolida Mensilità
                    </v-btn>
            
                </v-toolbar>
            </template>
            <template v-slot:item.period="{ item }">
                {{item.period.periodName}}
            </template>
            <template v-slot:item.creationTime="{ item }">
                {{item.displayCreationTime}}
            </template>
            <template v-slot:item.status="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-if="item.status == 'error'"
                            color="red lighten-1"
                            v-on="on"
                            v-bind="attrs"
                        >
                            mdi-alert-circle
                        </v-icon>
                         <v-icon
                            v-if="item.status == 'warning'"
                            color="orange lighten-1"
                            v-on="on"
                            v-bind="attrs"
                        >
                            mdi-alert-circle
                        </v-icon>
                        <v-icon
                            v-if="item.status == 'ok'"
                            color="green lighten-1"
                            v-on="on"
                            v-bind="attrs"
                        >
                            mdi-check-circle
                        </v-icon>
                    </template>
                    <span  v-if="item.status == 'error'">
                        È necessario intervenire per risolvere gli errori presenti nei turni consolidati
                    </span>
                    <span  v-if="item.status == 'warning'">
                        Sono presenti dei Warnings, è possibile generare Reports sul periodo
                    </span>
                    <span  v-if="item.status == 'ok'">
                        È possibile generare Reports sul periodo
                    </span>
                </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
                <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="confirmConsolidationRedo(item)"
                        >
                            mdi-reload
                        </v-icon>
                    </template>
                    <span>Ricalcola Consolidamento</span>
                </v-tooltip> -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="confirmConsolidationUndo(item)"
                        >
                            mdi-undo-variant
                        </v-icon>
                    </template>
                    <span>Annulla Consolidamento</span>
                </v-tooltip>
                
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-container class="d-flex flex-wrap ">
                        <v-sheet
                            rounded="pill" 
                            :color="userErrors.status == 'ok' ? 'green lighten-4' : 'red lighten-3'" 
                            class="d-flex flex-row justify-space-between py-2 px-4 align-center ma-2 clickable"
                            @click="userErrors.status != 'ok' ? openConsolidationErrorsDisplayDialog(userErrors.user.username, item.id) : function() {}"
                            v-for="(userErrors, index) in item.usersErrors"
                            :key="item.id + '_' + index"
                        >
                            <v-spacer />
                            <div class="font-weight-medium">
                                {{userErrors.user.username}}
                            </div>
                            <v-spacer />
                            <v-icon
                                class="ml-2"
                                small
                                v-if="userErrors.status == 'ok'"
                            >
                                mdi-check
                            </v-icon>
                            <v-icon
                                small
                                class="ml-2"
                                v-else
                            >
                                mdi-magnify
                            </v-icon>
                        </v-sheet>
                    </v-container>
                </td>
            </template>
        </v-data-table>
        </v-layout>
        <DataConsolidationDialog 
            :show="showConsolidationDialog"
            v-on:dialog-close="closeConsolidationDialog"
            v-on:refresh-consolidation-records="fetchConsolidationRecords"
        />
        <ConsolidationErrorsDisplay
            :show="showConsolidationErrorsDisplayDialog"
            :userErrors="displayingUserErrors"
            v-on:close-user-consolidation-errors-dialog="closeConsolidationErrorsDisplayDialog"
            v-on:open-error-resolve-dialog="openConsolidationErrorResolveDialog"
        />
        <ConsolidationErrorResolve
            :show="showConsolidationErrorResolveDialog"
            :userErrors="displayingUserErrors"
            :resolvingErrorIndex="resolvingErrorIndex"
            v-on:close-consolidation-error-resolve-dialog="closeConsolidationErrorResolveDialog"
            v-on:refresh-consolidation-user-errors="refreshConsolidationUserError"
        />
        <ConfirmDialog
            :dialog="confirmDialogShow"
            :dialogText="confirmDialogText"
            :dialogSubtext="confirmDialogSubtext"
            v-on:c-dialog-confirm="confirmDialogAction()"
            v-on:c-dialog-cancel="confirmDialogShow = false"
        />
        </v-container>
    </v-container>
</template>

<script>
export const DataConsolidationDialog = () => import('./consolidation/DataConsolidation.vue')
export const ConsolidationErrorResolve = () => import('./consolidation/ConsolidationErrorResolve.vue')
export const ConsolidationErrorsDisplay = () => import('./consolidation/ConsolidationErrorsDisplay.vue')

export const ConfirmDialog = () => import('../global/ConfirmDialog.vue');

import ConsolidationRecordsApi  from '../../api/consolidationRecord'
import workTimesService from '../../services/mixins/workTimesMixin'

import { DateTime } from 'luxon'

export default {
    data: function() {
        return {
            search: '',
            headers: [
                { text: 'Azienda', align: 'start', value: 'company.companyName' },
                { text: 'Periodo', align: 'start', value: 'period', sort: (a, b) => {return (a.periodTime.diff(b.periodTime, ['days']))['days']} },
                { text: 'Orario Consolidamento', align: 'start', value: 'creationTime', sort: (a, b) => {return (a.diff(b, ['milliseconds']))['milliseconds']} },
                { text: 'Stato', align: 'start', value: 'status' },
                { text: '', value: 'actions', sortable: false, align: 'end' },
            ],
            consolidationRecords: [],
            showConsolidationDialog: false,
            showConsolidationErrorsDisplayDialog: false,
            showConsolidationErrorResolveDialog: false,
            //displayingUserErrors: {},
            resolvingErrorIndex: null,
            expanded: [],
            confirmDialogShow: false,
            confirmDialogText: "",
            confirmDialogSubtext: "",
            confirmDialogAction: () => {},
            dataLoading: false,
            editingConsolidationRecordId: null,
            editingUserErrorsIndex: null,
            displayingUserErrorsConsolidationRecordId: null,
            displayingUserErrorsUsername: null,
        };
    },
    mixins: [
        workTimesService
    ],
    components: {
        DataConsolidationDialog,
        ConsolidationErrorsDisplay,
        ConsolidationErrorResolve,
        ConfirmDialog
    },
    computed: {
        displayingUserErrors: function() {
            return ((this.displayConsolidationData.find(cd => cd.id == this.displayingUserErrorsConsolidationRecordId) || {}).usersErrors || []).find(ue => ue.user.username == this.displayingUserErrorsUsername) || {};
        },
        displayConsolidationData: function() {
            return this.consolidationRecords.map(record => {
                let dateObject = {
                    day: 1,
                    month: record.month,
                    year: record.year
                };
                let periodTime = DateTime.fromObject(dateObject);
                record.id = record.company.companyName + DateTime.fromObject(dateObject).setLocale('it').toFormat('-MM-yyyy');

                let recordCopy = JSON.parse(JSON.stringify(record));
                recordCopy.period = {periodTime: periodTime};
                recordCopy.period.periodName = periodTime.setLocale('it').toFormat('MMMM yyyy');
                recordCopy.creationTime = DateTime.fromISO( recordCopy.creationTime);
                recordCopy.displayCreationTime = recordCopy.creationTime.toLocaleString(DateTime.DATETIME_MED);
                recordCopy.usersErrors.map(userErrors => {
                    userErrors.id = (new Date).getTime();
                    if(userErrors.consolidationErrors && userErrors.consolidationErrors.length > 0)
                    {
                        if(userErrors.consolidationErrors.filter(error => error.blocking).length > 0) {
                            userErrors.status = "error";
                        } else {
                            userErrors.status = "warning";
                        }
                    } else {
                        userErrors.status = "ok";
                    }
                });
                if((recordCopy.usersErrors || []).some(userError => userError.status == 'error')) {
                    recordCopy.status = "error";
                } else {
                    if((recordCopy.usersErrors || []).some(userError => userError.status == 'warning')) {
                        recordCopy.status = "warning";
                    } else {
                        recordCopy.status = "ok";
                    }
                }

                return recordCopy;
            })
        }
    },
  
    methods: {
        refreshConsolidationUserError: function() {
            if(this.displayingUserErrors.consolidationErrors.length <= 1) {
                // // let ecr = this.consolidationRecords.find(r => r.id == this.editingConsolidationRecordId);
                // // ecr.usersErrors[this.editingUserErrorsIndex].consolidationErrors = [];

                // let index = this.consolidationRecords.findIndex(r => r.id == this.editingConsolidationRecordId);
                // this.$set(this.consolidationRecords[index].usersErrors[this.editingUserErrorsIndex].consolidationErrors,  []);

                

                this.showConsolidationErrorsDisplayDialog = false;
                this.editingConsolidationRecordId = null;
                this.editingUserErrorsIndex = null;
                this.resolvingErrorIndex = null;
            } 
            // else {
            //     this.displayingUserErrors.consolidationErrors.splice(this.resolvingErrorIndex, 1);
            // } 

            this.fetchConsolidationRecords();
        },
        closeConsolidationErrorsDisplayDialog: function() {
            this.showConsolidationErrorsDisplayDialog = false;
            this.displayingUserErrorsUsername = null;
            this.displayingUserErrorsConsolidationRecordId = null;
        },
        openConsolidationErrorsDisplayDialog: function(username, consolidationRecordId) {
            // this.editingConsolidationRecordId = consolidationRecordId;
            // this.editingUserErrorsIndex = userErrorsIndex;

            //this.displayingUserErrors = userErrors;

            this.displayingUserErrorsUsername = username;
            this.displayingUserErrorsConsolidationRecordId = consolidationRecordId;

            this.showConsolidationErrorsDisplayDialog = true;
        },
        closeConsolidationErrorResolveDialog: function() {
            this.showConsolidationErrorResolveDialog = false;
        },
        // openConsolidationErrorResolveDialog: function(userError) {
        //     this.showConsolidationErrorResolveDialog = true;
        // },
        openConsolidationErrorResolveDialog: function(index) {
            this.resolvingErrorIndex = index;
            this.showConsolidationErrorResolveDialog = true;
        },
        closeConsolidationDialog: function() {
            this.showConsolidationDialog = false;
        },
        confirmConsolidationRedo: function(item) {
            this.confirmDialogAction = () => {
                this.redoConsolidation(item);
            };
            this.confirmDialogText = "Avviare il ricalcolo del periodo " + item.periodName + " per " + item.company.companyName + "?";
            this.confirmDialogSubtext = "(Si riceverà una mail di notifica a processo terminato)"
            this.confirmDialogShow = true;
        },
        confirmConsolidationUndo: function(item) {
            this.confirmDialogAction = () => {
                this.undoConsolidation(item);
            };
            this.confirmDialogText = "Annullare il consolidamento del periodo " + item.periodName + " per " + item.company.companyName + "?";
            this.confirmDialogSubtext = "(Verranno eliminati i Report relativi al periodo, se presenti)";
            this.confirmDialogShow = true;
        },
        redoConsolidation: function(item) {
            ConsolidationRecordsApi.createConsolidationRecord([item.company.companyName], item.year, item.month).then(response => {
                if(response.success) {
                    this.fetchConsolidationRecords();
                    this.confirmDialogText = "";
                    this.confirmDialogSubtext = null;
                    this.confirmDialogAction = () => {};
                    this.confirmDialogShow = false;
                }
            });
        },
        undoConsolidation: function(item) {
            ConsolidationRecordsApi.undoConsolidationRecord(item.company.companyName, item.year, item.month).then(response => {
                if(response.success) {
                    this.fetchConsolidationRecords();
                    this.confirmDialogText = "";
                    this.confirmDialogSubtext = null;
                    this.confirmDialogAction = () => {};
                    this.confirmDialogShow = false;
                }
            });
        },
        formatTimeString: function(timeISO) {
            if(timeISO) {
                return DateTime.fromISO(timeISO).toLocaleString(DateTime.TIME_24_SIMPLE);
            } else {
                return "--:--";
            }
        },
        formatDurationString: function(minutesDuration) {
            return (minutesDuration >= 60 ? Math.floor(minutesDuration/60) + 'h ' : '') + (minutesDuration % 60 != 0 ? minutesDuration % 60 + 'm': '').trim();
        },
        fetchConsolidationRecords: function() {
            this.dataLoading = true;
            ConsolidationRecordsApi.getConsolidationRecords().then(response => {
                if(response.success) {
                    this.consolidationRecords = response.data;
                } else {
                    this.consolidationRecords = [];
                }
                this.dataLoading = false;
            });
        }
    },
    mounted: function() {
        this.fetchConsolidationRecords();
    }
}
</script>
<style lang="css" scoped>
  .clickable :hover {
    cursor: pointer;
  }
</style>