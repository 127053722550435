<template>
    <v-container fluid style="overflow-y: auto;">
        <v-data-table
            :headers="headers"
            :items="holidaysData"
            :search="search"
            disable-pagination
            hide-default-footer
            single-expand
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >
                
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Cerca"
                        single-line
                        hide-details
                    ></v-text-field>
                
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider>
                    <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        @click="dialogEditHoliday = true"
                        >
                        <v-icon
                            left
                            dark
                        >
                            mdi-plus
                        </v-icon>
                        Aggiungi Festività
                        </v-btn>
                    
                </v-toolbar>
            </template>
            <!-- <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <container>
                        <v-row  v-for="(holiday, index) in item.holidayDates" :key="index">
                            
                        </v-row>
                    </container>
                    
                </td>
            </template> -->
            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
        <YearHolidaysEditDialog
            :show="dialogEditHoliday"
            :editedYearHolidays="editedItem"
            v-on:dialog-close="closeEditDialog"
            v-on:selected-year-change="loadEditingHolidayDates"
            v-on:save-local-year-holidays="saveLocalYearHolidays"
        />
        <ConfirmDialog
            :dialog="confirmDialog" 
            :dialogText="confirmDialogText"
            v-on:c-dialog-confirm="confirmDeleteItem"
            v-on:c-dialog-cancel="cancelDeleteItem"
        />
    </v-container>
</template>
<script>
export const YearHolidaysEditDialog = () => import('../dialogs/YearHolidaysEdit.vue')
export const ConfirmDialog = () => import('../../../global/ConfirmDialog.vue')

import localYearHolidaysApi from '../../../../api/localYearHolidays'
import { mapState } from 'vuex'

export default {
    data: function() {
        return {
            search: '',
            headers: [
                { text: 'Anno', align: 'start', value: 'year' },
                { align: 'end', value: 'actions' },
            ],
            //holidaysData: [],
            dialogEditHoliday: false,
            confirmDialog: false,
            confirmDialogText: '',
            editedItem: null,
            deletingYear: null,
        };
    },
    props: {

    },
    components: {
        ConfirmDialog,
        YearHolidaysEditDialog
    },
    computed: {
        ...mapState({
            holidaysData: state => state.dataStorage.localYearsHolidays,
        }),
    },
    methods: {
        editItem: function(item) {
            this.editedItem = JSON.parse(JSON.stringify(item));
            this.dialogEditHoliday = true;
        },
        deleteItem: function(item) {
            this.deletingYear = item.year;
            this.confirmDialogText = "Eliminare definitivamente le Giornate Festive per l'anno " + item.year + " ?";
            this.confirmDialog = true;
        },
        confirmDeleteItem: function() {
            localYearHolidaysApi.deleteLocalYearHolidays(this.deletingYear).then(response => {
                if(response.success) {
                    this.emitFetchLocalHolidaysEvent();
                    this.deletingYear = null;
                    this.confirmDialog = false;
                } else {
                    console.log(response);
                    this.confirmDialog = false;
                }
            });
        },
        cancelDeleteItem: function() {
            this.deletingYear = null;
            this.confirmDialog = false;
        },
        saveLocalYearHolidays: function(localYearHolidays) {
            console.log(localYearHolidays)
            localYearHolidaysApi.createLocalYearHolidays(localYearHolidays).then(response => {
                if(response.success) {
                    this.emitFetchLocalHolidaysEvent();
                } else {
                    console.log(response)
                }
            })
        },
        loadEditingHolidayDates: function(year) {
            let foundData = this.holidaysData.find(hd => hd.year == year);
            if(foundData) {
                this.editedItem = foundData;
            } else {
                this.editedItem = {};
            }
        },
        closeEditDialog: function() {
            this.dialogEditHoliday = false;
            this.editedItem = null;
        },
        emitFetchLocalHolidaysEvent() {
            this.$emit('refresh-local-years-holidays-data');
        },
        fetchLocalHolidays: function() {
            localYearHolidaysApi.getLocalYearsHolidays().then(response => {
                if(response.success) {
                    this.holidaysData = response.data;
                } else {
                    console.log(response)
                }
            })
        }
    },
    mounted: function() {
        //this.fetchLocalHolidays();
    }
}
</script>
