<template>
    <v-app>
        <v-container fill-height class="d-flex flex-column justify-center align-center">
            <div class="text-h1 font-weight-black red--text text--lighten-3">
                500
            </div>
            <div class="text-subtitle-1 mt-4">
                Errore Interno
            </div>
            <div class="text-subtitle-2 font-weight-light">
                contattare gli sviluppatori
            </div>
            <v-btn
                @click="goBack"
                class="mt-12"
            >
                <v-icon left>
                    mdi-home
                </v-icon>
                Torna alla schermata iniziale
            </v-btn>
        </v-container>
    </v-app>
</template>
<script>

export default {
    data: function() {
        return {
        };
    },
    methods: {
        goBack: function() {
            this.$router.push({ name: 'Home' });
        }
    },
    mounted: function() {
    }
}
</script>