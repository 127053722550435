<template>
    <v-app>
    <AppToolbar :clockObject="clockObject"/>
    <v-main>
        <ReportsTable />
       <!-- <v-container fill-height class="d-flex flex-column justify-center">
           <v-row>
               <v-col>
                    Tempi Mensili
               </v-col>
               <v-col>
                    <v-btn
                        @click="download"
                    > Download
                    </v-btn>
               </v-col>
               <v-col>
                    Tempi Mensili
               </v-col>
           </v-row>
            <div class="text-h1 font-weight-black light-blue--text text--lighten-3">
                Reports
            </div>
            
        </v-container> -->
    </v-main>
    <SnackBar/>
  </v-app>
</template>
<script>
export const AppToolbar = () => import('../../components/core/AppToolBar.vue')
export const SnackBar = () => import('../../components/core/AppSnackBar.vue')
export const ReportsTable = () => import('../../components/reports/ReportsTable.vue');

import { DateTime } from 'luxon'
import { mapState } from 'vuex'

import reportApi from '../../api/report'

export default {
    data: function() {
        return {
          book: null,
        };
    },
    props: {
        clockObject: {
            type: Object,
            required: true
        },
    },
    components: {
        AppToolbar,
        SnackBar,
        ReportsTable
    },
    methods: {
        download: function() {
            // reportApi.workingHoursReport('radio_coop', 1, 2022).then(response => {
            //     console.log('gang', response);
            // })
            console.log('to implement')
        },
    },
    computed: { 
        
    },
    mounted: function() {
    }
}
</script>