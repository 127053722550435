/* eslint-disable no-unused-vars */
import * as snackbarActions from '../actions/snackbar'

var showTimeOut

const state = {
    showSnackBar: false,
    message: '',
    // actionButton: 'OK',
    posX: 'center',
    posY: 'bottom',
    mode: '',
    timeout: -1,
    messageType: 'SUCCESS',
}

const getters = {
    [snackbarActions.SNACKBAR_GET_STATUS]: state => state.showSnackBar,
    [snackbarActions.SNACKBAR_GET_MESSAGE]: state => state.message,
    // [snackbarActions.SNACKBAR_GET_ACTION_BUTTON]: state => state.actionButton,
    [snackbarActions.SNACKBAR_GET_MODE]: state => state.mode,
    [snackbarActions.SNACKBAR_GET_MESSAGE_TYPE]: state => state.messageType,
    [snackbarActions.SNACKBAR_GET_X]: state => state.posX,
    [snackbarActions.SNACKBAR_GET_Y]: state => state.posY,
    [snackbarActions.SNACKBAR_GET_TIMEOUT]: state => state.timeout
}

const actions = {
    [snackbarActions.SNACKBAR_SHOW]: ({
        commit,
        dispatch
    }, messageObject) => {
        return new Promise((resolve, reject) => {
            //
            if (showTimeOut !== null && showTimeOut !== undefined) {
                clearTimeout(showTimeOut)
            }
            commit(snackbarActions.SNACKBAR_HIDE)
            setTimeout(() => {
                //
                commit(snackbarActions.SNACKBAR_SHOW, messageObject)
                //
                showTimeOut = setTimeout(() => {
                    dispatch(snackbarActions.SNACKBAR_HIDE)
                }, 4500)
            }, 125)

            return resolve()
        })
    },
    [snackbarActions.SNACKBAR_HIDE]: ({
        commit,
        dispatch
    }) => {
        return new Promise((resolve, reject) => {
            commit(snackbarActions.SNACKBAR_HIDE)
            return resolve()
        })
    }
}

const mutations = {
    [snackbarActions.SNACKBAR_HIDE]: (state) => {
        state.showSnackBar = false
    },
    [snackbarActions.SNACKBAR_SHOW]: (state, messageObject) => {
        state.showSnackBar = true;
        state.message = messageObject.text;
        state.messageType = messageObject.type;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
