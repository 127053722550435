import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VDataTable,{attrs:{"search":_vm.search,"headers":_vm.workScheduleDummiesHeaders,"items":_vm.displayWorkScheduleDummies,"single-expand":false,"expanded":_vm.expanded,"item-key":"workScheduleDummyName","show-expand":"","disable-pagination":"","hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Cerca","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VBtn,{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogEditWSD = true}}},[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Crea Modello Orario ")],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VContainer,[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"text-center"},[_vm._v(" Mattino ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Pomeriggio ")])])]),_c('tbody',_vm._l((_vm.orderedWeekDayKeys),function(weekDay,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(weekDay.text))]),_c('td',{staticClass:"text-center"},[_c(VContainer,[_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"5"}},[(item.defaultWeekWorkTimes[weekDay.key].morningTimes.clockInTime)?_c(VIcon,[_vm._v("mdi-import")]):_vm._e(),_vm._v(" "+_vm._s(_vm.formatDisplayTime(item.defaultWeekWorkTimes[weekDay.key].morningTimes.clockInTime))+" ")],1),_c(VCol,{attrs:{"cols":"12","md":"5"}},[(item.defaultWeekWorkTimes[weekDay.key].morningTimes.clockInTime)?_c(VIcon,[_vm._v("mdi-export")]):_vm._e(),_vm._v(" "+_vm._s(_vm.formatDisplayTime(item.defaultWeekWorkTimes[weekDay.key].morningTimes.clockOutTime))+" ")],1)],1)],1)],1),_c('td',{staticClass:"text-center"},[_c(VContainer,[_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"5"}},[(item.defaultWeekWorkTimes[weekDay.key].afternoonTimes.clockInTime)?_c(VIcon,[_vm._v("mdi-import")]):_vm._e(),_vm._v(" "+_vm._s(_vm.formatDisplayTime(item.defaultWeekWorkTimes[weekDay.key].afternoonTimes.clockInTime))+" ")],1),_c(VCol,{attrs:{"cols":"12","md":"5"}},[(item.defaultWeekWorkTimes[weekDay.key].afternoonTimes.clockOutTime)?_c(VIcon,[_vm._v("mdi-export")]):_vm._e(),_vm._v(" "+_vm._s(_vm.formatDisplayTime(item.defaultWeekWorkTimes[weekDay.key].afternoonTimes.clockOutTime))+" ")],1)],1)],1)],1)])}),0)]},proxy:true}],null,true)})],1)],1)]}},{key:"item.weekTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedDuration(item.weekMinutes))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])}),_c('ConfirmDialog',{attrs:{"dialog":_vm.dialogDelete,"dialogText":'Eliminare definitivamente il Modello Orario ' + _vm.editedItem.workScheduleDummyName + '?'},on:{"c-dialog-confirm":_vm.deleteItemConfirm,"c-dialog-cancel":_vm.closeDelete}}),_c('WorkScheduleDummyEdit',{attrs:{"show":_vm.dialogEditWSD,"editedWorkScheduleDummy":_vm.editedItem,"edit":_vm.editingWSD},on:{"dialog-close":_vm.closeDialogEditWSD,"create-work-schedule-dummy":_vm.createWorkScheduleDummy,"update-work-schedule-dummy":_vm.updateWorkScheduleDummy}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }