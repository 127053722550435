import gqlRequestManager from './gqlRequestManager'

import { userWorkDayEndpoint } from './endpoints'
import { 
    getUserWorkDayByCurrentUserDate,
    getUserWorkDayByUserDate,
    updateUserWorkDay,
    getUserWorkDayByCurrentUserDateRange,
    getUserWorkDayByUserDateRange
    } from '../utils/queries/userWorkDay'

export default {
    userWorkDayByCurrentUserDate: function(date) {
        return gqlRequestManager(
            userWorkDayEndpoint, 
            {
                query: getUserWorkDayByCurrentUserDate.query,
                variables: {
                    date: date
                }
            },
            getUserWorkDayByCurrentUserDate.dataName
        );
    },
    userWorkDayByUserDate: function(date, username) {
        return gqlRequestManager(
            userWorkDayEndpoint, 
            {
                query: getUserWorkDayByUserDate.query,
                variables: {
                    date: date,
                    username: username
                }
            },
            getUserWorkDayByUserDate.dataName
        );
    },
    updateUWD: function(date, businessTrip, overtimeMinutes, notes) {
        return gqlRequestManager(
            userWorkDayEndpoint, 
            {
                query: updateUserWorkDay.query,
                variables: {
                    date: date,
                    businessTrip: businessTrip,
                    overtimeMinutes: overtimeMinutes,
                    notes: notes
                }
            },
            updateUserWorkDay.dataName
        );
    },
    getUserWorkDayByCurrentUserDateRange: function(dateStart, dateStop) {
        return gqlRequestManager(
            userWorkDayEndpoint, 
            {
                query: getUserWorkDayByCurrentUserDateRange.query,
                variables: {
                    dateStart: dateStart,
                    dateStop: dateStop
                }
            },
            getUserWorkDayByCurrentUserDateRange.dataName
        );
    },
    getUserWorkDayByUserDateRange: function(username, dateStart, dateStop) {
        return gqlRequestManager(
            userWorkDayEndpoint, 
            {
                query: getUserWorkDayByUserDateRange.query,
                variables: {
                    username: username,
                    dateStart: dateStart,
                    dateStop: dateStop
                }
            },
            getUserWorkDayByUserDateRange.dataName
        );
    }
}
