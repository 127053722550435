import gqlRequestManager from './gqlRequestManager'

import { workDayRecordEndPoint } from './endpoints'
import { 
        registerPunchInOut, 
        undoLastPunchInOut, 
        registerSickRecord,
        registerAvailabilityRecord,
        registerAbsenceRecord,
        editAbsenceRecord, 
        removeWorkDayRecord,
        removeUserWorkDayRecord,
        editWorkDayRecordNote,
        insertAbsenceRecord,
        insertSickRecord
    } from '../utils/queries/workDayRecord'

export default {
    punchInOut: function() {
        return gqlRequestManager(
            workDayRecordEndPoint, 
            {
                query: registerPunchInOut.query,
                variables: {
                    recordTimeOffset: (new Date().getTimezoneOffset())
                }
            },
            registerPunchInOut.dataName
        );
    },
    undoLastPunchInOut: function() {
        return gqlRequestManager(
            workDayRecordEndPoint, 
            {
                query: undoLastPunchInOut.query
            },
            undoLastPunchInOut.dataName
        );
    },
    registerSickRecord: function(date) {
        return gqlRequestManager(
            workDayRecordEndPoint, 
            {
                query: registerSickRecord.query,
                variables: {
                    date: date
                }
            },
            registerSickRecord.dataName
        );
    },
    registerAvailabilityRecord: function(date) {
        return gqlRequestManager(
            workDayRecordEndPoint, 
            {
                query: registerAvailabilityRecord.query,
                variables: {
                    date: date
                }
            },
            registerAvailabilityRecord.dataName
        );
    },
    registerAbsenceRecord: function(date, minuteDuration, absenceKind, apply104 = false) {
        return gqlRequestManager(
            workDayRecordEndPoint, 
            {
                query: registerAbsenceRecord.query,
                variables: {
                    date: date,
                    minuteDuration: minuteDuration,
                    absenceKind: absenceKind,
                    apply104: apply104
                }
            },
            registerAbsenceRecord.dataName
        );
    },
    editAbsenceRecord: function(date, dateIndex, minuteDuration, absenceKind, apply104 = false) {
        return gqlRequestManager(
            workDayRecordEndPoint, 
            {
                query: editAbsenceRecord.query,
                variables: {
                    date: date,
                    dateIndex: dateIndex,
                    minuteDuration: minuteDuration,
                    absenceKind: absenceKind,
                    apply104: apply104
                }
            },
            editAbsenceRecord.dataName
        );
    },
    deleteWorkDayRecord: function(date, index) {
        return gqlRequestManager(
            workDayRecordEndPoint, 
            {
                query: removeWorkDayRecord.query,
                variables: {
                    date: date,
                    index: index
                }
            },
            removeWorkDayRecord.dataName
        );
    },
    deleteUserWorkDayRecord: function(username, date, index) {
        return gqlRequestManager(
            workDayRecordEndPoint, 
            {
                query: removeUserWorkDayRecord.query,
                variables: {
                    username: username,
                    date: date,
                    index: index
                }
            },
            removeUserWorkDayRecord.dataName
        );
    },
    editRecordNote: function(date, index, note) {
        return gqlRequestManager(
            workDayRecordEndPoint, 
            {
                query: editWorkDayRecordNote.query,
                variables: {
                    date: date,
                    index: index,
                    note: note
                }
            },
            editWorkDayRecordNote.dataName
        );
    },
    insertAbsenceRecord: function(date, username, minuteDuration, absenceKind) {
        return gqlRequestManager(
            workDayRecordEndPoint, 
            {
                query: insertAbsenceRecord.query,
                variables: {
                    date: date,
                    username: username,
                    minuteDuration: minuteDuration,
                    absenceKind: absenceKind
                }
            },
            insertAbsenceRecord.dataName
        );
    },
    insertSickRecord: function(date, username) {
        return gqlRequestManager(
            workDayRecordEndPoint, 
            {
                query: insertSickRecord.query,
                variables: {
                    date: date,
                    username: username
                }
            },
            insertSickRecord.dataName
        );
    }
}