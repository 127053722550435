<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="displayUsers"
      :search="search"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cerca"
          single-line
          hide-details
        ></v-text-field>

          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

          <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="dialogEditUser = true"
            >
              <v-icon
                left
                dark
              >
                mdi-plus
              </v-icon>
              Crea Utente
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.username="{ item }">
        <v-icon v-if="item.disabled"
          small
          class="mr-2"
          color="red lighten-2"
        >
          mdi-lock
        </v-icon>
        <v-icon v-else
          small
          class="mr-2"
          :color="userRoles[item.role].color"
        >
          {{userRoles[item.role].icon}}
        </v-icon>
          {{ item.username }}
      </template>
      <template v-slot:item.activeWorkSchedule.baseWorkScheduleDummy.workScheduleDummyName="{ item }">
        <v-icon
          small
          @click="editUserWorkSchedule(item)"
          class="mr-2"
        >
        mdi-account-clock
        </v-icon>
          {{ (item.activeWorkSchedule && item.activeWorkSchedule.baseWorkScheduleDummy) ? item.activeWorkSchedule.baseWorkScheduleDummy.workScheduleDummyName : '' }}
      </template>
      <template v-slot:item.actions="{ item }">
        <span v-if="item.role != 'SYSTEM'">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon v-if="item.disabled"
            small
            class="mr-2"
            @click="enableItem(item)"
          >
            mdi-lock-open-outline
          </v-icon>
          <v-icon v-else
            small
            class="mr-2"
            @click="disableItem(item)"
          >
            mdi-lock
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </span>
      </template>
    </v-data-table>
    <UserEdit
      :editedUser="editedItem"
      :edit="editingUser"
      :show="dialogEditUser"
      v-on:dialog-close="closeUserEdit"
      v-on:create-user="createUser"
      v-on:update-user="updateUser"
    />
    <UserWorkScheduleEdit
      :show="dialogUserWorkSchedules"
      :user="editedItem"
      v-on:close-dialog="closeDialogUWSEdit"
      v-on:create-user-work-schedule="createUserWorkSchedule"
      v-on:update-user-work-schedule="updateUserWorkSchedule"
      v-on:delete-user-work-schedule="deleteUserWorkSchedule"
    />
    <ConfirmDialog
      :dialog="dialogDelete" 
      :dialogText="'Eliminare definitivamente l\'utente ' + this.editedItem.username + '?'"
      v-on:c-dialog-confirm="deleteItemConfirm"
      v-on:c-dialog-cancel="closeDialogDelete"
    />
    <ConfirmDialog
      :dialog="dialogDisable" 
      :dialogText="'Disattivare l\'utente ' + this.editedItem.username + '?'"
      v-on:c-dialog-confirm="disableItemConfirm"
      v-on:c-dialog-cancel="closeDialogDisable"
    />
  </v-container>
</template>

<script>
export const ConfirmDialog = () => import('../../../global/ConfirmDialog.vue')
export const UserEdit = () => import('../dialogs/UserEdit.vue')
export const UserWorkScheduleEdit = () => import('../dialogs/UserWorkScheduleEdit.vue')

import userApi from '../../../../api/user'
import userWorkScheduleApi from '../../../../api/userWorkSchedule'

import { DateTime } from 'luxon'
import { mapState } from 'vuex'

export default {
    data: () => ({
        search: '',
        dialogEditUser: false,
        dialogDelete: false,
        dialogDisable: false,
        dialogUserWorkSchedules: false,
        headers: [
            { text: 'Nome Utente', align: 'start', value: 'username' },
            { text: 'Nome', align: 'start', value: 'firstName' },
            { text: 'Cognome', align: 'start', value: 'lastName' },
            { text: 'E-Mail', align: 'start', value: 'email' },
            { text: 'Azienda', align: 'start', value: 'company.companyName' },
            { text: 'Reparto', align: 'start', value: 'department.departmentName' },
            { text: 'Orario Pianificato', align: 'start', value: 'activeWorkSchedule.baseWorkScheduleDummy.workScheduleDummyName' },
            { text: '', value: 'actions', sortable: false, align: 'end' },
        ],
        editedIndex: -1,
        editedItem: {
            username: '',
            firstName: '',
            lastName: '',
            email: '',
            company: {
              companyName: ''
            },
            department: null,
            disabled: undefined
        },
        defaultItem: {
            username: '',
            firstName: '',
            lastName: '',
            email: '',
            company: {
              companyName: ''
            },
            department: null,
            disabled: undefined

        },
    }),
    components: {
      ConfirmDialog,
      UserEdit,
      UserWorkScheduleEdit
    },
    computed: {
      ...mapState({
            users: state => state.dataStorage.users,
            userRoles: state => state.dataStorage.userRoles
        }), 
      displayUsers () {
        let dUsers = JSON.parse(JSON.stringify(this.users));
        let today = DateTime.now();
        dUsers.forEach(user => {
          user.activeWorkSchedule = user.userWorkSchedules.filter(workSchedule => {
            let validityStart = DateTime.fromISO(workSchedule.validityStartDate);
            let validityStop = workSchedule.validityStopDate ? DateTime.fromISO(workSchedule.validityStopDate) : null;
            return (today >= validityStart && (validityStop == null || today <= validityStop))
          })[0];
        })
        return dUsers;
      },
      editingUser: function() {
        //return JSON.stringify(this.editedItem) != JSON.stringify(this.defaultItem) 
        return this.editedIndex >= 0;
      }
    },
    methods: {
      editItem (item) {
        this.editedIndex = this.displayUsers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        delete this.editedItem.activeWorkSchedule
        this.dialogEditUser = true
      },

      editUserWorkSchedule (item) {
        this.editedIndex = this.displayUsers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogUserWorkSchedules = true
      },
      closeDialogUWSEdit: function() {
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
        this.dialogUserWorkSchedules = false;
      },
      closeDialogDelete: function() {
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
        this.dialogDelete = false;
      },
      closeDialogDisable: function() {
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
        this.dialogDisable = false;
      },
      closeUserEdit: function() {
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
        this.dialogEditUser = false;
      },

      deleteItem: function(item) {
        this.editedIndex = this.displayUsers.indexOf(item);
        this.editedItem = Object.assign({}, item);
        delete this.editedItem.activeWorkSchedule;
        this.dialogDelete = true;
      },

      disableItem: function(item) {
        this.editedIndex = this.displayUsers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        delete this.editedItem.activeWorkSchedule
        this.dialogDisable = true
      },

      enableItem: function(item) {
        let self = this;
        userApi.enableUser(item.username).then(response => {
          if(response.success) {
            self.emitFetchUsersEvent();
          }
        })
      },

      disableItemConfirm () {
        let self = this;
        userApi.disableUser(this.editedItem.username).then(response => {
            if(response.success) {
              self.emitFetchUsersEvent();
              self.dialogDisable = false;
            }
        });
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      deleteItemConfirm () {
        let self = this;
        userApi.deleteUser(this.editedItem.username).then(response => {
          if(response.success) {
            self.emitFetchUsersEvent();
            self.dialogDelete = false;
          }
        });
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      createUser(user, companyName) {
        userApi.createUser(user, companyName).then(response => {
          if(response.success) {
            this.emitFetchUsersEvent();
          }
          this.closeUserEdit();
        })
      },
      updateUser(username, updatedUser, updatedCompanyName) {
        userApi.updateUser(username, updatedUser, updatedCompanyName).then(response => {
           if(response.success) {
            this.emitFetchUsersEvent();
          }
          this.closeUserEdit();
        })
      },

      createUserWorkSchedule(editedUserWorkSchedule, username, editedBaseWorkScheduleDummyName) {
        userWorkScheduleApi.createUserWorkSchedule(editedUserWorkSchedule, username, editedBaseWorkScheduleDummyName).then(response => {
          if(response.success) {
            this.emitFetchUserWorkSchedulesEvent();
            this.emitFetchUsersEvent();
          }
          //this.dialogUserWorkSchedules = false;
        });
      },
      updateUserWorkSchedule(editedUserWorkSchedule, username, editedBaseWorkScheduleDummyName, userWorkScheduleValifityStartDate) {
        userWorkScheduleApi.updateUserWorkSchedule(editedUserWorkSchedule, username, editedBaseWorkScheduleDummyName, userWorkScheduleValifityStartDate).then(response => {
          if(response.success) {
            this.emitFetchUserWorkSchedulesEvent();
            this.emitFetchUsersEvent();
          }
          //this.dialogUserWorkSchedules = false;
        });
      },
      deleteUserWorkSchedule(username, userWorkScheduleValifityStartDate) {
        userWorkScheduleApi.deleteUserWorkSchedule(username, userWorkScheduleValifityStartDate).then(response => {
          if(response.success) {
            this.emitFetchUserWorkSchedulesEvent();
            this.emitFetchUsersEvent();
          }
          //this.dialogUserWorkSchedules = false;
        });
      },


      emitFetchUsersEvent() {
        this.$emit('refresh-users-data');
      },
      emitFetchUserWorkSchedulesEvent() {
        this.$emit('refresh-user-work-schedules-data');
      },
    },
}
</script>
