import authService from '../services/authService'
import store from '../store'
import router from '../router'

import * as currentUserActions from '../store/actions/currentUser'
import * as dataStorageActions from '../store/actions/dataStorage'

export default function isAuthenticated(router) {
    router.beforeEach(async (to, from, next) => {
        if (to.matched.some(record => record.meta.requiresAuth) && !to.meta.guest) {
            let result = await authService.triggerAuthenticateControl();
            if(result.success) {
                let currentUserResult = await result.userFetchPromise;
                if(currentUserResult.success) {
                    store.dispatch(currentUserActions.USER_DOLOGIN, currentUserResult.data)
                    let userRole = store.getters[currentUserActions.USER_GETROLE];
                    let minimumRequiredRoleValue = 0;
                    let userRolesDict = store.getters[dataStorageActions.DATASTORAGE_GETUSERROLES];
                    to.matched.forEach(path => {
                        let requiredRole = path.meta.requiredRole;
                        if(requiredRole) {
                            if(userRolesDict[requiredRole].value > minimumRequiredRoleValue) {
                                minimumRequiredRoleValue = userRolesDict[requiredRole].value;
                            }
                        }
                    });
                    if(userRolesDict[userRole].value >= minimumRequiredRoleValue) {
                        next();
                    } else {
                        router.push({ name: 'Unauthorized' });
                    }
                } else {
                    return false;
                }
            }
        }
        next();
    })
}